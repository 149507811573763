import React from "react";
import { Link } from "react-router-dom";

export default function Header() {
  return (
    <header
      className="hotale-header-wrap hotale-header-style-plain hotale-style-center-menu hotale-sticky-navigation hotale-style-fixed"
      data-navigation-offset={75}
      style={{ padding: "18px" }}
    >
      <div className="hotale-header-background" />
      <div className="hotale-header-container hotale-header-full">
        <div className="hotale-header-container-inner clearfix">
          <div className="hotale-logo hotale-item-pdlr">
            <div className="hotale-logo-inner">
              <a className="hotale-fixed-nav-logo" href="">
                <img
                  // src="upload/apartment2-logox2.png"
                  src="images/hostel.png"
                  alt="KSJ hostels"
                  width={147}
                  height={40}
                  title="apartment2-logox1"
                />
                <span
                  style={{
                    fontSize: "23px",
                    fontWeight: 200,
                    letterSpacing: "0px",
                    lineHeight: 1,
                    textTransform: "none",
                    color: "#141414",
                  }}
                >
                  {" "}
                  KSJ Stay
                </span>
              </a>
              <a className="hotale-orig-logo" href="">
                <img
                  // src="upload/apartment2-logox2.png"
                  src="images/hostel.png"
                  alt="KSJ hostels"
                  width={147}
                  height={40}
                  title="KSJ-hostel"
                />

                <span
                  style={{
                    fontSize: "23px",
                    fontWeight: 200,
                    letterSpacing: "0px",
                    lineHeight: 1,
                    textTransform: "none",
                    color: "#141414",
                  }}
                >
                  {" "}
                  KSJ Stay
                </span>
              </a>
            </div>
          </div>
          <div className="hotale-navigation hotale-item-pdlr clearfix">
            <div className="hotale-main-menu" id="hotale-main-menu">
              <ul id="menu-main-navigation-1" className="sf-menu">
                {/* <li className="menu-item menu-item-home current-menu-item  menu-item-has-children hotale-normal-menu">
                  <a href="index.html" className="sf-with-ul-pre">
                    Home
                  </a>
                  <ul className="sub-menu">
                    <li className="menu-item menu-item-home" data-size={60}>
                      <a target="_blank" href="../resort/index.html">
                        Home – Resort 1
                      </a>
                    </li>
                    <li className="menu-item" data-size={60}>
                      <a target="_blank" href="../resort2/index.html">
                        Home – Resort 2
                      </a>
                    </li>
                    <li className="menu-item" data-size={60}>
                      <a target="_blank" href="../hotel1/index.html">
                        Home – Hotel 1
                      </a>
                    </li>
                    <li className="menu-item" data-size={60}>
                      <a target="_blank" href="../hotel2/index.html">
                        Home – Hotel 2
                      </a>
                    </li>
                    <li className="menu-item" data-size={60}>
                      <a target="_blank" href="../apartment/index.html">
                        Home – Apartment
                      </a>
                    </li>
                    <li className="menu-item" data-size={60}>
                      <a href="index.html">Home – Apartment 2</a>
                    </li>
                  </ul>
                </li>
                <li className="menu-item menu-item-has-children hotale-normal-menu">
                  <a href="#" className="sf-with-ul-pre">
                    Pages
                  </a>
                  <ul className="sub-menu">
                    <li className="menu-item" data-size={60}>
                      <a href="about-us.html">About Us</a>
                    </li>
                    <li className="menu-item" data-size={60}>
                      <a href="about-us-2.html">About Us 2</a>
                    </li>
                    <li className="menu-item" data-size={60}>
                      <a href="about-us-3.html">About Us 3</a>
                    </li>
                    <li className="menu-item" data-size={60}>
                      <a href="our-team.html">Our Team</a>
                    </li>
                    <li className="menu-item" data-size={60}>
                      <a href="hotel-review.html">Hotel Review</a>
                    </li>
                    <li className="menu-item" data-size={60}>
                      <a href="faq.html">FAQ</a>
                    </li>
                    <li className="menu-item" data-size={60}>
                      <a href="gallery.html">Gallery</a>
                    </li>
                    <li className="menu-item" data-size={60}>
                      <a href="price-table.html">Price Table</a>
                    </li>
                    <li className="menu-item" data-size={60}>
                      <a href="maintenance.html">Maintenance</a>
                    </li>
                    <li className="menu-item" data-size={60}>
                      <a href="coming-soon.html">Coming Soon</a>
                    </li>
                    <li className="menu-item" data-size={60}>
                      <a href="404.html">404 Page</a>
                    </li>
                  </ul>
                </li>
                <li className="menu-item menu-item-has-children hotale-normal-menu">
                  <a href="room-grid-style-1.html" className="sf-with-ul-pre">
                    Rooms
                  </a>
                  <ul className="sub-menu">
                    <li className="menu-item" data-size={60}>
                      <a href="room-grid-style-1.html">Room Grid Style 1</a>
                    </li>
                    <li className="menu-item" data-size={60}>
                      <a href="room-grid-style-2.html">Room Grid Style 2</a>
                    </li>
                    <li className="menu-item" data-size={60}>
                      <a href="room-grid-style-3.html">Room Grid Style 3</a>
                    </li>
                    <li className="menu-item" data-size={60}>
                      <a href="room-grid-style-4.html">Room Grid Style 4</a>
                    </li>
                    <li className="menu-item" data-size={60}>
                      <a href="room-modern-style.html">Room Modern Style</a>
                    </li>
                    <li className="menu-item" data-size={60}>
                      <a href="room-side-thumbnail.html">Room Side Thumbnail</a>
                    </li>
                  </ul>
                </li>
                <li className="menu-item hotale-normal-menu">
                  <a href="room-search.html">Reservation</a>
                </li>
                <li className="menu-item menu-item-has-children hotale-normal-menu">
                  <a
                    href="blog-full-right-sidebar.html"
                    className="sf-with-ul-pre"
                  >
                    Blog
                  </a>
                  <ul className="sub-menu">
                    <li
                      className="menu-item menu-item-has-children"
                      data-size={60}
                    >
                      <a
                        href="blog-3-columns-with-frame.html"
                        className="sf-with-ul-pre"
                      >
                        Blog Columns
                      </a>
                      <ul className="sub-menu">
                        <li className="menu-item">
                          <a href="blog-2-columns.html">Blog 2 Columns</a>
                        </li>
                        <li className="menu-item">
                          <a href="blog-2-columns-with-frame.html">
                            Blog 2 Columns With Frame
                          </a>
                        </li>
                        <li className="menu-item">
                          <a href="blog-3-columns.html">Blog 3 Columns</a>
                        </li>
                        <li className="menu-item">
                          <a href="blog-3-columns-with-frame.html">
                            Blog 3 Columns With Frame
                          </a>
                        </li>
                        <li className="menu-item">
                          <a href="blog-4-columns.html">Blog 4 Columns</a>
                        </li>
                        <li className="menu-item">
                          <a href="blog-4-columns-with-frame.html">
                            Blog 4 Columns With Frame
                          </a>
                        </li>
                      </ul>
                    </li>
                    <li
                      className="menu-item menu-item-has-children"
                      data-size={60}
                    >
                      <a
                        href="blog-full-right-sidebar.html"
                        className="sf-with-ul-pre"
                      >
                        Blog Full
                      </a>
                      <ul className="sub-menu">
                        <li className="menu-item">
                          <a href="blog-full-right-sidebar.html">
                            Blog Full Right Sidebar
                          </a>
                        </li>
                        <li className="menu-item">
                          <a href="blog-full-right-sidebar-with-frame.html">
                            Blog Full Right Sidebar With Frame
                          </a>
                        </li>
                        <li className="menu-item">
                          <a href="blog-full-left-sidebar.html">
                            Blog Full Left Sidebar
                          </a>
                        </li>
                        <li className="menu-item">
                          <a href="blog-full-left-sidebar-with-frame.html">
                            Blog Full Left Sidebar With Frame
                          </a>
                        </li>
                        <li className="menu-item">
                          <a href="blog-full-both-sidebar.html">
                            Blog Full Both Sidebar
                          </a>
                        </li>
                        <li className="menu-item">
                          <a href="blog-full-both-sidebar-with-frame.html">
                            Blog Full Both Sidebar With Frame
                          </a>
                        </li>
                      </ul>
                    </li>
                    <li
                      className="menu-item menu-item-has-children"
                      data-size={60}
                    >
                      <a
                        href="blog-grid-3-columns.html"
                        className="sf-with-ul-pre"
                      >
                        Blog Grid
                      </a>
                      <ul className="sub-menu">
                        <li className="menu-item">
                          <a href="blog-grid-2-columns.html">
                            Blog Grid 2 Columns
                          </a>
                        </li>
                        <li className="menu-item">
                          <a href="blog-grid-2-columns-no-space.html">
                            Blog Grid 2 Columns No Space
                          </a>
                        </li>
                        <li className="menu-item">
                          <a href="blog-grid-3-columns.html">
                            Blog Grid 3 Columns
                          </a>
                        </li>
                        <li className="menu-item">
                          <a href="blog-grid-3-columns-no-space.html">
                            Blog Grid 3 Columns No Space
                          </a>
                        </li>
                        <li className="menu-item">
                          <a href="blog-grid-4-columns.html">
                            Blog Grid 4 Columns
                          </a>
                        </li>
                        <li className="menu-item">
                          <a href="blog-grid-4-columns-no-space.html">
                            Blog Grid 4 Columns No Space
                          </a>
                        </li>
                      </ul>
                    </li>
                    <li className="menu-item" data-size={60}>
                      <a href="single-blog.html">Single Posts</a>
                    </li>
                  </ul>
                </li>
                <li className="menu-ite hotale-normal-menu">
                  <a href="contact.html">Contact</a>
                </li> */}
                <li className="menu-ite hotale-normal-menu menu-item-home current-menu-item">
                  <div>
                    <a href="/">Home</a>
                  </div>
                </li>
                <li className="menu-ite hotale-normal-menu">
                  <div>
                    <a href="/gallery">Gallery</a>
                  </div>
                </li>
                <li className="menu-ite hotale-normal-menu">
                  <div>
                    <a href="/about">About Us</a>
                  </div>
                </li>
                <li className="menu-ite hotale-normal-menu">
                  <div>
                    <a href="/contact">Contact</a>
                  </div>
                </li>
              </ul>
              <div
                className="hotale-navigation-slide-bar hotale-navigation-slide-bar-style-2 hotale-left"
                data-size-offset={0}
                data-width="19px"
                id="hotale-navigation-slide-bar"
              />
            </div>
            {/* <div className="hotale-main-menu-right-wrap clearfix hotale-item-mglr hotale-navigation-top">
              <div className="tourmaster-currency-switcher">
                <span className="tourmaster-head">
                  <span>USD</span>
                  <i className="fa fa-sort-down" />
                </span>
                <div className="tourmaster-currency-switcher-inner">
                  <div className="tourmaster-currency-switcher-content">
                    <ul>
                      <li>
                        <a href="#">EUR</a>
                      </li>
                      <li>
                        <a href="#">CHF</a>
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
              <div
                className="tourmaster-user-top-bar tourmaster-guest tourmaster-style-2"
                data-redirect="index.html"
                data-ajax-url="#"
              >
                <span
                  className="tourmaster-user-top-bar-login"
                  data-tmlb="login"
                >
                  <i className="icon_lock_alt" />
                  <span className="tourmaster-text">Login</span>
                </span>
                <div
                  className="tourmaster-lightbox-content-wrap"
                  data-tmlb-id="login"
                >
                  <div className="tourmaster-lightbox-head">
                    <h3 className="tourmaster-lightbox-title">Login</h3>
                    <i className="tourmaster-lightbox-close icon_close" />
                  </div>
                  <div className="tourmaster-lightbox-content">
                    <form
                      className="tourmaster-login-form tourmaster-form-field tourmaster-with-border"
                      method="post"
                      action="#wp-login.php"
                    >
                      <div className="tourmaster-login-form-fields clearfix">
                        <p className="tourmaster-login-user">
                          <label>Username or E-Mail</label>
                          <input type="text" name="log" />
                        </p>
                        <p className="tourmaster-login-pass">
                          <label>Password</label>
                          <input type="password" name="pwd" />
                        </p>
                      </div>
                      <p className="tourmaster-login-submit">
                        <input
                          type="submit"
                          name="wp-submit"
                          className="tourmaster-button"
                          defaultValue="Sign In!"
                        />
                      </p>
                      <p className="tourmaster-login-lost-password">
                        <a href="#">Forget Password?</a>
                      </p>
                    </form>
                    <div className="tourmaster-login-bottom">
                      <h3 className="tourmaster-login-bottom-title">
                        Do not have an account?
                      </h3>
                      <a
                        className="tourmaster-login-bottom-link"
                        href="register.html"
                      >
                        Create an Account
                      </a>
                    </div>
                  </div>
                </div>
              </div>
            </div> */}
          </div>
          {/* hotale-navigation */}
        </div>
        {/* hotale-header-inner */}
      </div>
      {/* hotale-header-container */}
    </header>
  );
}
