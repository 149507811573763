import React from "react";

export default function Section4() {
  return (
    <>
      <div
        className="gdlr-core-pbf-wrapper"
        style={{ padding: "90px 0px 30px 0px" }}
        id="gdlr-core-wrapper-3"
      >
        <div className="gdlr-core-pbf-background-wrap" />
        <div className="gdlr-core-pbf-background-wrap" style={{ top: "110px" }}>
          <div
            className="gdlr-core-pbf-background gdlr-core-parallax gdlr-core-js"
            style={{
              backgroundImage: "url(upload/apartment2-column-bg.png)",
              backgroundRepeat: "no-repeat",
              backgroundPosition: "top center",
            }}
            data-parallax-speed={0}
          />
        </div>
        <div className="gdlr-core-pbf-wrapper-content gdlr-core-js">
          <div
            className="gdlr-core-pbf-wrapper-container clearfix gdlr-core-container"
            style={{ display: "flex", justifyContent: "center" }}
          >
            <div
              className="gdlr-core-pbf-column gdlr-core-column-60 gdlr-core-column-first"
              id="gdlr-core-column-7"
            >
              <div
                className="gdlr-core-pbf-column-content-margin gdlr-core-js"
                style={{ padding: "0px 0px 90px 0px" }}
              >
                <div className="gdlr-core-pbf-background-wrap" />
                <div
                  className="gdlr-core-pbf-column-content clearfix gdlr-core-js"
                  style={{ maxWidth: "580px" }}
                >
                  <div className="gdlr-core-pbf-element">
                    <div className="gdlr-core-title-item gdlr-core-item-pdb clearfix gdlr-core-center-align gdlr-core-title-item-caption-top gdlr-core-item-pdlr">
                      <div className="gdlr-core-title-item-title-wrap">
                        <h3
                          className="gdlr-core-title-item-title gdlr-core-skin-title class-test"
                          style={{
                            fontSize: "45px",
                            fontWeight: 700,
                            letterSpacing: "0px",
                            lineHeight: 1,
                            textTransform: "none",
                          }}
                        >
                          Room Tariff and Other charges
                          <span className="gdlr-core-title-item-title-divider gdlr-core-skin-divider" />
                        </h3>
                      </div>
                    </div>
                  </div>
                  <div className="gdlr-core-pbf-element">
                    <div className="gdlr-core-divider-item gdlr-core-divider-item-normal gdlr-core-item-pdlr gdlr-core-center-align">
                      <div
                        className="gdlr-core-divider-container"
                        style={{ maxWidth: "40px" }}
                      >
                        <div
                          className="gdlr-core-divider-line gdlr-core-skin-divider"
                          style={{
                            borderColor: "#ff5023",
                            borderWidth: "3px",
                          }}
                        />
                      </div>
                    </div>
                  </div>
                  <div className="gdlr-core-pbf-element">
                    <div
                      className="gdlr-core-text-box-item gdlr-core-item-pdlr gdlr-core-item-pdb gdlr-core-center-align"
                      style={{ paddingBottom: "20px" }}
                    >
                      <div
                        className="gdlr-core-text-box-item-content"
                        style={{
                          fontSize: "19px",
                          textTransform: "none",
                          color: "#94959b",
                        }}
                      >
                        {/* <p>
                          Search millions of apartments, houses for rent with
                          our exclusive hotels &amp; apartments app.
                        </p> */}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div
              className="gdlr-core-pbf-column gdlr-core-column-20 gdlr-core-column-first"
              id="gdlr-core-column-8"
            >
              <div
                className="gdlr-core-pbf-column-content-margin gdlr-core-js"
                style={{ padding: "0px 0px 30px 0px" }}
              >
                <div className="gdlr-core-pbf-background-wrap" />
                <div
                  className="gdlr-core-pbf-background-frame"
                  style={{
                    margin: "0px 20px 0px 20px",
                    borderWidth: "0px 0px 1px 0px",
                    borderStyle: "solid",
                    borderColor: "#eaeaea",
                  }}
                />
                <div
                  className="gdlr-core-pbf-column-content clearfix gdlr-core-js"
                  data-gdlr-animation="fadeInUp"
                  data-gdlr-animation-duration="600ms"
                  data-gdlr-animation-offset="0.8"
                >
                  <div className="gdlr-core-pbf-element">
                    <div className="gdlr-core-image-item gdlr-core-item-pdb gdlr-core-center-align gdlr-core-item-pdlr">
                      <div
                        className="gdlr-core-image-item-wrap gdlr-core-media-image gdlr-core-image-item-style-rectangle"
                        style={{ borderWidth: "0px" }}
                      >
                        <img
                          src="upload/shield.png"
                          alt=""
                          width={60}
                          height={60}
                          title="apartment2-col-1"
                        />
                      </div>
                    </div>
                  </div>
                  <div className="gdlr-core-pbf-element">
                    <div
                      className="gdlr-core-title-item gdlr-core-item-pdb clearfix gdlr-core-center-align gdlr-core-title-item-caption-top gdlr-core-item-pdlr"
                      style={{ paddingBottom: "25px" }}
                    >
                      <div className="gdlr-core-title-item-title-wrap">
                        <h3
                          className="gdlr-core-title-item-title gdlr-core-skin-title class-test"
                          style={{
                            fontSize: "21px",
                            fontWeight: 400,
                            letterSpacing: "0px",
                            lineHeight: 1,
                            textTransform: "none",
                          }}
                        >
                          Security Deposit
                          <span className="gdlr-core-title-item-title-divider gdlr-core-skin-divider" />
                        </h3>
                      </div>
                    </div>
                  </div>
                  <div className="gdlr-core-pbf-element">
                    <div
                      className="gdlr-core-text-box-item gdlr-core-item-pdlr gdlr-core-item-pdb gdlr-core-center-align"
                      style={{ paddingBottom: "20px" }}
                    >
                      <div
                        className="gdlr-core-text-box-item-content"
                        style={{
                          fontSize: "19px",
                          textTransform: "none",
                          color: "#94959b",
                        }}
                      >
                        <p>
                          The below listed amount should be paid as a security
                          deposit in advance along with the monthly rent which
                          shall carry no interest and will be refunded on
                          vacating the premises in a proper condition while on
                          the time of occupancy.
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div
              className="gdlr-core-pbf-column gdlr-core-column-20"
              id="gdlr-core-column-9"
            >
              <div
                className="gdlr-core-pbf-column-content-margin gdlr-core-js"
                style={{ padding: "0px 0px 30px 0px" }}
              >
                <div className="gdlr-core-pbf-background-wrap" />
                <div
                  className="gdlr-core-pbf-background-frame"
                  style={{
                    margin: "0px 20px 0px 20px",
                    borderWidth: "0px 0px 1px 0px",
                    borderStyle: "solid",
                    borderColor: "#eaeaea",
                  }}
                />
                <div
                  className="gdlr-core-pbf-column-content clearfix gdlr-core-js"
                  data-gdlr-animation="fadeInDown"
                  data-gdlr-animation-duration="600ms"
                  data-gdlr-animation-offset="0.8"
                >
                  <div className="gdlr-core-pbf-element">
                    <div className="gdlr-core-image-item gdlr-core-item-pdb gdlr-core-center-align gdlr-core-item-pdlr">
                      <div
                        className="gdlr-core-image-item-wrap gdlr-core-media-image gdlr-core-image-item-style-rectangle"
                        style={{ borderWidth: "0px" }}
                      >
                        <img
                          src="upload/tax.png"
                          alt=""
                          width={60}
                          height={60}
                          title="apartment2-col-2"
                        />
                      </div>
                    </div>
                  </div>
                  <div className="gdlr-core-pbf-element">
                    <div
                      className="gdlr-core-title-item gdlr-core-item-pdb clearfix gdlr-core-center-align gdlr-core-title-item-caption-top gdlr-core-item-pdlr"
                      style={{ paddingBottom: "25px" }}
                    >
                      <div className="gdlr-core-title-item-title-wrap">
                        <h3
                          className="gdlr-core-title-item-title gdlr-core-skin-title class-test"
                          style={{
                            fontSize: "21px",
                            fontWeight: 400,
                            letterSpacing: "0px",
                            lineHeight: 1,
                            textTransform: "none",
                          }}
                        >
                          Tariff Structure
                          <span className="gdlr-core-title-item-title-divider gdlr-core-skin-divider" />
                        </h3>
                      </div>
                    </div>
                  </div>
                  <div className="gdlr-core-pbf-element">
                    <div
                      className="gdlr-core-text-box-item gdlr-core-item-pdlr gdlr-core-item-pdb gdlr-core-center-align"
                      style={{ paddingBottom: "20px" }}
                    >
                      <div
                        className="gdlr-core-text-box-item-content"
                        style={{
                          fontSize: "19px",
                          textTransform: "none",
                          color: "#94959b",
                        }}
                      >
                        <p>
                          We have single rooms, twin sharing and triple sharing
                          rooms in both A/C and Non A/C which is starting from
                          Rs.6000 per person up to Rs.15000 per person.
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div
              className="gdlr-core-pbf-column gdlr-core-column-20"
              id="gdlr-core-column-10"
            >
              <div
                className="gdlr-core-pbf-column-content-margin gdlr-core-js"
                style={{ padding: "0px 0px 30px 0px" }}
              >
                <div className="gdlr-core-pbf-background-wrap" />
                <div
                  className="gdlr-core-pbf-background-frame"
                  style={{
                    margin: "0px 20px 0px 20px",
                    borderWidth: "0px 0px 1px 0px",
                    borderStyle: "solid",
                    borderColor: "#eaeaea",
                  }}
                />
                <div
                  className="gdlr-core-pbf-column-content clearfix gdlr-core-js"
                  data-gdlr-animation="fadeInUp"
                  data-gdlr-animation-duration="600ms"
                  data-gdlr-animation-offset="0.8"
                >
                  <div className="gdlr-core-pbf-element">
                    <div className="gdlr-core-image-item gdlr-core-item-pdb gdlr-core-center-align gdlr-core-item-pdlr">
                      <div
                        className="gdlr-core-image-item-wrap gdlr-core-media-image gdlr-core-image-item-style-rectangle"
                        style={{ borderWidth: "0px" }}
                      >
                        <img
                          src="upload/plug.png"
                          alt=""
                          width={60}
                          height={60}
                          title="apartment2-col-3"
                        />
                      </div>
                    </div>
                  </div>
                  <div className="gdlr-core-pbf-element">
                    <div
                      className="gdlr-core-title-item gdlr-core-item-pdb clearfix gdlr-core-center-align gdlr-core-title-item-caption-top gdlr-core-item-pdlr"
                      style={{ paddingBottom: "25px" }}
                    >
                      <div className="gdlr-core-title-item-title-wrap">
                        <h3
                          className="gdlr-core-title-item-title gdlr-core-skin-title class-test"
                          style={{
                            fontSize: "21px",
                            fontWeight: 400,
                            letterSpacing: "0px",
                            lineHeight: 1,
                            textTransform: "none",
                          }}
                        >
                          Electricity Charges
                          <span className="gdlr-core-title-item-title-divider gdlr-core-skin-divider" />
                        </h3>
                      </div>
                    </div>
                  </div>
                  <div className="gdlr-core-pbf-element">
                    <div
                      className="gdlr-core-text-box-item gdlr-core-item-pdlr gdlr-core-item-pdb gdlr-core-center-align"
                      style={{ paddingBottom: "20px" }}
                    >
                      <div
                        className="gdlr-core-text-box-item-content"
                        style={{
                          fontSize: "19px",
                          textTransform: "none",
                          color: "#94959b",
                        }}
                      >
                        <p>
                          The electricity charges will be paid additionally at
                          Rs.12 Per unit as per the individual meter of every
                          room. The total amount will be split equally amongst
                          the room members.
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div
              className="gdlr-core-pbf-column gdlr-core-column-20 gdlr-core-column-first"
              id="gdlr-core-column-8"
            >
              <div
                className="gdlr-core-pbf-column-content-margin gdlr-core-js"
                style={{ padding: "0px 0px 30px 0px" }}
              >
                <div className="gdlr-core-pbf-background-wrap" />
                <div
                  className="gdlr-core-pbf-background-frame"
                  style={{
                    margin: "0px 20px 0px 20px",
                    borderWidth: "0px 0px 1px 0px",
                    borderStyle: "solid",
                    borderColor: "#eaeaea",
                  }}
                />
                <div
                  className="gdlr-core-pbf-column-content clearfix gdlr-core-js"
                  data-gdlr-animation="fadeInUp"
                  data-gdlr-animation-duration="600ms"
                  data-gdlr-animation-offset="0.8"
                >
                  <div className="gdlr-core-pbf-element">
                    <div className="gdlr-core-image-item gdlr-core-item-pdb gdlr-core-center-align gdlr-core-item-pdlr">
                      <div
                        className="gdlr-core-image-item-wrap gdlr-core-media-image gdlr-core-image-item-style-rectangle"
                        style={{ borderWidth: "0px" }}
                      >
                        <img
                          src="upload/login.png"
                          alt=""
                          width={60}
                          height={60}
                          title="apartment2-col-1"
                        />
                      </div>
                    </div>
                  </div>
                  <div className="gdlr-core-pbf-element">
                    <div
                      className="gdlr-core-title-item gdlr-core-item-pdb clearfix gdlr-core-center-align gdlr-core-title-item-caption-top gdlr-core-item-pdlr"
                      style={{ paddingBottom: "25px" }}
                    >
                      <div className="gdlr-core-title-item-title-wrap">
                        <h3
                          className="gdlr-core-title-item-title gdlr-core-skin-title class-test"
                          style={{
                            fontSize: "21px",
                            fontWeight: 400,
                            letterSpacing: "0px",
                            lineHeight: 1,
                            textTransform: "none",
                          }}
                        >
                          Check in Procedure
                          <span className="gdlr-core-title-item-title-divider gdlr-core-skin-divider" />
                        </h3>
                      </div>
                    </div>
                  </div>
                  <div className="gdlr-core-pbf-element">
                    <div
                      className="gdlr-core-text-box-item gdlr-core-item-pdlr gdlr-core-item-pdb gdlr-core-center-align"
                      style={{ paddingBottom: "20px" }}
                    >
                      <div
                        className="gdlr-core-text-box-item-content"
                        style={{
                          fontSize: "19px",
                          textTransform: "none",
                          color: "#94959b",
                        }}
                      >
                        <p>
                          Occupants can check in during any day during the month
                          according to the room availability on submitting the
                          ID proof and a passport size photo.
                          <br />
                          The occupancy is for a minimum period of 30days
                          {/* <br />The first month tariff charges will be calculated from the date of occupancy till the end of the month. */}
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div
              className="gdlr-core-pbf-column gdlr-core-column-20"
              id="gdlr-core-column-9"
            >
              <div
                className="gdlr-core-pbf-column-content-margin gdlr-core-js"
                style={{ padding: "0px 0px 30px 0px" }}
              >
                <div className="gdlr-core-pbf-background-wrap" />
                <div
                  className="gdlr-core-pbf-background-frame"
                  style={{
                    margin: "0px 20px 0px 20px",
                    borderWidth: "0px 0px 1px 0px",
                    borderStyle: "solid",
                    borderColor: "#eaeaea",
                  }}
                />
                <div
                  className="gdlr-core-pbf-column-content clearfix gdlr-core-js"
                  data-gdlr-animation="fadeInDown"
                  data-gdlr-animation-duration="600ms"
                  data-gdlr-animation-offset="0.8"
                >
                  <div className="gdlr-core-pbf-element">
                    <div className="gdlr-core-image-item gdlr-core-item-pdb gdlr-core-center-align gdlr-core-item-pdlr">
                      <div
                        className="gdlr-core-image-item-wrap gdlr-core-media-image gdlr-core-image-item-style-rectangle"
                        style={{ borderWidth: "0px" }}
                      >
                        <img
                          // src="upload/apartment2-col-2.png"
                          src="upload/logout.png"
                          alt=""
                          width={60}
                          height={60}
                          title="apartment2-col-2"
                        />
                      </div>
                    </div>
                  </div>
                  <div className="gdlr-core-pbf-element">
                    <div
                      className="gdlr-core-title-item gdlr-core-item-pdb clearfix gdlr-core-center-align gdlr-core-title-item-caption-top gdlr-core-item-pdlr"
                      style={{ paddingBottom: "25px" }}
                    >
                      <div className="gdlr-core-title-item-title-wrap">
                        <h3
                          className="gdlr-core-title-item-title gdlr-core-skin-title class-test"
                          style={{
                            fontSize: "21px",
                            fontWeight: 400,
                            letterSpacing: "0px",
                            lineHeight: 1,
                            textTransform: "none",
                          }}
                        >
                          Checkout Procedure
                          <span className="gdlr-core-title-item-title-divider gdlr-core-skin-divider" />
                        </h3>
                      </div>
                    </div>
                  </div>
                  <div className="gdlr-core-pbf-element">
                    <div
                      className="gdlr-core-text-box-item gdlr-core-item-pdlr gdlr-core-item-pdb gdlr-core-center-align"
                      style={{ paddingBottom: "20px" }}
                    >
                      <div
                        className="gdlr-core-text-box-item-content"
                        style={{
                          fontSize: "19px",
                          textTransform: "none",
                          color: "#94959b",
                        }}
                      >
                        <p>
                          The date of checking out must be informed prior of
                          about 30days. On the time of checkout the security
                          deposit amount will be given back.{" "}
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
