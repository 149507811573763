import React from "react";
import Extra from "./components/Homepage/Extra";
import Footer from "./components/Homepage/Footer";
import Header from "./components/Homepage/header";
import Section1 from "./components/Homepage/Section-1";
import Section2 from "./components/Homepage/Section2";
import Section3 from "./components/Homepage/Section3";
import Section4 from "./components/Homepage/Section4";
import Section5 from "./components/Homepage/Section5";
import Section6 from "./components/Homepage/Section6";
import Section7 from "./components/Homepage/Section7";

export default function HomePage() {
  return (
    <div>
      <div className="hotale-mobile-header-wrap">
        <div
          className="hotale-mobile-header hotale-header-background hotale-style-slide hotale-sticky-mobile-navigation"
          id="hotale-mobile-header"
        >
          <div className="hotale-mobile-header-container hotale-container clearfix">
            <div className="hotale-logo hotale-item-pdlr">
              <div className="hotale-logo-inner">
                <a className="hotale-fixed-nav-logo" href="/">
                  <img
                    style={{ width: "30px" }}
                    src="/images/hostel.png"
                    alt=""
                    width={147}
                    height={40}
                    title="KSJ"
                  />
                  <span
                    style={{
                      fontSize: "23px",
                      fontWeight: 70,
                      letterSpacing: "0px",
                      lineHeight: 1,
                      textTransform: "none",
                      color: "#141414",
                    }}
                  >
                    {" "}
                    KSJ Stay
                  </span>
                </a>
                <a className="hotale-orig-logo" href="/">
                  <img
                    style={{ width: "30px" }}
                    src="images/hostel.png"
                    alt=""
                    width={294}
                    height={80}
                    title="KSJ"
                  />
                  <span
                    style={{
                      fontSize: "23px",
                      fontWeight: 70,
                      letterSpacing: "0px",
                      lineHeight: 1,
                      textTransform: "none",
                      color: "#141414",
                    }}
                  >
                    {" "}
                    KSJ
                  </span>
                </a>
              </div>
            </div>
            <div className="hotale-mobile-menu-right">
              <div
                className="tourmaster-user-top-bar tourmaster-guest tourmaster-style-2"
                data-redirect="/about"
                // data-ajax-url="#"
              >
                <span
                  className="tourmaster-user-top-bar-login"
                  // data-tmlb="login"
                >
                  <i className="icon_lock_alt" />
                  <span className="tourmaster-text">About</span>
                </span>
                {/* <div
                  className="tourmaster-lightbox-content-wrap"
                  data-tmlb-id="login"
                >
                  <div className="tourmaster-lightbox-head">
                    <h3 className="tourmaster-lightbox-title">Login</h3>
                    <i className="tourmaster-lightbox-close icon_close" />
                  </div>
                  <div className="tourmaster-lightbox-content">
                    <form
                      className="tourmaster-login-form tourmaster-form-field tourmaster-with-border"
                      method="post"
                      action="#wp-login.php"
                    >
                      <div className="tourmaster-login-form-fields clearfix">
                        <p className="tourmaster-login-user">
                          <label>Username or E-Mail</label>
                          <input type="text" name="log" />
                        </p>
                        <p className="tourmaster-login-pass">
                          <label>Password</label>
                          <input type="password" name="pwd" />
                        </p>
                      </div>
                      <p className="tourmaster-login-submit">
                        <input
                          type="submit"
                          name="wp-submit"
                          className="tourmaster-button"
                          defaultValue="Sign In!"
                        />
                      </p>
                      <p className="tourmaster-login-lost-password">
                        <a href="#">Forget Password?</a>
                      </p>
                    </form>
                    <div className="tourmaster-login-bottom">
                      <h3 className="tourmaster-login-bottom-title">
                        Do not have an account?
                      </h3>
                      <a
                        className="tourmaster-login-bottom-link"
                        href="register.html"
                      >
                        Create an Account
                      </a>
                    </div>
                  </div>
                </div> */}
              </div>
              <div className="hotale-mobile-menu">
                <a
                  className="hotale-mm-menu-button hotale-mobile-menu-button hotale-mobile-button-hamburger"
                  href="#hotale-mobile-menu"
                >
                  <span />
                </a>
                <div
                  className="hotale-mm-menu-wrap hotale-navigation-font"
                  id="hotale-mobile-menu"
                  data-slide="right"
                >
                  <ul id="menu-main-navigation" className="m-menu">
                    <li className="menu-item menu-item-home current-menu-item  menu-item-has-children">
                      <a href="/" aria-current="page">
                        Home
                      </a>
                      {/* <ul className="sub-menu">
                        <li className="menu-item menu-item-home current-menu-item page_item page-item-14980 current_page_item">
                          <a href="../resort/index.html" aria-current="page">
                            Home – Resort 1
                          </a>
                        </li>
                        <li className="menu-item">
                          <a
                            target="_blank"
                            rel="noopener"
                            href="../resort2/index.html"
                          >
                            Home – Resort 2
                          </a>
                        </li>
                        <li className="menu-item">
                          <a
                            target="_blank"
                            rel="noopener"
                            href="../hotel1/index.html"
                          >
                            Home – Hotel 1
                          </a>
                        </li>
                        <li className="menu-item">
                          <a
                            target="_blank"
                            rel="noopener"
                            href="../hotel2/index.html"
                          >
                            Home – Hotel 2
                          </a>
                        </li>
                        <li className="menu-item">
                          <a
                            rel="noopener"
                            target="_blank"
                            href="../apartment/index.html"
                          >
                            Home – Apartment
                          </a>
                        </li>
                        <li className="menu-item">
                          <a href="index.html">Home – Apartment 2</a>
                        </li>
                      </ul> */}
                    </li>
                    <li className="menu-item menu-item-has-children">
                      <a href="/gallery">Gallery</a>
                      {/* <ul className="sub-menu">
                        <li className="menu-item">
                          <a href="about-us.html">About Us</a>
                        </li>
                        <li className="menu-item">
                          <a href="about-us-2.html">About Us 2</a>
                        </li>
                        <li className="menu-item">
                          <a href="about-us-3.html">About Us 3</a>
                        </li>
                        <li className="menu-item">
                          <a href="our-team.html">Our Team</a>
                        </li>
                        <li className="menu-item">
                          <a href="hotel-review.html">Hotel Review</a>
                        </li>
                        <li className="menu-item">
                          <a href="faq.html">FAQ</a>
                        </li>
                        <li className="menu-item">
                          <a href="gallery.html">Gallery</a>
                        </li>
                        <li className="menu-item">
                          <a href="price-table.html">Price Table</a>
                        </li>
                        <li className="menu-item">
                          <a href="maintenance.html">Maintenance</a>
                        </li>
                        <li className="menu-item">
                          <a href="coming-soon.html">Coming Soon</a>
                        </li>
                        <li className="menu-item">
                          <a href="404.html">404 Page</a>
                        </li>
                      </ul> */}
                    </li>
                    <li className="menu-item menu-item-has-children">
                      <a href="room-grid-style-1.html">Rooms</a>
                      <ul className="sub-menu">
                        <li className="menu-item">
                          <a href="room-grid-style-1.html">Room Grid Style 1</a>
                        </li>
                        <li className="menu-item">
                          <a href="room-grid-style-2.html">Room Grid Style 2</a>
                        </li>
                        <li className="menu-item">
                          <a href="room-grid-style-3.html">Room Grid Style 3</a>
                        </li>
                        <li className="menu-item">
                          <a href="room-grid-style-4.html">Room Grid Style 4</a>
                        </li>
                        <li className="menu-item">
                          <a href="room-modern-style.html">Room Modern Style</a>
                        </li>
                        <li className="menu-item">
                          <a href="room-side-thumbnail.html">
                            Room Side Thumbnail
                          </a>
                        </li>
                      </ul>
                    </li>
                    <li className="menu-item">
                      <a href="room-search.html">Reservation</a>
                    </li>
                    <li className="menu-item menu-item-has-children">
                      <a href="blog-full-right-sidebar.html">Blog</a>
                      <ul className="sub-menu">
                        <li className="menu-item menu-item-has-children">
                          <a href="blog-3-columns-with-frame.html">
                            Blog Columns
                          </a>
                          <ul className="sub-menu">
                            <li className="menu-item">
                              <a href="blog-2-columns.html">Blog 2 Columns</a>
                            </li>
                            <li className="menu-item">
                              <a href="blog-2-columns-with-frame.html">
                                Blog 2 Columns With Frame
                              </a>
                            </li>
                            <li className="menu-item">
                              <a href="blog-3-columns.html">Blog 3 Columns</a>
                            </li>
                            <li className="menu-item">
                              <a href="blog-3-columns-with-frame.html">
                                Blog 3 Columns With Frame
                              </a>
                            </li>
                            <li className="menu-item">
                              <a href="blog-4-columns.html">Blog 4 Columns</a>
                            </li>
                            <li className="menu-item">
                              <a href="blog-4-columns-with-frame.html">
                                Blog 4 Columns With Frame
                              </a>
                            </li>
                          </ul>
                        </li>
                        <li className="menu-item menu-item-has-children">
                          <a href="blog-full-right-sidebar.html">Blog Full</a>
                          <ul className="sub-menu">
                            <li className="menu-item">
                              <a href="blog-full-right-sidebar.html">
                                Blog Full Right Sidebar
                              </a>
                            </li>
                            <li className="menu-item">
                              <a href="blog-full-right-sidebar-with-frame.html">
                                Blog Full Right Sidebar With Frame
                              </a>
                            </li>
                            <li className="menu-item">
                              <a href="blog-full-left-sidebar.html">
                                Blog Full Left Sidebar
                              </a>
                            </li>
                            <li className="menu-item">
                              <a href="blog-full-left-sidebar-with-frame.html">
                                Blog Full Left Sidebar With Frame
                              </a>
                            </li>
                            <li className="menu-item">
                              <a href="blog-full-both-sidebar.html">
                                Blog Full Both Sidebar
                              </a>
                            </li>
                            <li className="menu-item">
                              <a href="blog-full-both-sidebar-with-frame.html">
                                Blog Full Both Sidebar With Frame
                              </a>
                            </li>
                          </ul>
                        </li>
                        <li className="menu-item menu-item-has-children">
                          <a href="blog-grid-3-columns.html">Blog Grid</a>
                          <ul className="sub-menu">
                            <li className="menu-item">
                              <a href="blog-grid-2-columns.html">
                                Blog Grid 2 Columns
                              </a>
                            </li>
                            <li className="menu-item">
                              <a href="blog-grid-2-columns-no-space.html">
                                Blog Grid 2 Columns No Space
                              </a>
                            </li>
                            <li className="menu-item">
                              <a href="blog-grid-3-columns.html">
                                Blog Grid 3 Columns
                              </a>
                            </li>
                            <li className="menu-item">
                              <a href="blog-grid-3-columns-no-space.html">
                                Blog Grid 3 Columns No Space
                              </a>
                            </li>
                            <li className="menu-item">
                              <a href="blog-grid-4-columns.html">
                                Blog Grid 4 Columns
                              </a>
                            </li>
                            <li className="menu-item">
                              <a href="blog-grid-4-columns-no-space.html">
                                Blog Grid 4 Columns No Space
                              </a>
                            </li>
                          </ul>
                        </li>
                        <li className="menu-item">
                          <a href="single-blog.html">Single Posts</a>
                        </li>
                      </ul>
                    </li>
                    <li className="menu-item">
                      <a href="contact.html">Contact</a>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="hotale-body-outer-wrapper">
        <div className="hotale-body-wrapper clearfix hotale-with-frame">
          <Header />
          {/* header */}
          <div className="hotale-page-wrapper" id="hotale-page-wrapper">
            <div className="gdlr-core-page-builder-body">
              <Section1 />
              <Section2 />
              <Section3 />
              <Section4 />
              <Section5 />
              <Section6 />
              {/* <Section7 />
              <Extra /> */}
            </div>
          </div>
          <Footer />
        </div>
      </div>
    </div>
  );
}
