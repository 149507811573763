import React from "react";
import Footer from "./components/Homepage/Footer";
import Header from "./components/Homepage/header";

export default function AboutUS() {
  return (
    <div>
      <div className="hotale-mobile-header-wrap">
        <div
          className="hotale-mobile-header hotale-header-background hotale-style-slide hotale-sticky-mobile-navigation"
          id="hotale-mobile-header"
        >
          <div className="hotale-mobile-header-container hotale-container clearfix">
            <div className="hotale-logo hotale-item-pdlr">
              <div className="hotale-logo-inner">
                <a className="hotale-fixed-nav-logo" href="/">
                  <img
                    style={{ width: "30px" }}
                    src="images/hostel.png"
                    alt=""
                    width={147}
                    height={40}
                    title="KSJ"
                  />
                  <span
                    style={{
                      fontSize: "23px",
                      fontWeight: 70,
                      letterSpacing: "0px",
                      lineHeight: 1,
                      textTransform: "none",
                      color: "#141414",
                    }}
                  >
                    {" "}
                    KSJ Stay
                  </span>
                </a>
                <a className="hotale-orig-logo" href="index.html">
                  <img
                    style={{ width: "30px" }}
                    src="images/hostel.png"
                    alt=""
                    width={294}
                    height={80}
                    title="KSJ"
                  />
                  <span
                    style={{
                      fontSize: "23px",
                      fontWeight: 70,
                      letterSpacing: "0px",
                      lineHeight: 1,
                      textTransform: "none",
                      color: "#141414",
                    }}
                  >
                    {" "}
                    KSJ
                  </span>
                </a>
              </div>
            </div>
            <div className="hotale-mobile-menu-right">
              <div
                className="tourmaster-user-top-bar tourmaster-guest tourmaster-style-2"
                data-redirect="/"
                // data-ajax-url="#"
              >
                <span
                  className="tourmaster-user-top-bar-login"
                  // data-tmlb="login"
                >
                  <i className="icon_lock_alt" />
                  <span className="tourmaster-text">About</span>
                </span>
                {/* <div
                  className="tourmaster-lightbox-content-wrap"
                  data-tmlb-id="login"
                >
                  <div className="tourmaster-lightbox-head">
                    <h3 className="tourmaster-lightbox-title">Login</h3>
                    <i className="tourmaster-lightbox-close icon_close" />
                  </div>
                  <div className="tourmaster-lightbox-content">
                    <form
                      className="tourmaster-login-form tourmaster-form-field tourmaster-with-border"
                      method="post"
                      action="#wp-login.php"
                    >
                      <div className="tourmaster-login-form-fields clearfix">
                        <p className="tourmaster-login-user">
                          <label>Username or E-Mail</label>
                          <input type="text" name="log" />
                        </p>
                        <p className="tourmaster-login-pass">
                          <label>Password</label>
                          <input type="password" name="pwd" />
                        </p>
                      </div>
                      <p className="tourmaster-login-submit">
                        <input
                          type="submit"
                          name="wp-submit"
                          className="tourmaster-button"
                          defaultValue="Sign In!"
                        />
                      </p>
                      <p className="tourmaster-login-lost-password">
                        <a href="#">Forget Password?</a>
                      </p>
                    </form>
                    <div className="tourmaster-login-bottom">
                      <h3 className="tourmaster-login-bottom-title">
                        Do not have an account?
                      </h3>
                      <a
                        className="tourmaster-login-bottom-link"
                        href="register.html"
                      >
                        Create an Account
                      </a>
                    </div>
                  </div>
                </div> */}
              </div>
              <div className="hotale-mobile-menu">
                <a
                  className="hotale-mm-menu-button hotale-mobile-menu-button hotale-mobile-button-hamburger"
                  href="#hotale-mobile-menu"
                >
                  <span />
                </a>
                <div
                  className="hotale-mm-menu-wrap hotale-navigation-font"
                  id="hotale-mobile-menu"
                  data-slide="right"
                >
                  <ul id="menu-main-navigation" className="m-menu">
                    <li className="menu-item menu-item-home current-menu-item  menu-item-has-children">
                      <a href="index.html" aria-current="page">
                        Home
                      </a>
                      <ul className="sub-menu">
                        <li className="menu-item menu-item-home current-menu-item page_item page-item-14980 current_page_item">
                          <a href="../resort/index.html" aria-current="page">
                            Home – Resort 1
                          </a>
                        </li>
                        <li className="menu-item">
                          <a
                            target="_blank"
                            rel="noopener"
                            href="../resort2/index.html"
                          >
                            Home – Resort 2
                          </a>
                        </li>
                        <li className="menu-item">
                          <a
                            target="_blank"
                            rel="noopener"
                            href="../hotel1/index.html"
                          >
                            Home – Hotel 1
                          </a>
                        </li>
                        <li className="menu-item">
                          <a
                            target="_blank"
                            rel="noopener"
                            href="../hotel2/index.html"
                          >
                            Home – Hotel 2
                          </a>
                        </li>
                        <li className="menu-item">
                          <a
                            rel="noopener"
                            target="_blank"
                            href="../apartment/index.html"
                          >
                            Home – Apartment
                          </a>
                        </li>
                        <li className="menu-item">
                          <a href="index.html">Home – Apartment 2</a>
                        </li>
                      </ul>
                    </li>
                    <li className="menu-item menu-item-has-children">
                      <a href="#">Pages</a>
                      <ul className="sub-menu">
                        <li className="menu-item">
                          <a href="about-us.html">About Us</a>
                        </li>
                        <li className="menu-item">
                          <a href="about-us-2.html">About Us 2</a>
                        </li>
                        <li className="menu-item">
                          <a href="about-us-3.html">About Us 3</a>
                        </li>
                        <li className="menu-item">
                          <a href="our-team.html">Our Team</a>
                        </li>
                        <li className="menu-item">
                          <a href="hotel-review.html">Hotel Review</a>
                        </li>
                        <li className="menu-item">
                          <a href="faq.html">FAQ</a>
                        </li>
                        <li className="menu-item">
                          <a href="gallery.html">Gallery</a>
                        </li>
                        <li className="menu-item">
                          <a href="price-table.html">Price Table</a>
                        </li>
                        <li className="menu-item">
                          <a href="maintenance.html">Maintenance</a>
                        </li>
                        <li className="menu-item">
                          <a href="coming-soon.html">Coming Soon</a>
                        </li>
                        <li className="menu-item">
                          <a href="404.html">404 Page</a>
                        </li>
                      </ul>
                    </li>
                    <li className="menu-item menu-item-has-children">
                      <a href="room-grid-style-1.html">Rooms</a>
                      <ul className="sub-menu">
                        <li className="menu-item">
                          <a href="room-grid-style-1.html">Room Grid Style 1</a>
                        </li>
                        <li className="menu-item">
                          <a href="room-grid-style-2.html">Room Grid Style 2</a>
                        </li>
                        <li className="menu-item">
                          <a href="room-grid-style-3.html">Room Grid Style 3</a>
                        </li>
                        <li className="menu-item">
                          <a href="room-grid-style-4.html">Room Grid Style 4</a>
                        </li>
                        <li className="menu-item">
                          <a href="room-modern-style.html">Room Modern Style</a>
                        </li>
                        <li className="menu-item">
                          <a href="room-side-thumbnail.html">
                            Room Side Thumbnail
                          </a>
                        </li>
                      </ul>
                    </li>
                    <li className="menu-item">
                      <a href="room-search.html">Reservation</a>
                    </li>
                    <li className="menu-item menu-item-has-children">
                      <a href="blog-full-right-sidebar.html">Blog</a>
                      <ul className="sub-menu">
                        <li className="menu-item menu-item-has-children">
                          <a href="blog-3-columns-with-frame.html">
                            Blog Columns
                          </a>
                          <ul className="sub-menu">
                            <li className="menu-item">
                              <a href="blog-2-columns.html">Blog 2 Columns</a>
                            </li>
                            <li className="menu-item">
                              <a href="blog-2-columns-with-frame.html">
                                Blog 2 Columns With Frame
                              </a>
                            </li>
                            <li className="menu-item">
                              <a href="blog-3-columns.html">Blog 3 Columns</a>
                            </li>
                            <li className="menu-item">
                              <a href="blog-3-columns-with-frame.html">
                                Blog 3 Columns With Frame
                              </a>
                            </li>
                            <li className="menu-item">
                              <a href="blog-4-columns.html">Blog 4 Columns</a>
                            </li>
                            <li className="menu-item">
                              <a href="blog-4-columns-with-frame.html">
                                Blog 4 Columns With Frame
                              </a>
                            </li>
                          </ul>
                        </li>
                        <li className="menu-item menu-item-has-children">
                          <a href="blog-full-right-sidebar.html">Blog Full</a>
                          <ul className="sub-menu">
                            <li className="menu-item">
                              <a href="blog-full-right-sidebar.html">
                                Blog Full Right Sidebar
                              </a>
                            </li>
                            <li className="menu-item">
                              <a href="blog-full-right-sidebar-with-frame.html">
                                Blog Full Right Sidebar With Frame
                              </a>
                            </li>
                            <li className="menu-item">
                              <a href="blog-full-left-sidebar.html">
                                Blog Full Left Sidebar
                              </a>
                            </li>
                            <li className="menu-item">
                              <a href="blog-full-left-sidebar-with-frame.html">
                                Blog Full Left Sidebar With Frame
                              </a>
                            </li>
                            <li className="menu-item">
                              <a href="blog-full-both-sidebar.html">
                                Blog Full Both Sidebar
                              </a>
                            </li>
                            <li className="menu-item">
                              <a href="blog-full-both-sidebar-with-frame.html">
                                Blog Full Both Sidebar With Frame
                              </a>
                            </li>
                          </ul>
                        </li>
                        <li className="menu-item menu-item-has-children">
                          <a href="blog-grid-3-columns.html">Blog Grid</a>
                          <ul className="sub-menu">
                            <li className="menu-item">
                              <a href="blog-grid-2-columns.html">
                                Blog Grid 2 Columns
                              </a>
                            </li>
                            <li className="menu-item">
                              <a href="blog-grid-2-columns-no-space.html">
                                Blog Grid 2 Columns No Space
                              </a>
                            </li>
                            <li className="menu-item">
                              <a href="blog-grid-3-columns.html">
                                Blog Grid 3 Columns
                              </a>
                            </li>
                            <li className="menu-item">
                              <a href="blog-grid-3-columns-no-space.html">
                                Blog Grid 3 Columns No Space
                              </a>
                            </li>
                            <li className="menu-item">
                              <a href="blog-grid-4-columns.html">
                                Blog Grid 4 Columns
                              </a>
                            </li>
                            <li className="menu-item">
                              <a href="blog-grid-4-columns-no-space.html">
                                Blog Grid 4 Columns No Space
                              </a>
                            </li>
                          </ul>
                        </li>
                        <li className="menu-item">
                          <a href="single-blog.html">Single Posts</a>
                        </li>
                      </ul>
                    </li>
                    <li className="menu-item">
                      <a href="contact.html">Contact</a>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="hotale-body-outer-wrapper">
        <div className="hotale-body-wrapper clearfix hotale-with-frame">
          <Header />
          {/* header */}

          <div className="hotale-page-title-wrap hotale-style-custom hotale-center-align">
            <div className="hotale-header-transparent-substitute" />
            <div className="hotale-page-title-overlay" />
            {/* <div className="hotale-page-title-container hotale-container">
              <div className="hotale-page-title-content hotale-item-pdlr">
                <h1 className="hotale-page-title">About Us</h1>
              </div>
            </div> */}
          </div>
          <div className="hotale-page-wrapper" id="hotale-page-wrapper">
            <div className="gdlr-core-page-builder-body">
              {/* <div
                className="gdlr-core-pbf-wrapper"
                style={{ padding: "105px 0px 30px 0px" }}
                id="gdlr-core-wrapper-1"
              >
                <div className="gdlr-core-pbf-background-wrap" />
                <div className="gdlr-core-pbf-wrapper-content gdlr-core-js">
                  <div className="gdlr-core-pbf-wrapper-container clearfix gdlr-core-container">
                    <div className="gdlr-core-pbf-column gdlr-core-column-60 gdlr-core-column-first">
                      <div className="gdlr-core-pbf-column-content-margin gdlr-core-js">
                        <div className="gdlr-core-pbf-column-content clearfix gdlr-core-js">
                          <div className="gdlr-core-pbf-element">
                            <div
                              className="gdlr-core-title-item gdlr-core-item-pdb clearfix gdlr-core-center-align gdlr-core-title-item-caption-top gdlr-core-item-pdlr"
                              style={{ paddingBottom: "20px" }}
                              id="gdlr-core-title-item-1"
                            >
                              <div className="gdlr-core-title-item-title-wrap">
                                <h3
                                  className="gdlr-core-title-item-title gdlr-core-skin-title hotale-additional-font class-test"
                                  style={{
                                    fontSize: "80px",
                                    fontWeight: 400,
                                    letterSpacing: "0px",
                                    textTransform: "none",
                                    color: "#000000",
                                  }}
                                >
                                  Wide Range of Facilities
                                  <span className="gdlr-core-title-item-title-divider gdlr-core-skin-divider" />
                                </h3>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div
                      className="gdlr-core-pbf-column gdlr-core-column-60 gdlr-core-column-first"
                      id="gdlr-core-column-1"
                    >
                      <div
                        className="gdlr-core-pbf-column-content-margin gdlr-core-js"
                        style={{ padding: "0px 0px 20px 0px" }}
                      >
                        <div className="gdlr-core-pbf-background-wrap" />
                        <div
                          className="gdlr-core-pbf-column-content clearfix gdlr-core-js"
                          style={{ maxWidth: "760px" }}
                        >
                          <div className="gdlr-core-pbf-element">
                            <div className="gdlr-core-text-box-item gdlr-core-item-pdlr gdlr-core-item-pdb gdlr-core-center-align gdlr-core-no-p-space">
                              <div
                                className="gdlr-core-text-box-item-content"
                                style={{
                                  fontSize: "21px",
                                  fontWeight: 400,
                                  letterSpacing: "0px",
                                  textTransform: "none",
                                  color: "#898989",
                                }}
                              >
                                <p>
                                  <span style={{ whiteSpace: "pre-wrap" }}>
                                    Choose from a wide range of exclusive rooms,
                                    hotels, and apartments.
                                  </span>
                                </p>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div> */}

              {/* <div
                className="gdlr-core-pbf-wrapper"
                style={{ padding: "30px 0px 90px 0px" }}
                id="gdlr-core-wrapper-2"
              >
                <div className="gdlr-core-pbf-background-wrap" />
                <div
                  className="gdlr-core-pbf-background-wrap"
                  style={{ top: "20px" }}
                >
                  <div
                    className="gdlr-core-pbf-background gdlr-core-parallax gdlr-core-js"
                    style={{
                      backgroundRepeat: "no-repeat",
                      backgroundPosition: "top center",
                    }}
                    data-parallax-speed={0}
                  />
                </div>
                <div className="gdlr-core-pbf-wrapper-content gdlr-core-js">
                  <div className="gdlr-core-pbf-wrapper-container clearfix gdlr-core-container">
                    <div
                      className="gdlr-core-pbf-column gdlr-core-column-20 gdlr-core-column-first"
                      id="gdlr-core-column-2"
                    >
                      <div
                        className="gdlr-core-pbf-column-content-margin gdlr-core-js"
                        style={{ padding: "30px 40px 5px 40px" }}
                      >
                        <div
                          className="gdlr-core-pbf-background-wrap"
                          style={{
                            borderWidth: "0px 0px 1px 0px",
                            borderColor: "#eaeaea",
                            borderStyle: "solid",
                          }}
                        />
                        <div className="gdlr-core-pbf-column-content clearfix gdlr-core-js">
                          <div className="gdlr-core-pbf-element">
                            <div
                              className="gdlr-core-column-service-item gdlr-core-item-pdb gdlr-core-center-align gdlr-core-no-caption gdlr-core-item-pdlr"
                              style={{ paddingBottom: "30px" }}
                            >
                              <div
                                className="gdlr-core-column-service-media gdlr-core-media-image"
                                style={{
                                  maxWidth: "70.5px",
                                  marginLeft: "auto",
                                  marginRight: "auto",
                                }}
                              >
                                <img
                                  src="upload/about-2-icon-1.png"
                                  alt=""
                                  width={141}
                                  height={140}
                                  title="about-2-icon-1"
                                />
                              </div>
                              <div className="gdlr-core-column-service-content-wrapper">
                                <div className="gdlr-core-column-service-title-wrap">
                                  <h3
                                    className="gdlr-core-column-service-title gdlr-core-skin-title"
                                    style={{
                                      fontSize: "24px",
                                      fontWeight: 500,
                                      textTransform: "none",
                                    }}
                                  >
                                    Resort
                                  </h3>
                                </div>
                                <div
                                  className="gdlr-core-column-service-content"
                                  style={{
                                    fontSize: "16px",
                                    textTransform: "none",
                                  }}
                                >
                                  <p>
                                    Choose from a wide range of exclusive rooms,
                                    hotels, and apartments.
                                  </p>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div
                      className="gdlr-core-pbf-column gdlr-core-column-20"
                      id="gdlr-core-column-3"
                    >
                      <div
                        className="gdlr-core-pbf-column-content-margin gdlr-core-js"
                        style={{ padding: "30px 40px 5px 40px" }}
                      >
                        <div
                          className="gdlr-core-pbf-background-wrap"
                          style={{
                            borderWidth: "0px 1px 1px 1px",
                            borderColor: "#eaeaea",
                            borderStyle: "solid",
                          }}
                        />
                        <div className="gdlr-core-pbf-column-content clearfix gdlr-core-js">
                          <div className="gdlr-core-pbf-element">
                            <div
                              className="gdlr-core-column-service-item gdlr-core-item-pdb gdlr-core-center-align gdlr-core-no-caption gdlr-core-item-pdlr"
                              style={{ paddingBottom: "30px" }}
                            >
                              <div
                                className="gdlr-core-column-service-media gdlr-core-media-image"
                                style={{
                                  maxWidth: "70px",
                                  marginLeft: "auto",
                                  marginRight: "auto",
                                }}
                              >
                                <img
                                  src="upload/about-2-icon-2.png"
                                  alt=""
                                  width={140}
                                  height={140}
                                  title="about-2-icon-2"
                                />
                              </div>
                              <div className="gdlr-core-column-service-content-wrapper">
                                <div className="gdlr-core-column-service-title-wrap">
                                  <h3
                                    className="gdlr-core-column-service-title gdlr-core-skin-title"
                                    style={{
                                      fontSize: "24px",
                                      fontWeight: 500,
                                      textTransform: "none",
                                    }}
                                  >
                                    Playground
                                  </h3>
                                </div>
                                <div
                                  className="gdlr-core-column-service-content"
                                  style={{
                                    fontSize: "16px",
                                    textTransform: "none",
                                  }}
                                >
                                  <p>
                                    Choose from a wide range of exclusive rooms,
                                    hotels, and apartments.
                                  </p>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div
                      className="gdlr-core-pbf-column gdlr-core-column-20"
                      id="gdlr-core-column-4"
                    >
                      <div
                        className="gdlr-core-pbf-column-content-margin gdlr-core-js"
                        style={{ padding: "30px 40px 5px 40px" }}
                      >
                        <div
                          className="gdlr-core-pbf-background-wrap"
                          style={{
                            borderWidth: "0px 0px 1px 0px",
                            borderColor: "#eaeaea",
                            borderStyle: "solid",
                          }}
                        />
                        <div className="gdlr-core-pbf-column-content clearfix gdlr-core-js">
                          <div className="gdlr-core-pbf-element">
                            <div
                              className="gdlr-core-column-service-item gdlr-core-item-pdb gdlr-core-center-align gdlr-core-no-caption gdlr-core-item-pdlr"
                              style={{ paddingBottom: "30px" }}
                            >
                              <div
                                className="gdlr-core-column-service-media gdlr-core-media-image"
                                style={{
                                  maxWidth: "85.5px",
                                  marginLeft: "auto",
                                  marginRight: "auto",
                                }}
                              >
                                <img
                                  src="upload/about-2-icon-3.png"
                                  alt=""
                                  width={171}
                                  height={140}
                                  title="about-2-icon-3"
                                />
                              </div>
                              <div className="gdlr-core-column-service-content-wrapper">
                                <div className="gdlr-core-column-service-title-wrap">
                                  <h3
                                    className="gdlr-core-column-service-title gdlr-core-skin-title"
                                    style={{
                                      fontSize: "24px",
                                      fontWeight: 500,
                                      textTransform: "none",
                                    }}
                                  >
                                    Reservation
                                  </h3>
                                </div>
                                <div
                                  className="gdlr-core-column-service-content"
                                  style={{
                                    fontSize: "16px",
                                    textTransform: "none",
                                  }}
                                >
                                  <p>
                                    Choose from a wide range of exclusive rooms,
                                    hotels, and apartments.
                                  </p>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div
                      className="gdlr-core-pbf-column gdlr-core-column-20 gdlr-core-column-first"
                      id="gdlr-core-column-5"
                    >
                      <div
                        className="gdlr-core-pbf-column-content-margin gdlr-core-js"
                        style={{ padding: "30px 40px 5px 40px" }}
                      >
                        <div
                          className="gdlr-core-pbf-background-wrap"
                          style={{
                            borderWidth: "0px 0px 0px 0px",
                            borderColor: "#eaeaea",
                            borderStyle: "solid",
                          }}
                        />
                        <div className="gdlr-core-pbf-column-content clearfix gdlr-core-js">
                          <div className="gdlr-core-pbf-element">
                            <div
                              className="gdlr-core-column-service-item gdlr-core-item-pdb gdlr-core-center-align gdlr-core-no-caption gdlr-core-item-pdlr"
                              style={{ paddingBottom: "30px" }}
                            >
                              <div
                                className="gdlr-core-column-service-media gdlr-core-media-image"
                                style={{
                                  maxWidth: "70.5px",
                                  marginLeft: "auto",
                                  marginRight: "auto",
                                }}
                              >
                                <img
                                  src="upload/about-2-icon-4.png"
                                  alt=""
                                  width={141}
                                  height={140}
                                  title="about-2-icon-4"
                                />
                              </div>
                              <div className="gdlr-core-column-service-content-wrapper">
                                <div className="gdlr-core-column-service-title-wrap">
                                  <h3
                                    className="gdlr-core-column-service-title gdlr-core-skin-title"
                                    style={{
                                      fontSize: "24px",
                                      fontWeight: 500,
                                      textTransform: "none",
                                    }}
                                  >
                                    Casino
                                  </h3>
                                </div>
                                <div
                                  className="gdlr-core-column-service-content"
                                  style={{
                                    fontSize: "16px",
                                    textTransform: "none",
                                  }}
                                >
                                  <p>
                                    Choose from a wide range of exclusive rooms,
                                    hotels, and apartments.
                                  </p>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div
                      className="gdlr-core-pbf-column gdlr-core-column-20"
                      id="gdlr-core-column-6"
                    >
                      <div
                        className="gdlr-core-pbf-column-content-margin gdlr-core-js"
                        style={{ padding: "30px 40px 5px 40px" }}
                      >
                        <div
                          className="gdlr-core-pbf-background-wrap"
                          style={{
                            borderWidth: "0px 1px 0px 1px",
                            borderColor: "#eaeaea",
                            borderStyle: "solid",
                          }}
                        />
                        <div className="gdlr-core-pbf-column-content clearfix gdlr-core-js">
                          <div className="gdlr-core-pbf-element">
                            <div
                              className="gdlr-core-column-service-item gdlr-core-item-pdb gdlr-core-center-align gdlr-core-no-caption gdlr-core-item-pdlr"
                              style={{ paddingBottom: "30px" }}
                            >
                              <div
                                className="gdlr-core-column-service-media gdlr-core-media-image"
                                style={{
                                  maxWidth: "70px",
                                  marginLeft: "auto",
                                  marginRight: "auto",
                                }}
                              >
                                <img
                                  src="upload/about-2-icon-5.png"
                                  alt=""
                                  width={140}
                                  height={140}
                                  title="about-2-icon-5"
                                />
                              </div>
                              <div className="gdlr-core-column-service-content-wrapper">
                                <div className="gdlr-core-column-service-title-wrap">
                                  <h3
                                    className="gdlr-core-column-service-title gdlr-core-skin-title"
                                    style={{
                                      fontSize: "24px",
                                      fontWeight: 500,
                                      textTransform: "none",
                                    }}
                                  >
                                    Room Service
                                  </h3>
                                </div>
                                <div
                                  className="gdlr-core-column-service-content"
                                  style={{
                                    fontSize: "16px",
                                    textTransform: "none",
                                  }}
                                >
                                  <p>
                                    Choose from a wide range of exclusive rooms,
                                    hotels, and apartments.
                                  </p>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div
                      className="gdlr-core-pbf-column gdlr-core-column-20"
                      id="gdlr-core-column-7"
                    >
                      <div
                        className="gdlr-core-pbf-column-content-margin gdlr-core-js"
                        style={{ padding: "30px 40px 5px 40px" }}
                      >
                        <div
                          className="gdlr-core-pbf-background-wrap"
                          style={{
                            borderWidth: "0px 0px 0px 0px",
                            borderColor: "#eaeaea",
                            borderStyle: "solid",
                          }}
                        />
                        <div className="gdlr-core-pbf-column-content clearfix gdlr-core-js">
                          <div className="gdlr-core-pbf-element">
                            <div
                              className="gdlr-core-column-service-item gdlr-core-item-pdb gdlr-core-center-align gdlr-core-no-caption gdlr-core-item-pdlr"
                              style={{ paddingBottom: "30px" }}
                            >
                              <div
                                className="gdlr-core-column-service-media gdlr-core-media-image"
                                style={{
                                  maxWidth: "74px",
                                  marginLeft: "auto",
                                  marginRight: "auto",
                                }}
                              >
                                <img
                                  src="upload/about-2-icon-6-1.png"
                                  alt=""
                                  width={148}
                                  height={148}
                                  title="about-2-icon-6"
                                />
                              </div>
                              <div className="gdlr-core-column-service-content-wrapper">
                                <div className="gdlr-core-column-service-title-wrap">
                                  <h3
                                    className="gdlr-core-column-service-title gdlr-core-skin-title"
                                    style={{
                                      fontSize: "24px",
                                      fontWeight: 500,
                                      textTransform: "none",
                                    }}
                                  >
                                    Free Wifi
                                  </h3>
                                </div>
                                <div
                                  className="gdlr-core-column-service-content"
                                  style={{
                                    fontSize: "16px",
                                    textTransform: "none",
                                  }}
                                >
                                  <p>
                                    Choose from a wide range of exclusive rooms,
                                    hotels, and apartments.
                                  </p>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div> */}

              <div
                className="gdlr-core-pbf-wrapper"
                style={{ padding: "55px 0px 150px 0px" }}
                id="gdlr-core-wrapper-3"
              >
                <div className="gdlr-core-pbf-background-wrap" />
                <div
                  className="gdlr-core-pbf-background-wrap"
                  style={{ top: "10px" }}
                >
                  <div
                    className="gdlr-core-pbf-background gdlr-core-parallax gdlr-core-js"
                    style={{
                      backgroundImage: "url(upload/about-2-about-bg.png)",
                      backgroundRepeat: "no-repeat",
                      backgroundPosition: "top center",
                    }}
                    data-parallax-speed="0.1"
                  />
                </div>
                <div className="gdlr-core-pbf-wrapper-content gdlr-core-js">
                  <div className="gdlr-core-pbf-wrapper-container clearfix gdlr-core-container">
                    <div
                      className="gdlr-core-pbf-column gdlr-core-column-30 gdlr-core-column-first"
                      id="gdlr-core-column-8"
                    >
                      <div
                        className="gdlr-core-pbf-column-content-margin gdlr-core-js"
                        style={{
                          margin: "0px 0px 0px 0px",
                          padding: "40px 90px 0px 0px",
                        }}
                      >
                        <div className="gdlr-core-pbf-background-wrap" />
                        <div className="gdlr-core-pbf-column-content clearfix gdlr-core-js">
                          <div className="gdlr-core-pbf-element">
                            <div
                              className="gdlr-core-title-item gdlr-core-item-pdb clearfix gdlr-core-left-align gdlr-core-title-item-caption-top gdlr-core-item-pdlr"
                              style={{ paddingBottom: "20px" }}
                            >
                              <div className="gdlr-core-title-item-title-wrap">
                                <h3
                                  className="gdlr-core-title-item-title gdlr-core-skin-title class-test"
                                  style={{
                                    fontSize: "37px",
                                    fontWeight: 400,
                                    letterSpacing: "0px",
                                    textTransform: "none",
                                  }}
                                >
                                  Hey, it’s KSJ Stay
                                  <span className="gdlr-core-title-item-title-divider gdlr-core-skin-divider" />
                                </h3>
                              </div>
                            </div>
                          </div>
                          <div className="gdlr-core-pbf-element">
                            <div className="gdlr-core-divider-item gdlr-core-divider-item-normal gdlr-core-item-pdlr gdlr-core-left-align">
                              <div
                                className="gdlr-core-divider-container"
                                style={{ maxWidth: "54px" }}
                              >
                                <div
                                  className="gdlr-core-divider-line gdlr-core-skin-divider"
                                  style={{
                                    borderColor: "#ff5023",
                                    borderWidth: "5px",
                                  }}
                                />
                              </div>
                            </div>
                          </div>
                          <div className="gdlr-core-pbf-element">
                            <div
                              className="gdlr-core-text-box-item gdlr-core-item-pdlr gdlr-core-item-pdb gdlr-core-left-align"
                              style={{ paddingBottom: "22px" }}
                            >
                              <div
                                className="gdlr-core-text-box-item-content"
                                style={{
                                  fontSize: "20px",
                                  textTransform: "none",
                                  color: "#94959b",
                                }}
                              >
                                <p>
                                  KSJ stay in Chennai is our service to the
                                  working women of the city for the past 15
                                  years. There is about 75 occupancy in our PG.
                                  Every year we accommodate 60% of new guests in
                                  our premises. Hence it’s a feel good second
                                  home with wider amenities provided with
                                  comfort along with our experience in this
                                  field.
                                </p>
                              </div>
                            </div>
                          </div>
                          <div className="gdlr-core-pbf-element">
                            <div
                              className="gdlr-core-text-box-item gdlr-core-item-pdlr gdlr-core-item-pdb gdlr-core-left-align"
                              style={{ paddingBottom: "22px" }}
                            >
                              <div
                                className="gdlr-core-text-box-item-content"
                                style={{
                                  fontSize: "18px",
                                  textTransform: "none",
                                  color: "#94959b",
                                }}
                              >
                                <p>
                                  We provide a top-tier comfort stay for working
                                  women who needs all the amenities. Our stay
                                  comes with an assurance for Discipline,
                                  Security and an amazing care. Our mature
                                  process and system in the place assures you
                                  our best services during your entire stay.{" "}
                                </p>
                              </div>
                            </div>
                          </div>

                          {/* <div className="gdlr-core-pbf-element">
                            <div className="gdlr-core-button-item gdlr-core-item-pdlr gdlr-core-item-pdb gdlr-core-left-align">
                              <a
                                className="gdlr-core-button gdlr-core-button-solid gdlr-core-left-align gdlr-core-button-no-border"
                                href="room-search.html"
                                id="gdlr-core-button-id-1"
                              >
                                 <span className="gdlr-core-content">
                                  Book Now
                                </span> 
                              </a>
                            </div>
                          </div> */}
                        </div>
                      </div>
                    </div>
                    <div
                      className="gdlr-core-pbf-column gdlr-core-column-30"
                      id="gdlr-core-column-9"
                    >
                      <div className="gdlr-core-pbf-column-content-margin gdlr-core-js">
                        <div className="gdlr-core-pbf-background-wrap" />
                        <div className="gdlr-core-pbf-column-content clearfix gdlr-core-js">
                          <div className="gdlr-core-pbf-element">
                            <div className="gdlr-core-image-item gdlr-core-item-pdb gdlr-core-center-align gdlr-core-item-pdlr">
                              <div
                                className="gdlr-core-image-item-wrap gdlr-core-media-image gdlr-core-image-item-style-rectangle"
                                style={{ borderWidth: "0px" }}
                              >
                                <img
                                  src="images/13.jpg"
                                  alt=""
                                  width={570}
                                  height={450}
                                  title="about-2-right"
                                />
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              <div
                className="gdlr-core-pbf-wrapper"
                style={{ padding: "105px 0px 30px 0px" }}
                id="gdlr-core-wrapper-1"
              >
                <div className="gdlr-core-pbf-background-wrap" />
                <div className="gdlr-core-pbf-wrapper-content gdlr-core-js">
                  <div className="gdlr-core-pbf-wrapper-container clearfix gdlr-core-container">
                    <div className="gdlr-core-pbf-column gdlr-core-column-60 gdlr-core-column-first">
                      <div className="gdlr-core-pbf-column-content-margin gdlr-core-js">
                        <div className="gdlr-core-pbf-column-content clearfix gdlr-core-js">
                          <div className="gdlr-core-pbf-element">
                            <div
                              className="gdlr-core-title-item gdlr-core-item-pdb clearfix gdlr-core-center-align gdlr-core-title-item-caption-top gdlr-core-item-pdlr"
                              style={{ paddingBottom: "20px" }}
                              id="gdlr-core-title-item-1"
                            >
                              <div className="gdlr-core-title-item-title-wrap">
                                <h3
                                  className="gdlr-core-title-item-title gdlr-core-skin-title hotale-additional-font class-test"
                                  style={{
                                    fontSize: "80px",
                                    fontWeight: 400,
                                    letterSpacing: "0px",
                                    textTransform: "none",
                                    color: "#000000",
                                  }}
                                >
                                  Wide Range of Facilities
                                  <span className="gdlr-core-title-item-title-divider gdlr-core-skin-divider" />
                                </h3>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    {/* <div
                      className="gdlr-core-pbf-column gdlr-core-column-60 gdlr-core-column-first"
                      id="gdlr-core-column-1"
                    >
                      <div
                        className="gdlr-core-pbf-column-content-margin gdlr-core-js"
                        style={{ padding: "0px 0px 20px 0px" }}
                      >
                        <div className="gdlr-core-pbf-background-wrap" />
                        <div
                          className="gdlr-core-pbf-column-content clearfix gdlr-core-js"
                          style={{ maxWidth: "760px" }}
                        >
                          <div className="gdlr-core-pbf-element">
                            <div className="gdlr-core-text-box-item gdlr-core-item-pdlr gdlr-core-item-pdb gdlr-core-center-align gdlr-core-no-p-space">
                              <div
                                className="gdlr-core-text-box-item-content"
                                style={{
                                  fontSize: "21px",
                                  fontWeight: 400,
                                  letterSpacing: "0px",
                                  textTransform: "none",
                                  color: "#898989",
                                }}
                              >
                                <p>
                                  <span style={{ whiteSpace: "pre-wrap" }}>
                                    Choose from a wide range of exclusive rooms,
                                    hotels, and apartments.
                                  </span>
                                </p>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div> */}
                  </div>
                </div>
              </div>

              <div
                className="gdlr-core-pbf-wrapper"
                style={{ padding: "30px 0px 90px 0px" }}
                id="gdlr-core-wrapper-2"
              >
                <div className="gdlr-core-pbf-background-wrap" />
                <div
                  className="gdlr-core-pbf-background-wrap"
                  style={{ top: "20px" }}
                >
                  <div
                    className="gdlr-core-pbf-background gdlr-core-parallax gdlr-core-js"
                    style={{
                      backgroundRepeat: "no-repeat",
                      backgroundPosition: "top center",
                    }}
                    data-parallax-speed={0}
                  />
                </div>
                <div className="gdlr-core-pbf-wrapper-content gdlr-core-js">
                  <div className="gdlr-core-pbf-wrapper-container clearfix gdlr-core-container">
                    <div
                      className="gdlr-core-pbf-column gdlr-core-column-20 gdlr-core-column-first"
                      id="gdlr-core-column-2"
                    >
                      <div
                        className="gdlr-core-pbf-column-content-margin gdlr-core-js"
                        style={{ padding: "30px 40px 5px 40px" }}
                      >
                        <div
                          className="gdlr-core-pbf-background-wrap"
                          style={{
                            borderWidth: "0px 0px 1px 0px",
                            borderColor: "#eaeaea",
                            borderStyle: "solid",
                          }}
                        />
                        <div className="gdlr-core-pbf-column-content clearfix gdlr-core-js">
                          <div className="gdlr-core-pbf-element">
                            <div
                              className="gdlr-core-column-service-item gdlr-core-item-pdb gdlr-core-center-align gdlr-core-no-caption gdlr-core-item-pdlr"
                              style={{ paddingBottom: "30px" }}
                            >
                              <div
                                className="gdlr-core-column-service-media gdlr-core-media-image"
                                style={{
                                  maxWidth: "70.5px",
                                  marginLeft: "auto",
                                  marginRight: "auto",
                                }}
                              >
                                <img
                                  src="upload/dressing-room.png"
                                  alt=""
                                  width={141}
                                  height={140}
                                  title="about-2-icon-1"
                                />
                              </div>
                              <div
                                className="gdlr-core-column-service-content-wrapper"
                                style={{ marginBottom: "50px" }}
                              >
                                <div className="gdlr-core-column-service-title-wrap">
                                  <h3
                                    className="gdlr-core-column-service-title gdlr-core-skin-title"
                                    style={{
                                      fontSize: "24px",
                                      fontWeight: 500,
                                      textTransform: "none",
                                    }}
                                  >
                                    Furnished Rooms
                                  </h3>
                                </div>
                                {/* <div
                                  className="gdlr-core-column-service-content"
                                  style={{
                                    fontSize: "16px",
                                    textTransform: "none",
                                  }}
                                >
                                  <p>
                                    Choose from a wide range of exclusive rooms,
                                    hotels, and apartments.
                                  </p>
                                </div> */}
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div
                      className="gdlr-core-pbf-column gdlr-core-column-20"
                      id="gdlr-core-column-3"
                    >
                      <div
                        className="gdlr-core-pbf-column-content-margin gdlr-core-js"
                        style={{ padding: "30px 40px 5px 40px" }}
                      >
                        <div
                          className="gdlr-core-pbf-background-wrap"
                          style={{
                            borderWidth: "0px 1px 1px 1px",
                            borderColor: "#eaeaea",
                            borderStyle: "solid",
                          }}
                        />
                        <div className="gdlr-core-pbf-column-content clearfix gdlr-core-js">
                          <div className="gdlr-core-pbf-element">
                            <div
                              className="gdlr-core-column-service-item gdlr-core-item-pdb gdlr-core-center-align gdlr-core-no-caption gdlr-core-item-pdlr"
                              style={{ paddingBottom: "30px" }}
                            >
                              <div
                                className="gdlr-core-column-service-media gdlr-core-media-image"
                                style={{
                                  maxWidth: "70px",
                                  marginLeft: "auto",
                                  marginRight: "auto",
                                }}
                              >
                                <img
                                  src="upload/broccoli.png"
                                  alt=""
                                  width={140}
                                  height={140}
                                  title="about-2-icon-2"
                                />
                              </div>
                              <div className="gdlr-core-column-service-content-wrapper">
                                <div className="gdlr-core-column-service-title-wrap">
                                  <h3
                                    className="gdlr-core-column-service-title gdlr-core-skin-title"
                                    style={{
                                      fontSize: "24px",
                                      fontWeight: 500,
                                      textTransform: "none",
                                    }}
                                  >
                                    Freshly cooked In-house Food for all
                                    occupants
                                  </h3>
                                </div>
                                {/* <div
                                  className="gdlr-core-column-service-content"
                                  style={{
                                    fontSize: "16px",
                                    textTransform: "none",
                                  }}
                                >
                                  <p>
                                    Choose from a wide range of exclusive rooms,
                                    hotels, and apartments.
                                  </p>
                                </div> */}
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div
                      className="gdlr-core-pbf-column gdlr-core-column-20"
                      id="gdlr-core-column-4"
                    >
                      <div
                        className="gdlr-core-pbf-column-content-margin gdlr-core-js"
                        style={{ padding: "30px 40px 5px 40px" }}
                      >
                        <div
                          className="gdlr-core-pbf-background-wrap"
                          style={{
                            borderWidth: "0px 0px 1px 0px",
                            borderColor: "#eaeaea",
                            borderStyle: "solid",
                          }}
                        />
                        <div className="gdlr-core-pbf-column-content clearfix gdlr-core-js">
                          <div className="gdlr-core-pbf-element">
                            <div
                              className="gdlr-core-column-service-item gdlr-core-item-pdb gdlr-core-center-align gdlr-core-no-caption gdlr-core-item-pdlr"
                              style={{ paddingBottom: "30px" }}
                            >
                              <div
                                className="gdlr-core-column-service-media gdlr-core-media-image"
                                style={{
                                  maxWidth: "85.5px",
                                  marginLeft: "auto",
                                  marginRight: "auto",
                                }}
                              >
                                <img
                                  src="upload/ref.png"
                                  alt=""
                                  width={171}
                                  height={140}
                                  title="about-2-icon-3"
                                />
                              </div>
                              <div
                                className="gdlr-core-column-service-content-wrapper"
                                style={{ marginBottom: "35px" }}
                              >
                                <div className="gdlr-core-column-service-title-wrap">
                                  <h3
                                    className="gdlr-core-column-service-title gdlr-core-skin-title"
                                    style={{
                                      fontSize: "24px",
                                      fontWeight: 500,
                                      textTransform: "none",
                                    }}
                                  >
                                    Refrigerators
                                  </h3>
                                </div>
                                {/* <div
                                  className="gdlr-core-column-service-content"
                                  style={{
                                    fontSize: "16px",
                                    textTransform: "none",
                                  }}
                                >
                                  <p>
                                    Choose from a wide range of exclusive rooms,
                                    hotels, and apartments.
                                  </p>
                                </div> */}
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div
                      className="gdlr-core-pbf-column gdlr-core-column-20 gdlr-core-column-first"
                      id="gdlr-core-column-5"
                    >
                      <div
                        className="gdlr-core-pbf-column-content-margin gdlr-core-js"
                        style={{ padding: "30px 40px 5px 40px" }}
                      >
                        <div
                          className="gdlr-core-pbf-background-wrap"
                          style={{
                            borderWidth: "0px 0px 0px 0px",
                            borderColor: "#eaeaea",
                            borderStyle: "solid",
                          }}
                        />
                        <div className="gdlr-core-pbf-column-content clearfix gdlr-core-js">
                          <div className="gdlr-core-pbf-element">
                            <div
                              className="gdlr-core-column-service-item gdlr-core-item-pdb gdlr-core-center-align gdlr-core-no-caption gdlr-core-item-pdlr"
                              style={{ paddingBottom: "30px" }}
                            >
                              <div
                                className="gdlr-core-column-service-media gdlr-core-media-image"
                                style={{
                                  maxWidth: "70.5px",
                                  marginLeft: "auto",
                                  marginRight: "auto",
                                }}
                              >
                                <img
                                  src="upload/fab.png"
                                  alt=""
                                  width={141}
                                  height={140}
                                  title="about-2-icon-4"
                                />
                              </div>
                              <div className="gdlr-core-column-service-content-wrapper">
                                <div className="gdlr-core-column-service-title-wrap">
                                  <h3
                                    className="gdlr-core-column-service-title gdlr-core-skin-title"
                                    style={{
                                      fontSize: "24px",
                                      fontWeight: 500,
                                      textTransform: "none",
                                    }}
                                  >
                                    First Aid facilities
                                  </h3>
                                </div>
                                {/* <div
                                  className="gdlr-core-column-service-content"
                                  style={{
                                    fontSize: "16px",
                                    textTransform: "none",
                                  }}
                                >
                                  <p>
                                    Choose from a wide range of exclusive rooms,
                                    hotels, and apartments.
                                  </p>
                                </div> */}
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div
                      className="gdlr-core-pbf-column gdlr-core-column-20"
                      id="gdlr-core-column-6"
                    >
                      <div
                        className="gdlr-core-pbf-column-content-margin gdlr-core-js"
                        style={{ padding: "30px 40px 5px 40px" }}
                      >
                        <div
                          className="gdlr-core-pbf-background-wrap"
                          style={{
                            borderWidth: "0px 1px 0px 1px",
                            borderColor: "#eaeaea",
                            borderStyle: "solid",
                          }}
                        />
                        <div className="gdlr-core-pbf-column-content clearfix gdlr-core-js">
                          <div className="gdlr-core-pbf-element">
                            <div
                              className="gdlr-core-column-service-item gdlr-core-item-pdb gdlr-core-center-align gdlr-core-no-caption gdlr-core-item-pdlr"
                              style={{ paddingBottom: "30px" }}
                            >
                              <div
                                className="gdlr-core-column-service-media gdlr-core-media-image"
                                style={{
                                  maxWidth: "70px",
                                  marginLeft: "auto",
                                  marginRight: "auto",
                                }}
                              >
                                <img
                                  src="upload/wardrobe.png"
                                  alt=""
                                  width={140}
                                  height={140}
                                  title="about-2-icon-5"
                                />
                              </div>
                              <div className="gdlr-core-column-service-content-wrapper">
                                <div className="gdlr-core-column-service-title-wrap">
                                  <h3
                                    className="gdlr-core-column-service-title gdlr-core-skin-title"
                                    style={{
                                      fontSize: "24px",
                                      fontWeight: 500,
                                      textTransform: "none",
                                    }}
                                  >
                                    Individual Wardrobes
                                  </h3>
                                </div>
                                {/* <div
                                  className="gdlr-core-column-service-content"
                                  style={{
                                    fontSize: "16px",
                                    textTransform: "none",
                                  }}
                                >
                                  <p>
                                    Choose from a wide range of exclusive rooms,
                                    hotels, and apartments.
                                  </p>
                                </div> */}
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div
                      className="gdlr-core-pbf-column gdlr-core-column-20"
                      id="gdlr-core-column-7"
                    >
                      <div
                        className="gdlr-core-pbf-column-content-margin gdlr-core-js"
                        style={{ padding: "30px 40px 5px 40px" }}
                      >
                        <div
                          className="gdlr-core-pbf-background-wrap"
                          style={{
                            borderWidth: "0px 0px 0px 0px",
                            borderColor: "#eaeaea",
                            borderStyle: "solid",
                          }}
                        />
                        <div className="gdlr-core-pbf-column-content clearfix gdlr-core-js">
                          <div className="gdlr-core-pbf-element">
                            <div
                              className="gdlr-core-column-service-item gdlr-core-item-pdb gdlr-core-center-align gdlr-core-no-caption gdlr-core-item-pdlr"
                              style={{ paddingBottom: "30px" }}
                            >
                              <div
                                className="gdlr-core-column-service-media gdlr-core-media-image"
                                style={{
                                  maxWidth: "74px",
                                  marginLeft: "auto",
                                  marginRight: "auto",
                                }}
                              >
                                <img
                                  src="upload/about-2-icon-6-1.png"
                                  alt=""
                                  width={148}
                                  height={148}
                                  title="about-2-icon-6"
                                />
                              </div>
                              <div className="gdlr-core-column-service-content-wrapper">
                                <div className="gdlr-core-column-service-title-wrap">
                                  <h3
                                    className="gdlr-core-column-service-title gdlr-core-skin-title"
                                    style={{
                                      fontSize: "24px",
                                      fontWeight: 500,
                                      textTransform: "none",
                                    }}
                                  >
                                    Unlimited Wifi
                                  </h3>
                                </div>
                                {/* <div
                                  className="gdlr-core-column-service-content"
                                  style={{
                                    fontSize: "16px",
                                    textTransform: "none",
                                  }}
                                >
                                  <p>
                                    Choose from a wide range of exclusive rooms,
                                    hotels, and apartments.
                                  </p>
                                </div> */}
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div
                      className="gdlr-core-pbf-column gdlr-core-column-20"
                      id="gdlr-core-column-7"
                    >
                      <div
                        className="gdlr-core-pbf-column-content-margin gdlr-core-js"
                        style={{ padding: "30px 40px 5px 40px" }}
                      >
                        <div
                          className="gdlr-core-pbf-background-wrap"
                          style={{
                            borderWidth: "0px 0px 0px 0px",
                            borderColor: "#eaeaea",
                            borderStyle: "solid",
                          }}
                        />
                        <div className="gdlr-core-pbf-column-content clearfix gdlr-core-js">
                          <div className="gdlr-core-pbf-element">
                            <div
                              className="gdlr-core-column-service-item gdlr-core-item-pdb gdlr-core-center-align gdlr-core-no-caption gdlr-core-item-pdlr"
                              style={{ paddingBottom: "30px" }}
                            >
                              <div
                                className="gdlr-core-column-service-media gdlr-core-media-image"
                                style={{
                                  maxWidth: "74px",
                                  marginLeft: "auto",
                                  marginRight: "auto",
                                }}
                              >
                                <img
                                  src="upload/water.png"
                                  alt=""
                                  width={148}
                                  height={148}
                                  title="about-2-icon-6"
                                />
                              </div>
                              <div className="gdlr-core-column-service-content-wrapper">
                                <div className="gdlr-core-column-service-title-wrap">
                                  <h3
                                    className="gdlr-core-column-service-title gdlr-core-skin-title"
                                    style={{
                                      fontSize: "24px",
                                      fontWeight: 500,
                                      textTransform: "none",
                                    }}
                                  >
                                    RO Water in every floor
                                  </h3>
                                </div>
                                {/* <div
                                  className="gdlr-core-column-service-content"
                                  style={{
                                    fontSize: "16px",
                                    textTransform: "none",
                                  }}
                                >
                                  <p>
                                    Choose from a wide range of exclusive rooms,
                                    hotels, and apartments.
                                  </p>
                                </div> */}
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div
                      className="gdlr-core-pbf-column gdlr-core-column-20"
                      id="gdlr-core-column-7"
                    >
                      <div
                        className="gdlr-core-pbf-column-content-margin gdlr-core-js"
                        style={{ padding: "30px 40px 5px 40px" }}
                      >
                        <div
                          className="gdlr-core-pbf-background-wrap"
                          style={{
                            borderWidth: "0px 0px 0px 0px",
                            borderColor: "#eaeaea",
                            borderStyle: "solid",
                          }}
                        />
                        <div className="gdlr-core-pbf-column-content clearfix gdlr-core-js">
                          <div className="gdlr-core-pbf-element">
                            <div
                              className="gdlr-core-column-service-item gdlr-core-item-pdb gdlr-core-center-align gdlr-core-no-caption gdlr-core-item-pdlr"
                              style={{ paddingBottom: "30px" }}
                            >
                              <div
                                className="gdlr-core-column-service-media gdlr-core-media-image"
                                style={{
                                  maxWidth: "74px",
                                  marginLeft: "auto",
                                  marginRight: "auto",
                                }}
                              >
                                <img
                                  src="upload/washing-machine.png"
                                  alt=""
                                  width={148}
                                  height={148}
                                  title="about-2-icon-6"
                                />
                              </div>
                              <div className="gdlr-core-column-service-content-wrapper">
                                <div className="gdlr-core-column-service-title-wrap">
                                  <h3
                                    className="gdlr-core-column-service-title gdlr-core-skin-title"
                                    style={{
                                      fontSize: "24px",
                                      fontWeight: 500,
                                      textTransform: "none",
                                    }}
                                  >
                                    Washing Machines
                                  </h3>
                                </div>
                                {/* <div
                                  className="gdlr-core-column-service-content"
                                  style={{
                                    fontSize: "16px",
                                    textTransform: "none",
                                  }}
                                >
                                  <p>
                                    Choose from a wide range of exclusive rooms,
                                    hotels, and apartments.
                                  </p>
                                </div> */}
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div
                      className="gdlr-core-pbf-column gdlr-core-column-20"
                      id="gdlr-core-column-7"
                    >
                      <div
                        className="gdlr-core-pbf-column-content-margin gdlr-core-js"
                        style={{ padding: "30px 40px 5px 40px" }}
                      >
                        <div
                          className="gdlr-core-pbf-background-wrap"
                          style={{
                            borderWidth: "0px 0px 0px 0px",
                            borderColor: "#eaeaea",
                            borderStyle: "solid",
                          }}
                        />
                        <div className="gdlr-core-pbf-column-content clearfix gdlr-core-js">
                          <div className="gdlr-core-pbf-element">
                            <div
                              className="gdlr-core-column-service-item gdlr-core-item-pdb gdlr-core-center-align gdlr-core-no-caption gdlr-core-item-pdlr"
                              style={{ paddingBottom: "30px" }}
                            >
                              <div
                                className="gdlr-core-column-service-media gdlr-core-media-image"
                                style={{
                                  maxWidth: "74px",
                                  marginLeft: "auto",
                                  marginRight: "auto",
                                }}
                              >
                                <img
                                  src="upload/cctv.png"
                                  alt=""
                                  width={148}
                                  height={148}
                                  title="about-2-icon-6"
                                />
                              </div>
                              <div className="gdlr-core-column-service-content-wrapper">
                                <div className="gdlr-core-column-service-title-wrap">
                                  <h3
                                    className="gdlr-core-column-service-title gdlr-core-skin-title"
                                    style={{
                                      fontSize: "24px",
                                      fontWeight: 500,
                                      textTransform: "none",
                                    }}
                                  >
                                    CCTV Surveillance
                                  </h3>
                                </div>
                                {/* <div
                                  className="gdlr-core-column-service-content"
                                  style={{
                                    fontSize: "16px",
                                    textTransform: "none",
                                  }}
                                >
                                  <p>
                                    Choose from a wide range of exclusive rooms,
                                    hotels, and apartments.
                                  </p>
                                </div> */}
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              {/* <div
                className="gdlr-core-pbf-wrapper"
                style={{ margin: "0px 0px 0px 0px" }}
                id="gdlr-core-wrapper-4"
              >
                <div
                  className="gdlr-core-pbf-background-wrap"
                  style={{
                    borderRadius: "20px 20px 20px 20px",
                    MozBorderRadius: "20px 20px 20px 20px",
                    WebkitBorderRadius: "20px 20px 20px 20px",
                    backgroundColor: "#f8f8f8",
                  }}
                />
                <div className="gdlr-core-pbf-wrapper-content gdlr-core-js">
                  <div className="gdlr-core-pbf-wrapper-container clearfix gdlr-core-container">
                    <div
                      className="gdlr-core-pbf-column gdlr-core-column-60 gdlr-core-column-first"
                      id="gdlr-core-column-10"
                    >
                      <div
                        className="gdlr-core-pbf-column-content-margin gdlr-core-js"
                        style={{
                          margin: "-120px 0px 0px 0px",
                          padding: "0px 0px 40px 0px",
                        }}
                      >
                        <div className="gdlr-core-pbf-background-wrap" />
                        <div className="gdlr-core-pbf-column-content clearfix gdlr-core-js">
                          <div className="gdlr-core-pbf-element">
                            <div
                              className="gdlr-core-title-item gdlr-core-item-pdb clearfix gdlr-core-center-align gdlr-core-title-item-caption-bottom gdlr-core-item-pdlr"
                              style={{ paddingBottom: "45px" }}
                              id="gdlr-core-title-item-2"
                            >
                              <div className="gdlr-core-title-item-title-wrap">
                                <h3
                                  className="gdlr-core-title-item-title gdlr-core-skin-title hotale-additional-font class-test"
                                  style={{
                                    fontSize: "80px",
                                    fontWeight: 400,
                                    textTransform: "none",
                                    color: "#000000",
                                  }}
                                >
                                  Newsletter
                                  <span className="gdlr-core-title-item-title-divider gdlr-core-skin-divider" />
                                </h3>
                              </div>
                              <span
                                className="gdlr-core-title-item-caption gdlr-core-info-font gdlr-core-skin-caption"
                                style={{
                                  fontSize: "18px",
                                  fontStyle: "normal",
                                  letterSpacing: "0px",
                                  color: "#898989",
                                  marginTop: "15px",
                                }}
                              >
                                Subscribe the newsletter to get updated to news
                                and promotions
                              </span>
                            </div>
                          </div>
                          <div className="gdlr-core-pbf-element">
                            <div className="gdlr-core-newsletter-item gdlr-core-item-pdlr gdlr-core-item-pdb gdlr-core-style-curve3">
                              <div className="newsletter newsletter-subscription">
                                <form
                                  className="gdlr-core-newsletter-form clearfix"
                                  method="post"
                                  action="#"
                                  onsubmit="return newsletter_check(this)"
                                >
                                  <div className="gdlr-core-newsletter-email">
                                    <input
                                      className="newsletter-email gdlr-core-skin-e-background gdlr-core-skin-e-content"
                                      placeholder="Your Email Address"
                                      type="email"
                                      name="ne"
                                      size={30}
                                      required
                                    />
                                  </div>
                                  <div className="gdlr-core-newsletter-submit">
                                    <input
                                      className="newsletter-submit"
                                      type="submit"
                                      defaultValue="Subscribe"
                                    />
                                  </div>
                                </form>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div> */}

              {/* <div
                className="gdlr-core-pbf-wrapper"
                style={{ padding: "90px 0px 55px 0px" }}
                id="gdlr-core-wrapper-5"
              >
                <div className="gdlr-core-pbf-background-wrap" />
                <div
                  className="gdlr-core-pbf-background-wrap"
                  style={{ top: "30px" }}
                >
                  <div
                    className="gdlr-core-pbf-background gdlr-core-parallax gdlr-core-js"
                    style={{
                      backgroundImage: "url(upload/bg-testimonail.jpg)",
                      backgroundRepeat: "no-repeat",
                      backgroundPosition: "top center",
                    }}
                    data-parallax-speed={0}
                  />
                </div>
                <div className="gdlr-core-pbf-wrapper-content gdlr-core-js">
                  <div className="gdlr-core-pbf-wrapper-container clearfix gdlr-core-container">
                    <div
                      className="gdlr-core-pbf-column gdlr-core-column-60 gdlr-core-column-first"
                      id="gdlr-core-column-11"
                    >
                      <div
                        className="gdlr-core-pbf-column-content-margin gdlr-core-js"
                        style={{ padding: "0px 0px 40px 0px" }}
                      >
                        <div className="gdlr-core-pbf-background-wrap" />
                        <div
                          className="gdlr-core-pbf-column-content clearfix gdlr-core-js"
                          style={{ maxWidth: "700px" }}
                        >
                          <div className="gdlr-core-pbf-element">
                            <div
                              className="gdlr-core-title-item gdlr-core-item-pdb clearfix gdlr-core-center-align gdlr-core-title-item-caption-top gdlr-core-item-pdlr"
                              style={{ paddingBottom: "15px" }}
                              id="gdlr-core-title-item-3"
                            >
                              <div className="gdlr-core-title-item-title-wrap">
                                <h3
                                  className="gdlr-core-title-item-title gdlr-core-skin-title hotale-additional-font class-test"
                                  style={{
                                    fontSize: "80px",
                                    fontWeight: 400,
                                    textTransform: "none",
                                    color: "#000000",
                                  }}
                                >
                                  Testimonial
                                  <span className="gdlr-core-title-item-title-divider gdlr-core-skin-divider" />
                                </h3>
                              </div>
                            </div>
                          </div>
                          <div className="gdlr-core-pbf-element">
                            <div className="gdlr-core-text-box-item gdlr-core-item-pdlr gdlr-core-item-pdb gdlr-core-center-align">
                              <div
                                className="gdlr-core-text-box-item-content"
                                style={{
                                  fontSize: "21px",
                                  fontWeight: 400,
                                  textTransform: "none",
                                  color: "#898989",
                                }}
                              >
                                <p>
                                  A wonderful serenity has taken possession of
                                  my entire soul, like these sweet mornings of
                                  spring which I enjoy with my whole heart.
                                </p>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div
                      className="gdlr-core-pbf-column gdlr-core-column-60 gdlr-core-column-first"
                      id="gdlr-core-column-12"
                    >
                      <div
                        className="gdlr-core-pbf-column-content-margin gdlr-core-js"
                        style={{ padding: "10px 0px 0px 0px" }}
                      >
                        <div className="gdlr-core-pbf-background-wrap" />
                        <div className="gdlr-core-pbf-column-content clearfix gdlr-core-js">
                          <div className="gdlr-core-pbf-element">
                            <div className="gdlr-core-testimonial-item gdlr-core-item-pdb clearfix gdlr-core-testimonial-style-left-2 gdlr-core-item-pdlr">
                              <div
                                className="gdlr-core-flexslider flexslider gdlr-core-js-2 gdlr-core-bullet-style-round gdlr-core-color-bullet"
                                data-type="carousel"
                                data-column={2}
                                data-move={1}
                                data-nav="bullet"
                                data-controls-top-margin="70px"
                              >
                                <ul className="slides">
                                  <li className="gdlr-core-item-mglr">
                                    <div className="gdlr-core-testimonial clearfix gdlr-core-with-frame">
                                      <div
                                        className="gdlr-core-testimonial-frame clearfix gdlr-core-skin-e-background gdlr-core-outer-frame-element"
                                        style={{
                                          boxShadow:
                                            "0px 15px 35px rgba(0, 0, 0, 0.08)",
                                          MozBoxShadow:
                                            "0px 15px 35px rgba(0, 0, 0, 0.08)",
                                          WebkitBoxShadow:
                                            "0px 15px 35px rgba(0, 0, 0, 0.08)",
                                          borderRadius: "50px 0px 50px 0px",
                                          MozBorderRadius: "50px 0px 50px 0px",
                                          WebkitBorderRadius:
                                            "50px 0px 50px 0px",
                                        }}
                                      >
                                        <div
                                          className="gdlr-core-testimonial-frame-border"
                                          style={{
                                            borderRadius: "50px 0px 50px 0px",
                                            MozBorderRadius:
                                              "50px 0px 50px 0px",
                                            WebkitBorderRadius:
                                              "50px 0px 50px 0px",
                                          }}
                                        />
                                        <div className="gdlr-core-testimonial-author-image gdlr-core-media-image">
                                          <img
                                            src="upload/customer1-150x150.jpg"
                                            alt=""
                                            width={150}
                                            height={150}
                                            title="customer1"
                                          />
                                          <div className="gdlr-core-testimonial-quote gdlr-core-quote-font gdlr-core-skin-icon">
                                            “
                                          </div>
                                        </div>
                                        <div className="gdlr-core-testimonial-content-wrap">
                                          <div
                                            className="gdlr-core-testimonial-content gdlr-core-info-font gdlr-core-skin-content"
                                            style={{
                                              fontSize: "18px",
                                              color: "#656565",
                                              paddingBottom: "25px",
                                            }}
                                          >
                                            <p>
                                              A wonderful serenity has taken
                                              possession of my entire soul, like
                                              these sweet mornings of spring
                                              which I enjoy with my whole heart.
                                              I am alone, and feel the charm of
                                              existence.
                                            </p>
                                          </div>
                                          <div className="gdlr-core-testimonial-author-wrap clearfix">
                                            <div className="gdlr-core-testimonial-author-content">
                                              <div
                                                className="gdlr-core-testimonial-title gdlr-core-title-font gdlr-core-skin-title"
                                                style={{
                                                  color: "#313131",
                                                  fontSize: "19px",
                                                  fontWeight: 600,
                                                  fontStyle: "normal",
                                                  letterSpacing: "0px",
                                                  textTransform: "none",
                                                }}
                                              >
                                                Joan Smith
                                              </div>
                                              <div
                                                className="gdlr-core-testimonial-position gdlr-core-info-font gdlr-core-skin-caption"
                                                style={{
                                                  color: "#313131",
                                                  fontSize: "14px",
                                                  fontStyle: "normal",
                                                  fontWeight: 500,
                                                }}
                                              >
                                                Solo Traveler
                                              </div>
                                            </div>
                                          </div>
                                        </div>
                                      </div>
                                    </div>
                                  </li>
                                  <li className="gdlr-core-item-mglr">
                                    <div className="gdlr-core-testimonial clearfix gdlr-core-with-frame">
                                      <div
                                        className="gdlr-core-testimonial-frame clearfix gdlr-core-skin-e-background gdlr-core-outer-frame-element"
                                        style={{
                                          boxShadow:
                                            "0px 15px 35px rgba(0, 0, 0, 0.08)",
                                          MozBoxShadow:
                                            "0px 15px 35px rgba(0, 0, 0, 0.08)",
                                          WebkitBoxShadow:
                                            "0px 15px 35px rgba(0, 0, 0, 0.08)",
                                          borderRadius: "50px 0px 50px 0px",
                                          MozBorderRadius: "50px 0px 50px 0px",
                                          WebkitBorderRadius:
                                            "50px 0px 50px 0px",
                                        }}
                                      >
                                        <div
                                          className="gdlr-core-testimonial-frame-border"
                                          style={{
                                            borderRadius: "50px 0px 50px 0px",
                                            MozBorderRadius:
                                              "50px 0px 50px 0px",
                                            WebkitBorderRadius:
                                              "50px 0px 50px 0px",
                                          }}
                                        />
                                        <div className="gdlr-core-testimonial-author-image gdlr-core-media-image">
                                          <img
                                            src="upload/customer03-150x150.jpg"
                                            alt=""
                                            width={150}
                                            height={150}
                                            title="customer03"
                                          />
                                          <div className="gdlr-core-testimonial-quote gdlr-core-quote-font gdlr-core-skin-icon">
                                            “
                                          </div>
                                        </div>
                                        <div className="gdlr-core-testimonial-content-wrap">
                                          <div
                                            className="gdlr-core-testimonial-content gdlr-core-info-font gdlr-core-skin-content"
                                            style={{
                                              fontSize: "18px",
                                              color: "#656565",
                                              paddingBottom: "25px",
                                            }}
                                          >
                                            <p>
                                              A wonderful serenity has taken
                                              possession of my entire soul, like
                                              these sweet mornings of spring
                                              which I enjoy with my whole heart.
                                              I am alone, and feel the charm of
                                              existence.
                                            </p>
                                          </div>
                                          <div className="gdlr-core-testimonial-author-wrap clearfix">
                                            <div className="gdlr-core-testimonial-author-content">
                                              <div
                                                className="gdlr-core-testimonial-title gdlr-core-title-font gdlr-core-skin-title"
                                                style={{
                                                  color: "#313131",
                                                  fontSize: "19px",
                                                  fontWeight: 600,
                                                  fontStyle: "normal",
                                                  letterSpacing: "0px",
                                                  textTransform: "none",
                                                }}
                                              >
                                                William Jones
                                              </div>
                                              <div
                                                className="gdlr-core-testimonial-position gdlr-core-info-font gdlr-core-skin-caption"
                                                style={{
                                                  color: "#313131",
                                                  fontSize: "14px",
                                                  fontStyle: "normal",
                                                  fontWeight: 500,
                                                }}
                                              >
                                                Solo Traveler
                                              </div>
                                            </div>
                                          </div>
                                        </div>
                                      </div>
                                    </div>
                                  </li>
                                  <li className="gdlr-core-item-mglr">
                                    <div className="gdlr-core-testimonial clearfix gdlr-core-with-frame">
                                      <div
                                        className="gdlr-core-testimonial-frame clearfix gdlr-core-skin-e-background gdlr-core-outer-frame-element"
                                        style={{
                                          boxShadow:
                                            "0px 15px 35px rgba(0, 0, 0, 0.08)",
                                          MozBoxShadow:
                                            "0px 15px 35px rgba(0, 0, 0, 0.08)",
                                          WebkitBoxShadow:
                                            "0px 15px 35px rgba(0, 0, 0, 0.08)",
                                          borderRadius: "50px 0px 50px 0px",
                                          MozBorderRadius: "50px 0px 50px 0px",
                                          WebkitBorderRadius:
                                            "50px 0px 50px 0px",
                                        }}
                                      >
                                        <div
                                          className="gdlr-core-testimonial-frame-border"
                                          style={{
                                            borderRadius: "50px 0px 50px 0px",
                                            MozBorderRadius:
                                              "50px 0px 50px 0px",
                                            WebkitBorderRadius:
                                              "50px 0px 50px 0px",
                                          }}
                                        />
                                        <div className="gdlr-core-testimonial-author-image gdlr-core-media-image">
                                          <img
                                            src="upload/customer02-150x150.jpg"
                                            alt=""
                                            width={150}
                                            height={150}
                                            title="customer02"
                                          />
                                          <div className="gdlr-core-testimonial-quote gdlr-core-quote-font gdlr-core-skin-icon">
                                            “
                                          </div>
                                        </div>
                                        <div className="gdlr-core-testimonial-content-wrap">
                                          <div
                                            className="gdlr-core-testimonial-content gdlr-core-info-font gdlr-core-skin-content"
                                            style={{
                                              fontSize: "18px",
                                              color: "#656565",
                                              paddingBottom: "25px",
                                            }}
                                          >
                                            <p>
                                              A wonderful serenity has taken
                                              possession of my entire soul, like
                                              these sweet mornings of spring
                                              which I enjoy with my whole heart.
                                              I am alone, and feel the charm of
                                              existence.
                                            </p>
                                          </div>
                                          <div className="gdlr-core-testimonial-author-wrap clearfix">
                                            <div className="gdlr-core-testimonial-author-content">
                                              <div
                                                className="gdlr-core-testimonial-title gdlr-core-title-font gdlr-core-skin-title"
                                                style={{
                                                  color: "#313131",
                                                  fontSize: "19px",
                                                  fontWeight: 600,
                                                  fontStyle: "normal",
                                                  letterSpacing: "0px",
                                                  textTransform: "none",
                                                }}
                                              >
                                                Ralph Clark
                                              </div>
                                              <div
                                                className="gdlr-core-testimonial-position gdlr-core-info-font gdlr-core-skin-caption"
                                                style={{
                                                  color: "#313131",
                                                  fontSize: "14px",
                                                  fontStyle: "normal",
                                                  fontWeight: 500,
                                                }}
                                              >
                                                Solo Traveler
                                              </div>
                                            </div>
                                          </div>
                                        </div>
                                      </div>
                                    </div>
                                  </li>
                                  <li className="gdlr-core-item-mglr">
                                    <div className="gdlr-core-testimonial clearfix gdlr-core-with-frame">
                                      <div
                                        className="gdlr-core-testimonial-frame clearfix gdlr-core-skin-e-background gdlr-core-outer-frame-element"
                                        style={{
                                          boxShadow:
                                            "0px 15px 35px rgba(0, 0, 0, 0.08)",
                                          MozBoxShadow:
                                            "0px 15px 35px rgba(0, 0, 0, 0.08)",
                                          WebkitBoxShadow:
                                            "0px 15px 35px rgba(0, 0, 0, 0.08)",
                                          borderRadius: "50px 0px 50px 0px",
                                          MozBorderRadius: "50px 0px 50px 0px",
                                          WebkitBorderRadius:
                                            "50px 0px 50px 0px",
                                        }}
                                      >
                                        <div
                                          className="gdlr-core-testimonial-frame-border"
                                          style={{
                                            borderRadius: "50px 0px 50px 0px",
                                            MozBorderRadius:
                                              "50px 0px 50px 0px",
                                            WebkitBorderRadius:
                                              "50px 0px 50px 0px",
                                          }}
                                        />
                                        <div className="gdlr-core-testimonial-author-image gdlr-core-media-image">
                                          <img
                                            src="upload/customer1-150x150.jpg"
                                            alt=""
                                            width={150}
                                            height={150}
                                            title="customer1"
                                          />
                                          <div className="gdlr-core-testimonial-quote gdlr-core-quote-font gdlr-core-skin-icon">
                                            “
                                          </div>
                                        </div>
                                        <div className="gdlr-core-testimonial-content-wrap">
                                          <div
                                            className="gdlr-core-testimonial-content gdlr-core-info-font gdlr-core-skin-content"
                                            style={{
                                              fontSize: "18px",
                                              color: "#656565",
                                              paddingBottom: "25px",
                                            }}
                                          >
                                            <p>
                                              A wonderful serenity has taken
                                              possession of my entire soul, like
                                              these sweet mornings of spring
                                              which I enjoy with my whole heart.
                                              I am alone, and feel the charm of
                                              existence.
                                            </p>
                                          </div>
                                          <div className="gdlr-core-testimonial-author-wrap clearfix">
                                            <div className="gdlr-core-testimonial-author-content">
                                              <div
                                                className="gdlr-core-testimonial-title gdlr-core-title-font gdlr-core-skin-title"
                                                style={{
                                                  color: "#313131",
                                                  fontSize: "19px",
                                                  fontWeight: 600,
                                                  fontStyle: "normal",
                                                  letterSpacing: "0px",
                                                  textTransform: "none",
                                                }}
                                              >
                                                Christopher Lopez
                                              </div>
                                              <div
                                                className="gdlr-core-testimonial-position gdlr-core-info-font gdlr-core-skin-caption"
                                                style={{
                                                  color: "#313131",
                                                  fontSize: "14px",
                                                  fontStyle: "normal",
                                                  fontWeight: 500,
                                                }}
                                              >
                                                Solo Traveler
                                              </div>
                                            </div>
                                          </div>
                                        </div>
                                      </div>
                                    </div>
                                  </li>
                                  <li className="gdlr-core-item-mglr">
                                    <div className="gdlr-core-testimonial clearfix gdlr-core-with-frame">
                                      <div
                                        className="gdlr-core-testimonial-frame clearfix gdlr-core-skin-e-background gdlr-core-outer-frame-element"
                                        style={{
                                          boxShadow:
                                            "0px 15px 35px rgba(0, 0, 0, 0.08)",
                                          MozBoxShadow:
                                            "0px 15px 35px rgba(0, 0, 0, 0.08)",
                                          WebkitBoxShadow:
                                            "0px 15px 35px rgba(0, 0, 0, 0.08)",
                                          borderRadius: "50px 0px 50px 0px",
                                          MozBorderRadius: "50px 0px 50px 0px",
                                          WebkitBorderRadius:
                                            "50px 0px 50px 0px",
                                        }}
                                      >
                                        <div
                                          className="gdlr-core-testimonial-frame-border"
                                          style={{
                                            borderRadius: "50px 0px 50px 0px",
                                            MozBorderRadius:
                                              "50px 0px 50px 0px",
                                            WebkitBorderRadius:
                                              "50px 0px 50px 0px",
                                          }}
                                        />
                                        <div className="gdlr-core-testimonial-author-image gdlr-core-media-image">
                                          <img
                                            src="upload/customer03-150x150.jpg"
                                            alt=""
                                            width={150}
                                            height={150}
                                            title="customer03"
                                          />
                                          <div className="gdlr-core-testimonial-quote gdlr-core-quote-font gdlr-core-skin-icon">
                                            “
                                          </div>
                                        </div>
                                        <div className="gdlr-core-testimonial-content-wrap">
                                          <div
                                            className="gdlr-core-testimonial-content gdlr-core-info-font gdlr-core-skin-content"
                                            style={{
                                              fontSize: "18px",
                                              color: "#656565",
                                              paddingBottom: "25px",
                                            }}
                                          >
                                            <p>
                                              A wonderful serenity has taken
                                              possession of my entire soul, like
                                              these sweet mornings of spring
                                              which I enjoy with my whole heart.
                                              I am alone, and feel the charm of
                                              existence.
                                            </p>
                                          </div>
                                          <div className="gdlr-core-testimonial-author-wrap clearfix">
                                            <div className="gdlr-core-testimonial-author-content">
                                              <div
                                                className="gdlr-core-testimonial-title gdlr-core-title-font gdlr-core-skin-title"
                                                style={{
                                                  color: "#313131",
                                                  fontSize: "19px",
                                                  fontWeight: 600,
                                                  fontStyle: "normal",
                                                  letterSpacing: "0px",
                                                  textTransform: "none",
                                                }}
                                              >
                                                Louis Lewis
                                              </div>
                                              <div
                                                className="gdlr-core-testimonial-position gdlr-core-info-font gdlr-core-skin-caption"
                                                style={{
                                                  color: "#313131",
                                                  fontSize: "14px",
                                                  fontStyle: "normal",
                                                  fontWeight: 500,
                                                }}
                                              >
                                                Solo Traveler
                                              </div>
                                            </div>
                                          </div>
                                        </div>
                                      </div>
                                    </div>
                                  </li>
                                </ul>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div> */}
            </div>
          </div>
        </div>
      </div>

      <Footer />
    </div>
  );
}
