import React from "react";

export default function Section3() {
  return (
    <>
      {" "}
      <div
        className="gdlr-core-pbf-wrapper"
        style={{ padding: "90px 0px 30px 0px" }}
        id="gdlr-core-wrapper-2"
      >
        <div className="gdlr-core-pbf-background-wrap" />
        <div className="gdlr-core-pbf-background-wrap" style={{ top: "50px" }}>
          <div
            className="gdlr-core-pbf-background gdlr-core-parallax gdlr-core-js"
            style={{
              backgroundImage: "url(upload/apartment2-blog-bg.png)",
              backgroundRepeat: "no-repeat",
              backgroundPosition: "top center",
            }}
            data-parallax-speed={0}
          />
        </div>
        <div className="gdlr-core-pbf-wrapper-content gdlr-core-js">
          <div className="gdlr-core-pbf-wrapper-container clearfix gdlr-core-container">
            <div
              className="gdlr-core-pbf-column gdlr-core-column-60 gdlr-core-column-first"
              id="gdlr-core-column-6"
            >
              <div
                className="gdlr-core-pbf-column-content-margin gdlr-core-js"
                style={{ padding: "0px 0px 30px 0px" }}
              >
                <div className="gdlr-core-pbf-background-wrap" />
                <div className="gdlr-core-pbf-column-content clearfix gdlr-core-js">
                  <div className="gdlr-core-pbf-element">
                    <div className="gdlr-core-title-item gdlr-core-item-pdb clearfix gdlr-core-center-align gdlr-core-title-item-caption-top gdlr-core-item-pdlr">
                      <div className="gdlr-core-title-item-title-wrap">
                        <h3
                          className="gdlr-core-title-item-title gdlr-core-skin-title class-test"
                          style={{
                            fontSize: "45px",
                            fontWeight: 700,
                            letterSpacing: "0px",
                            lineHeight: 1,
                            textTransform: "none",
                          }}
                        >
                          Our Rooms.
                          <span className="gdlr-core-title-item-title-divider gdlr-core-skin-divider" />
                        </h3>
                      </div>
                    </div>
                  </div>
                  <div className="gdlr-core-pbf-element">
                    <div className="gdlr-core-divider-item gdlr-core-divider-item-normal gdlr-core-item-pdlr gdlr-core-center-align">
                      <div
                        className="gdlr-core-divider-container"
                        style={{ maxWidth: "40px" }}
                      >
                        <div
                          className="gdlr-core-divider-line gdlr-core-skin-divider"
                          style={{
                            borderColor: "#ff5023",
                            borderWidth: "3px",
                          }}
                        />
                      </div>
                    </div>
                  </div>
                  <div className="gdlr-core-pbf-element">
                    <div
                      className="gdlr-core-text-box-item gdlr-core-item-pdlr gdlr-core-item-pdb gdlr-core-center-align"
                      style={{ paddingBottom: "20px" }}
                    >
                      <div
                        className="gdlr-core-text-box-item-content"
                        style={{
                          fontSize: "19px",
                          textTransform: "none",
                          color: "#94959b",
                        }}
                      >
                        {/* <p>
                          Choose from a wide range of exclusive rooms, hotels,
                          and apartments.
                        </p> */}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="gdlr-core-pbf-column gdlr-core-column-60 gdlr-core-column-first">
              <div className="gdlr-core-pbf-column-content-margin gdlr-core-js">
                <div className="gdlr-core-pbf-column-content clearfix gdlr-core-js">
                  <div className="gdlr-core-pbf-element">
                    <div
                      className="tourmaster-room-item clearfix tourmaster-room-item-style-grid"
                      id="gdlr-core-room-1"
                    >
                      <div
                        className="gdlr-core-flexslider flexslider gdlr-core-js-2"
                        data-type="carousel"
                        data-column={3}
                        data-move={1}
                        data-nav="navigation-outer"
                        data-nav-parent="self"
                        data-vcenter-nav={1}
                        data-disable-autoslide={1}
                      >
                        <div
                          className="gdlr-core-flexslider-custom-nav gdlr-core-style-navigation-outer gdlr-core-center-align"
                          style={{ marginTop: "-5px" }}
                        >
                          <i
                            className="icon-arrow-left flex-prev"
                            style={{
                              color: "#9e9e9e",
                              fontSize: "34px",
                              left: "-80px",
                            }}
                          />
                          <i
                            className="icon-arrow-right flex-next"
                            style={{
                              color: "#9e9e9e",
                              fontSize: "34px",
                              right: "-80px",
                            }}
                          />
                        </div>
                        <ul className="slides">
                          <li className="gdlr-core-item-mglr">
                            <div className="tourmaster-room-grid tourmaster-grid-frame">
                              <div
                                className="tourmaster-room-grid-inner"
                                style={{
                                  boxShadow:
                                    "0 20px 35px rgba(10, 10, 10, 0.09)",
                                  MozBoxShadow:
                                    "0 20px 35px rgba(10, 10, 10, 0.09)",
                                  WebkitBoxShadow:
                                    "0 20px 35px rgba(10, 10, 10, 0.09)",
                                  borderRadius: "20px",
                                  MozBorderRadius: "20px",
                                  WebkitBorderRadius: "20px",
                                }}
                              >
                                <div className="tourmaster-room-thumbnail tourmaster-media-image gdlr-core-outer-frame-element tourmaster-with-price">
                                  <a href="/">
                                    <img
                                      src="images/5.jpg"
                                      alt=""
                                      width={780}
                                      height={595}
                                    />
                                  </a>
                                </div>
                                <div
                                  className="tourmaster-room-content-wrap gdlr-core-skin-e-background gdlr-core-js"
                                  data-sync-height="room-item-90"
                                  style={{ paddingBottom: "30px" }}
                                >
                                  <h3
                                    className="tourmaster-room-title gdlr-core-skin-title"
                                    style={{
                                      fontSize: "21px",
                                      fontWeight: 500,
                                      textTransform: "none",
                                    }}
                                  >
                                    <a href="">Twin sharing room</a>
                                  </h3>
                                  <div className="tourmaster-info-wrap clearfix">
                                    <div className="tourmaster-info tourmaster-info-bed-type">
                                      <i className="gdlr-icon-double-bed2" />
                                      <span className="tourmaster-tail">
                                        2 Beds
                                      </span>
                                    </div>
                                    <div className="tourmaster-info tourmaster-info-guest-amount">
                                      <i className="gdlr-icon-group" />
                                      <span className="tourmaster-tail">
                                        2 Guests
                                      </span>
                                    </div>
                                  </div>
                                  <div className="tourmaster-room-rating">
                                    <i className="fa fa-star" />
                                    <i className="fa fa-star" />
                                    <i className="fa fa-star" />
                                    <i className="fa fa-star" />
                                    <i className="fa fa-star" />
                                    {/* <span className="tourmaster-room-rating-text">
                                      1 Review
                                    </span> */}
                                  </div>
                                  <div className="tourmaster-location">
                                    <i className="icon-location-pin" />
                                    OMR
                                  </div>
                                  {/* <a
                                    className="tourmaster-read-more tourmaster-type-text"
                                    href="single-room.html"
                                    style={{ color: "#212121" }}
                                  >
                                    Book Now
                                    <i className="icon-arrow-right" />
                                  </a> */}
                                </div>
                              </div>
                            </div>
                          </li>
                          <li className="gdlr-core-item-mglr">
                            <div className="tourmaster-room-grid tourmaster-grid-frame">
                              <div
                                className="tourmaster-room-grid-inner"
                                style={{
                                  boxShadow:
                                    "0 20px 35px rgba(10, 10, 10, 0.09)",
                                  MozBoxShadow:
                                    "0 20px 35px rgba(10, 10, 10, 0.09)",
                                  WebkitBoxShadow:
                                    "0 20px 35px rgba(10, 10, 10, 0.09)",
                                  borderRadius: "20px",
                                  MozBorderRadius: "20px",
                                  WebkitBorderRadius: "20px",
                                }}
                              >
                                <div className="tourmaster-room-thumbnail tourmaster-media-image gdlr-core-outer-frame-element tourmaster-with-price">
                                  <a href="">
                                    <img
                                      src="images/4.jpg"
                                      alt=""
                                      width={780}
                                      height={595}
                                    />
                                  </a>
                                </div>
                                <div
                                  className="tourmaster-room-content-wrap gdlr-core-skin-e-background gdlr-core-js"
                                  data-sync-height="room-item-90"
                                  style={{ paddingBottom: "30px" }}
                                >
                                  <h3
                                    className="tourmaster-room-title gdlr-core-skin-title"
                                    style={{
                                      fontSize: "21px",
                                      fontWeight: 500,
                                      textTransform: "none",
                                    }}
                                  >
                                    <a href="/">Four sharing room</a>
                                  </h3>
                                  <div className="tourmaster-info-wrap clearfix">
                                    <div className="tourmaster-info tourmaster-info-bed-type">
                                      <i className="gdlr-icon-double-bed2" />
                                      <span className="tourmaster-tail">
                                        4 Bed
                                      </span>
                                    </div>
                                    <div className="tourmaster-info tourmaster-info-guest-amount">
                                      <i className="gdlr-icon-group" />
                                      <span className="tourmaster-tail">
                                        4 Guest
                                      </span>
                                    </div>
                                  </div>
                                  <div className="tourmaster-room-rating">
                                    <i className="fa fa-star" />
                                    <i className="fa fa-star" />
                                    <i className="fa fa-star" />
                                    <i className="fa fa-star" />
                                    <i className="fa fa-star-half-o" />
                                    <span className="tourmaster-room-rating-text">
                                      2 Reviews
                                    </span>
                                  </div>
                                  <div className="tourmaster-location">
                                    <i className="icon-location-pin" />
                                    OMR
                                  </div>
                                  {/* <a
                                    className="tourmaster-read-more tourmaster-type-text"
                                    href="single-room.html"
                                    style={{ color: "#212121" }}
                                  >
                                    Book Now
                                    <i className="icon-arrow-right" />
                                  </a> */}
                                </div>
                              </div>
                            </div>
                          </li>
                          <li className="gdlr-core-item-mglr">
                            <div className="tourmaster-room-grid tourmaster-grid-frame">
                              <div
                                className="tourmaster-room-grid-inner"
                                style={{
                                  boxShadow:
                                    "0 20px 35px rgba(10, 10, 10, 0.09)",
                                  MozBoxShadow:
                                    "0 20px 35px rgba(10, 10, 10, 0.09)",
                                  WebkitBoxShadow:
                                    "0 20px 35px rgba(10, 10, 10, 0.09)",
                                  borderRadius: "20px",
                                  MozBorderRadius: "20px",
                                  WebkitBorderRadius: "20px",
                                }}
                              >
                                <div className="tourmaster-room-thumbnail tourmaster-media-image gdlr-core-outer-frame-element tourmaster-with-price tourmaster-with-ribbon">
                                  <a href="/">
                                    <img
                                      src="images/5.jpg"
                                      alt=""
                                      width={780}
                                      height={595}
                                    />
                                  </a>
                                </div>
                                <div
                                  className="tourmaster-room-content-wrap gdlr-core-skin-e-background gdlr-core-js"
                                  data-sync-height="room-item-90"
                                  style={{ paddingBottom: "30px" }}
                                >
                                  <h3
                                    className="tourmaster-room-title gdlr-core-skin-title"
                                    style={{
                                      fontSize: "21px",
                                      fontWeight: 500,
                                      textTransform: "none",
                                    }}
                                  >
                                    <a href="/">Triple sharing room</a>
                                  </h3>
                                  <div className="tourmaster-info-wrap clearfix">
                                    <div className="tourmaster-info tourmaster-info-bed-type">
                                      <i className="gdlr-icon-double-bed2" />
                                      <span className="tourmaster-tail">
                                        3 Beds
                                      </span>
                                    </div>
                                    <div className="tourmaster-info tourmaster-info-guest-amount">
                                      <i className="gdlr-icon-group" />
                                      <span className="tourmaster-tail">
                                        3 Guests
                                      </span>
                                    </div>
                                  </div>
                                  <div className="tourmaster-room-rating">
                                    <i className="fa fa-star" />
                                    <i className="fa fa-star" />
                                    <i className="fa fa-star" />
                                    <i className="fa fa-star" />
                                    <i className="fa fa-star-o" />
                                    <span className="tourmaster-room-rating-text">
                                      1 Review
                                    </span>
                                  </div>
                                  <div className="tourmaster-location">
                                    <i className="icon-location-pin" />
                                    OMR
                                  </div>
                                  {/* <a
                                    className="tourmaster-read-more tourmaster-type-text"
                                    href="single-room.html"
                                    style={{ color: "#212121" }}
                                  >
                                    Book Now
                                    <i className="icon-arrow-right" />
                                  </a> */}
                                </div>
                              </div>
                            </div>
                          </li>

                          {/* <li className="gdlr-core-item-mglr">
                            <div className="tourmaster-room-grid tourmaster-grid-frame">
                              <div
                                className="tourmaster-room-grid-inner"
                                style={{
                                  boxShadow:
                                    "0 20px 35px rgba(10, 10, 10, 0.09)",
                                  MozBoxShadow:
                                    "0 20px 35px rgba(10, 10, 10, 0.09)",
                                  WebkitBoxShadow:
                                    "0 20px 35px rgba(10, 10, 10, 0.09)",
                                  borderRadius: "20px",
                                  MozBorderRadius: "20px",
                                  WebkitBorderRadius: "20px",
                                }}
                              >
                                <div className="tourmaster-room-thumbnail tourmaster-media-image gdlr-core-outer-frame-element tourmaster-with-price">
                                  <a href="single-room.html">
                                    <img
                                      src="upload/francesca-tosolini-tHkJAMcO3QE-unsplash-780x595.jpg"
                                      alt=""
                                      width={780}
                                      height={595}
                                    />
                                  </a>
                                  <div
                                    className="tourmaster-price-wrap tourmaster-with-bg tourmaster-with-text-color"
                                    style={{
                                      borderRadius: "10px",
                                      MozBorderRadius: "10px",
                                      WebkitBorderRadius: "10px",
                                      backgroundColor: "#ffffff",
                                      color: "#1e1e1e",
                                    }}
                                  >
                                    <span className="tourmaster-head">
                                      From
                                    </span>
                                    <span className="tourmaster-price">
                                      ₹170
                                    </span>
                                    <span className="tourmaster-tail">
                                      {" "}
                                      / month
                                    </span>
                                  </div>
                                </div>
                                <div
                                  className="tourmaster-room-content-wrap gdlr-core-skin-e-background gdlr-core-js"
                                  data-sync-height="room-item-90"
                                  style={{ paddingBottom: "30px" }}
                                >
                                  <h3
                                    className="tourmaster-room-title gdlr-core-skin-title"
                                    style={{
                                      fontSize: "21px",
                                      fontWeight: 500,
                                      textTransform: "none",
                                    }}
                                  >
                                    <a href="single-room.html">
                                      Modern Studio in Miami Beach
                                    </a>
                                  </h3>
                                  <div className="tourmaster-info-wrap clearfix">
                                    <div className="tourmaster-info tourmaster-info-bed-type">
                                      <i className="gdlr-icon-double-bed2" />
                                      <span className="tourmaster-tail">
                                        1 King Bed
                                      </span>
                                    </div>
                                    <div className="tourmaster-info tourmaster-info-guest-amount">
                                      <i className="gdlr-icon-group" />
                                      <span className="tourmaster-tail">
                                        3 Guests
                                      </span>
                                    </div>
                                  </div>
                                  <div className="tourmaster-room-rating">
                                    <i className="fa fa-star" />
                                    <i className="fa fa-star" />
                                    <i className="fa fa-star" />
                                    <i className="fa fa-star" />
                                    <i className="fa fa-star" />
                                    <span className="tourmaster-room-rating-text">
                                      1 Review
                                    </span>
                                  </div>
                                  <div className="tourmaster-location">
                                    <i className="icon-location-pin" />
                                    Miami Beach
                                  </div>
                                  <a
                                    className="tourmaster-read-more tourmaster-type-text"
                                    href="single-room.html"
                                    style={{ color: "#212121" }}
                                  >
                                    Book Now
                                    <i className="icon-arrow-right" />
                                  </a>
                                </div>
                              </div>
                            </div>
                          </li>
                          <li className="gdlr-core-item-mglr">
                            <div className="tourmaster-room-grid tourmaster-grid-frame">
                              <div
                                className="tourmaster-room-grid-inner"
                                style={{
                                  boxShadow:
                                    "0 20px 35px rgba(10, 10, 10, 0.09)",
                                  MozBoxShadow:
                                    "0 20px 35px rgba(10, 10, 10, 0.09)",
                                  WebkitBoxShadow:
                                    "0 20px 35px rgba(10, 10, 10, 0.09)",
                                  borderRadius: "20px",
                                  MozBorderRadius: "20px",
                                  WebkitBorderRadius: "20px",
                                }}
                              >
                                <div className="tourmaster-room-thumbnail tourmaster-media-image gdlr-core-outer-frame-element tourmaster-with-price tourmaster-with-ribbon">
                                  <a href="single-room.html">
                                    <img
                                      src="upload/sidekix-media-1vMz2_MclrM-unsplash-780x595.jpg"
                                      alt=""
                                      width={780}
                                      height={595}
                                    />
                                  </a>
                                  <div
                                    className="tourmaster-price-wrap tourmaster-with-bg tourmaster-with-text-color"
                                    style={{
                                      borderRadius: "10px",
                                      MozBorderRadius: "10px",
                                      WebkitBorderRadius: "10px",
                                      backgroundColor: "#ffffff",
                                      color: "#1e1e1e",
                                    }}
                                  >
                                    <span className="tourmaster-head">
                                      From
                                    </span>
                                    <span className="tourmaster-price-discount">
                                      ₹560
                                    </span>
                                    <span className="tourmaster-price">
                                      ₹476
                                    </span>
                                    <span className="tourmaster-tail">
                                      {" "}
                                      / month
                                    </span>
                                  </div>
                                  <div className="tourmaster-ribbon">
                                    15% Off
                                  </div>
                                </div>
                                <div
                                  className="tourmaster-room-content-wrap gdlr-core-skin-e-background gdlr-core-js"
                                  data-sync-height="room-item-90"
                                  style={{ paddingBottom: "30px" }}
                                >
                                  <h3
                                    className="tourmaster-room-title gdlr-core-skin-title"
                                    style={{
                                      fontSize: "21px",
                                      fontWeight: 500,
                                      textTransform: "none",
                                    }}
                                  >
                                    <a href="single-room.html">
                                      16th Street Apartment NY
                                    </a>
                                  </h3>
                                  <div className="tourmaster-info-wrap clearfix">
                                    <div className="tourmaster-info tourmaster-info-bed-type">
                                      <i className="gdlr-icon-double-bed2" />
                                      <span className="tourmaster-tail">
                                        3 Double Beds
                                      </span>
                                    </div>
                                    <div className="tourmaster-info tourmaster-info-guest-amount">
                                      <i className="gdlr-icon-group" />
                                      <span className="tourmaster-tail">
                                        9 Guests
                                      </span>
                                    </div>
                                  </div>
                                  <div className="tourmaster-room-rating">
                                    <i className="fa fa-star" />
                                    <i className="fa fa-star" />
                                    <i className="fa fa-star" />
                                    <i className="fa fa-star" />
                                    <i className="fa fa-star" />
                                    <span className="tourmaster-room-rating-text">
                                      1 Review
                                    </span>
                                  </div>
                                  <div className="tourmaster-location">
                                    <i className="icon-location-pin" />
                                    New York City
                                  </div>
                                  <a
                                    className="tourmaster-read-more tourmaster-type-text"
                                    href="single-room.html"
                                    style={{ color: "#212121" }}
                                  >
                                    Book Now
                                    <i className="icon-arrow-right" />
                                  </a>
                                </div>
                              </div>
                            </div>
                          </li>
                          <li className="gdlr-core-item-mglr">
                            <div className="tourmaster-room-grid tourmaster-grid-frame">
                              <div
                                className="tourmaster-room-grid-inner"
                                style={{
                                  boxShadow:
                                    "0 20px 35px rgba(10, 10, 10, 0.09)",
                                  MozBoxShadow:
                                    "0 20px 35px rgba(10, 10, 10, 0.09)",
                                  WebkitBoxShadow:
                                    "0 20px 35px rgba(10, 10, 10, 0.09)",
                                  borderRadius: "20px",
                                  MozBorderRadius: "20px",
                                  WebkitBorderRadius: "20px",
                                }}
                              >
                                <div className="tourmaster-room-thumbnail tourmaster-media-image gdlr-core-outer-frame-element tourmaster-with-price">
                                  <a href="single-room.html">
                                    <img
                                      src="upload/isaac-martin-wH2aFGo-Rt0-unsplash-780x595.jpg"
                                      alt=""
                                      width={780}
                                      height={595}
                                    />
                                  </a>
                                  <div
                                    className="tourmaster-price-wrap tourmaster-with-bg tourmaster-with-text-color"
                                    style={{
                                      borderRadius: "10px",
                                      MozBorderRadius: "10px",
                                      WebkitBorderRadius: "10px",
                                      backgroundColor: "#ffffff",
                                      color: "#1e1e1e",
                                    }}
                                  >
                                    <span className="tourmaster-head">
                                      From
                                    </span>
                                    <span className="tourmaster-price">
                                      ₹120
                                    </span>
                                    <span className="tourmaster-tail">
                                      {" "}
                                      / month
                                    </span>
                                  </div>
                                </div>
                                <div
                                  className="tourmaster-room-content-wrap gdlr-core-skin-e-background gdlr-core-js"
                                  data-sync-height="room-item-90"
                                  style={{ paddingBottom: "30px" }}
                                >
                                  <h3
                                    className="tourmaster-room-title gdlr-core-skin-title"
                                    style={{
                                      fontSize: "21px",
                                      fontWeight: 500,
                                      textTransform: "none",
                                    }}
                                  >
                                    <a href="single-room.html">
                                      Kings Town Apartment
                                    </a>
                                  </h3>
                                  <div className="tourmaster-info-wrap clearfix">
                                    <div className="tourmaster-info tourmaster-info-bed-type">
                                      <i className="gdlr-icon-double-bed2" />
                                      <span className="tourmaster-tail">
                                        1 King Bed
                                      </span>
                                    </div>
                                    <div className="tourmaster-info tourmaster-info-guest-amount">
                                      <i className="gdlr-icon-group" />
                                      <span className="tourmaster-tail">
                                        4 Guests
                                      </span>
                                    </div>
                                  </div>
                                  <div className="tourmaster-room-rating">
                                    <i className="fa fa-star" />
                                    <i className="fa fa-star" />
                                    <i className="fa fa-star" />
                                    <i className="fa fa-star" />
                                    <i className="fa fa-star" />
                                    <span className="tourmaster-room-rating-text">
                                      1 Review
                                    </span>
                                  </div>
                                  <div className="tourmaster-location">
                                    <i className="icon-location-pin" />
                                    New York City
                                  </div>
                                  <a
                                    className="tourmaster-read-more tourmaster-type-text"
                                    href="single-room.html"
                                    style={{ color: "#212121" }}
                                  >
                                    Book Now
                                    <i className="icon-arrow-right" />
                                  </a>
                                </div>
                              </div>
                            </div>
                          </li>
                          <li className="gdlr-core-item-mglr">
                            <div className="tourmaster-room-grid tourmaster-grid-frame">
                              <div
                                className="tourmaster-room-grid-inner"
                                style={{
                                  boxShadow:
                                    "0 20px 35px rgba(10, 10, 10, 0.09)",
                                  MozBoxShadow:
                                    "0 20px 35px rgba(10, 10, 10, 0.09)",
                                  WebkitBoxShadow:
                                    "0 20px 35px rgba(10, 10, 10, 0.09)",
                                  borderRadius: "20px",
                                  MozBorderRadius: "20px",
                                  WebkitBorderRadius: "20px",
                                }}
                              >
                                <div className="tourmaster-room-thumbnail tourmaster-media-image gdlr-core-outer-frame-element tourmaster-with-price">
                                  <a href="single-room.html">
                                    <img
                                      src="upload/collov-home-design-LSpkE5OCD_8-unsplash-780x595.jpg"
                                      alt=""
                                      width={780}
                                      height={595}
                                    />
                                  </a>
                                  <div
                                    className="tourmaster-price-wrap tourmaster-with-bg tourmaster-with-text-color"
                                    style={{
                                      borderRadius: "10px",
                                      MozBorderRadius: "10px",
                                      WebkitBorderRadius: "10px",
                                      backgroundColor: "#ffffff",
                                      color: "#1e1e1e",
                                    }}
                                  >
                                    <span className="tourmaster-head">
                                      From
                                    </span>
                                    <span className="tourmaster-price">
                                      ₹220
                                    </span>
                                    <span className="tourmaster-tail">
                                      {" "}
                                      / month
                                    </span>
                                  </div>
                                </div>
                                <div
                                  className="tourmaster-room-content-wrap gdlr-core-skin-e-background gdlr-core-js"
                                  data-sync-height="room-item-90"
                                  style={{ paddingBottom: "30px" }}
                                >
                                  <h3
                                    className="tourmaster-room-title gdlr-core-skin-title"
                                    style={{
                                      fontSize: "21px",
                                      fontWeight: 500,
                                      textTransform: "none",
                                    }}
                                  >
                                    <a href="single-room.html">
                                      Palm Beach Calabria
                                    </a>
                                  </h3>
                                  <div className="tourmaster-info-wrap clearfix">
                                    <div className="tourmaster-info tourmaster-info-bed-type">
                                      <i className="gdlr-icon-double-bed2" />
                                      <span className="tourmaster-tail">
                                        2 Double Beds
                                      </span>
                                    </div>
                                    <div className="tourmaster-info tourmaster-info-guest-amount">
                                      <i className="gdlr-icon-group" />
                                      <span className="tourmaster-tail">
                                        6 Guests
                                      </span>
                                    </div>
                                  </div>
                                  <div className="tourmaster-room-rating">
                                    <i className="fa fa-star" />
                                    <i className="fa fa-star" />
                                    <i className="fa fa-star" />
                                    <i className="fa fa-star" />
                                    <i className="fa fa-star-o" />
                                    <span className="tourmaster-room-rating-text">
                                      1 Review
                                    </span>
                                  </div>
                                  <div className="tourmaster-location">
                                    <i className="icon-location-pin" />
                                    Calabria
                                  </div>
                                  <a
                                    className="tourmaster-read-more tourmaster-type-text"
                                    href="single-room.html"
                                    style={{ color: "#212121" }}
                                  >
                                    Book Now
                                    <i className="icon-arrow-right" />
                                  </a>
                                </div>
                              </div>
                            </div>
                          </li>
                          <li className="gdlr-core-item-mglr">
                            <div className="tourmaster-room-grid tourmaster-grid-frame">
                              <div
                                className="tourmaster-room-grid-inner"
                                style={{
                                  boxShadow:
                                    "0 20px 35px rgba(10, 10, 10, 0.09)",
                                  MozBoxShadow:
                                    "0 20px 35px rgba(10, 10, 10, 0.09)",
                                  WebkitBoxShadow:
                                    "0 20px 35px rgba(10, 10, 10, 0.09)",
                                  borderRadius: "20px",
                                  MozBorderRadius: "20px",
                                  WebkitBorderRadius: "20px",
                                }}
                              >
                                <div className="tourmaster-room-thumbnail tourmaster-media-image gdlr-core-outer-frame-element tourmaster-with-price">
                                  <a href="single-room.html">
                                    <img
                                      src="upload/luke-van-zyl-koH7IVuwRLw-unsplash-780x595.jpg"
                                      alt=""
                                      width={780}
                                      height={595}
                                    />
                                  </a>
                                  <div
                                    className="tourmaster-price-wrap tourmaster-with-bg tourmaster-with-text-color"
                                    style={{
                                      borderRadius: "10px",
                                      MozBorderRadius: "10px",
                                      WebkitBorderRadius: "10px",
                                      backgroundColor: "#ffffff",
                                      color: "#1e1e1e",
                                    }}
                                  >
                                    <span className="tourmaster-head">
                                      From
                                    </span>
                                    <span className="tourmaster-price">
                                      ₹300
                                    </span>
                                    <span className="tourmaster-tail">
                                      {" "}
                                      / month
                                    </span>
                                  </div>
                                </div>
                                <div
                                  className="tourmaster-room-content-wrap gdlr-core-skin-e-background gdlr-core-js"
                                  data-sync-height="room-item-90"
                                  style={{ paddingBottom: "30px" }}
                                >
                                  <h3
                                    className="tourmaster-room-title gdlr-core-skin-title"
                                    style={{
                                      fontSize: "21px",
                                      fontWeight: 500,
                                      textTransform: "none",
                                    }}
                                  >
                                    <a href="single-room.html">
                                      BV Apartment Barcelona
                                    </a>
                                  </h3>
                                  <div className="tourmaster-info-wrap clearfix">
                                    <div className="tourmaster-info tourmaster-info-bed-type">
                                      <i className="gdlr-icon-double-bed2" />
                                      <span className="tourmaster-tail">
                                        2 Double Beds
                                      </span>
                                    </div>
                                    <div className="tourmaster-info tourmaster-info-guest-amount">
                                      <i className="gdlr-icon-group" />
                                      <span className="tourmaster-tail">
                                        6 Guests
                                      </span>
                                    </div>
                                  </div>
                                  <div className="tourmaster-room-rating">
                                    <i className="fa fa-star" />
                                    <i className="fa fa-star" />
                                    <i className="fa fa-star" />
                                    <i className="fa fa-star" />
                                    <i className="fa fa-star" />
                                    <span className="tourmaster-room-rating-text">
                                      1 Review
                                    </span>
                                  </div>
                                  <div className="tourmaster-location">
                                    <i className="icon-location-pin" />
                                    Barcelona
                                  </div>
                                  <a
                                    className="tourmaster-read-more tourmaster-type-text"
                                    href="single-room.html"
                                    style={{ color: "#212121" }}
                                  >
                                    Book Now
                                    <i className="icon-arrow-right" />
                                  </a>
                                </div>
                              </div>
                            </div>
                          </li>
                          <li className="gdlr-core-item-mglr">
                            <div className="tourmaster-room-grid tourmaster-grid-frame">
                              <div
                                className="tourmaster-room-grid-inner"
                                style={{
                                  boxShadow:
                                    "0 20px 35px rgba(10, 10, 10, 0.09)",
                                  MozBoxShadow:
                                    "0 20px 35px rgba(10, 10, 10, 0.09)",
                                  WebkitBoxShadow:
                                    "0 20px 35px rgba(10, 10, 10, 0.09)",
                                  borderRadius: "20px",
                                  MozBorderRadius: "20px",
                                  WebkitBorderRadius: "20px",
                                }}
                              >
                                <div className="tourmaster-room-thumbnail tourmaster-media-image gdlr-core-outer-frame-element tourmaster-with-price tourmaster-with-ribbon">
                                  <a href="single-room.html">
                                    <img
                                      src="upload/shop-slo-vhztm9QC0L0-unsplash-780x595.jpg"
                                      alt=""
                                      width={780}
                                      height={595}
                                    />
                                  </a>
                                  <div
                                    className="tourmaster-price-wrap tourmaster-with-bg tourmaster-with-text-color"
                                    style={{
                                      borderRadius: "10px",
                                      MozBorderRadius: "10px",
                                      WebkitBorderRadius: "10px",
                                      backgroundColor: "#ffffff",
                                      color: "#1e1e1e",
                                    }}
                                  >
                                    <span className="tourmaster-head">
                                      From
                                    </span>
                                    <span className="tourmaster-price-discount">
                                      ₹150
                                    </span>
                                    <span className="tourmaster-price">
                                      ₹105
                                    </span>
                                    <span className="tourmaster-tail">
                                      {" "}
                                      / month
                                    </span>
                                  </div>
                                  <div className="tourmaster-ribbon">
                                    30% Off
                                  </div>
                                </div>
                                <div
                                  className="tourmaster-room-content-wrap gdlr-core-skin-e-background gdlr-core-js"
                                  data-sync-height="room-item-90"
                                  style={{ paddingBottom: "30px" }}
                                >
                                  <h3
                                    className="tourmaster-room-title gdlr-core-skin-title"
                                    style={{
                                      fontSize: "21px",
                                      fontWeight: 500,
                                      textTransform: "none",
                                    }}
                                  >
                                    <a href="single-room.html">
                                      Florenzo Miami Beach
                                    </a>
                                  </h3>
                                  <div className="tourmaster-info-wrap clearfix">
                                    <div className="tourmaster-info tourmaster-info-bed-type">
                                      <i className="gdlr-icon-double-bed2" />
                                      <span className="tourmaster-tail">
                                        1 King Bed
                                      </span>
                                    </div>
                                    <div className="tourmaster-info tourmaster-info-guest-amount">
                                      <i className="gdlr-icon-group" />
                                      <span className="tourmaster-tail">
                                        3 Guests
                                      </span>
                                    </div>
                                  </div>
                                  <div className="tourmaster-room-rating">
                                    <i className="fa fa-star" />
                                    <i className="fa fa-star" />
                                    <i className="fa fa-star" />
                                    <i className="fa fa-star" />
                                    <i className="fa fa-star" />
                                    <span className="tourmaster-room-rating-text">
                                      1 Review
                                    </span>
                                  </div>
                                  <div className="tourmaster-location">
                                    <i className="icon-location-pin" />
                                    Maimi Beach
                                  </div>
                                  <a
                                    className="tourmaster-read-more tourmaster-type-text"
                                    href="single-room.html"
                                    style={{ color: "#212121" }}
                                  >
                                    Book Now
                                    <i className="icon-arrow-right" />
                                  </a>
                                </div>
                              </div>
                            </div>
                          </li>
                        */}
                        </ul>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            {/* <div className="gdlr-core-pbf-column gdlr-core-column-60 gdlr-core-column-first">
              <div className="gdlr-core-pbf-column-content-margin gdlr-core-js">
                <div className="gdlr-core-pbf-column-content clearfix gdlr-core-js">
                  <div className="gdlr-core-pbf-element">
                    <div className="gdlr-core-button-item gdlr-core-item-pdlr gdlr-core-item-pdb gdlr-core-center-align">
                      <a
                        className="gdlr-core-button gdlr-core-button-transparent gdlr-core-center-align gdlr-core-button-with-border"
                        href="#"
                        style={{
                          fontStyle: "normal",
                          fontWeight: 600,
                          color: "#94959b",
                          padding: "14px 33px 14px 33px",
                          borderRadius: "5px",
                          MozBorderRadius: "5px",
                          WebkitBorderRadius: "5px",
                          borderColor: "#94959b",
                        }}
                      >
                        <span className="gdlr-core-content">
                          View All Apartments
                        </span>
                      </a>
                    </div>
                  </div>
                </div>
              </div>
            </div> */}
          </div>
        </div>
      </div>
    </>
  );
}
