import React from "react";

export default function Section6() {
  return (
    <>
      <div
        className="gdlr-core-pbf-wrapper"
        style={{ padding: "80px 0px 60px 0px" }}
        id="gdlr-core-wrapper-5"
      >
        <div className="gdlr-core-pbf-background-wrap" />
        <div className="gdlr-core-pbf-background-wrap" style={{ top: "70px" }}>
          <div
            className="gdlr-core-pbf-background gdlr-core-parallax gdlr-core-js"
            style={{
              backgroundImage: "url(upload/bg-testimonail.jpg)",
              backgroundRepeat: "no-repeat",
              backgroundPosition: "top center",
            }}
            data-parallax-speed={0}
          />
        </div>
        <div className="gdlr-core-pbf-wrapper-content gdlr-core-js">
          <div className="gdlr-core-pbf-wrapper-container clearfix gdlr-core-container">
            <div
              className="gdlr-core-pbf-column gdlr-core-column-60 gdlr-core-column-first"
              id="gdlr-core-column-13"
            >
              <div
                className="gdlr-core-pbf-column-content-margin gdlr-core-js"
                style={{ padding: "20px 0px 0px 0px" }}
              >
                <div className="gdlr-core-pbf-background-wrap">
                  <div
                    className="gdlr-core-pbf-background gdlr-core-parallax gdlr-core-js"
                    style={{
                      backgroundImage:
                        "url(upload/apartment2-testimonial-bg.png)",
                      backgroundRepeat: "no-repeat",
                      backgroundPosition: "top center",
                    }}
                    data-parallax-speed={0}
                  />
                </div>
                <div
                  className="gdlr-core-pbf-column-content clearfix gdlr-core-js"
                  style={{ maxWidth: "660px" }}
                >
                  <div className="gdlr-core-pbf-element">
                    <div className="gdlr-core-title-item gdlr-core-item-pdb clearfix gdlr-core-center-align gdlr-core-title-item-caption-bottom gdlr-core-item-pdlr">
                      <div className="gdlr-core-title-item-title-wrap">
                        <h3
                          className="gdlr-core-title-item-title gdlr-core-skin-title class-test"
                          style={{
                            fontSize: "45px",
                            fontWeight: 700,
                            letterSpacing: "0px",
                            textTransform: "none",
                          }}
                        >
                          Testimonial
                          <span className="gdlr-core-title-item-title-divider gdlr-core-skin-divider" />
                        </h3>
                      </div>
                    </div>
                  </div>
                  <div className="gdlr-core-pbf-element">
                    <div className="gdlr-core-divider-item gdlr-core-divider-item-normal gdlr-core-item-pdlr gdlr-core-center-align">
                      <div
                        className="gdlr-core-divider-container"
                        style={{ maxWidth: "40px" }}
                      >
                        <div
                          className="gdlr-core-divider-line gdlr-core-skin-divider"
                          style={{
                            borderColor: "#ff5023",
                            borderWidth: "3px",
                          }}
                        />
                      </div>
                    </div>
                  </div>
                  <div className="gdlr-core-pbf-element">
                    <div
                      className="gdlr-core-text-box-item gdlr-core-item-pdlr gdlr-core-item-pdb gdlr-core-center-align"
                      style={{ paddingBottom: "20px" }}
                    >
                      <div
                        className="gdlr-core-text-box-item-content"
                        style={{
                          fontSize: "19px",
                          textTransform: "none",
                          color: "#94959b",
                        }}
                      >
                        {/* <p>
                          All our hotels are fabulous, they are destinations
                          unto themselves. We have crossed the globe to bring
                          you only the best.
                        </p> */}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div
              className="gdlr-core-pbf-column gdlr-core-column-60 gdlr-core-column-first"
              id="gdlr-core-column-14"
            >
              <div
                className="gdlr-core-pbf-column-content-margin gdlr-core-js"
                style={{ padding: "70px 0px 0px 0px" }}
              >
                <div className="gdlr-core-pbf-background-wrap" />
                <div className="gdlr-core-pbf-column-content clearfix gdlr-core-js">
                  <div className="gdlr-core-pbf-element">
                    <div className="gdlr-core-testimonial-item gdlr-core-item-pdb clearfix gdlr-core-testimonial-style-left-2 gdlr-core-item-pdlr">
                      <div
                        className="gdlr-core-flexslider flexslider gdlr-core-js-2 gdlr-core-bullet-style-round gdlr-core-color-bullet"
                        data-type="carousel"
                        data-column={2}
                        data-move={1}
                        data-nav="bullet"
                        data-controls-top-margin="70px"
                      >
                        <ul className="slides">
                          <li className="gdlr-core-item-mglr">
                            <div className="gdlr-core-testimonial clearfix gdlr-core-with-frame">
                              <div
                                className="gdlr-core-testimonial-frame clearfix gdlr-core-skin-e-background gdlr-core-outer-frame-element"
                                style={{
                                  boxShadow:
                                    "0px 15px 35px rgba(0, 0, 0, 0.08)",
                                  MozBoxShadow:
                                    "0px 15px 35px rgba(0, 0, 0, 0.08)",
                                  WebkitBoxShadow:
                                    "0px 15px 35px rgba(0, 0, 0, 0.08)",
                                  borderRadius: "20px 20px 20px 20px",
                                  MozBorderRadius: "20px 20px 20px 20px",
                                  WebkitBorderRadius: "20px 20px 20px 20px",
                                }}
                              >
                                <div
                                  className="gdlr-core-testimonial-frame-border"
                                  style={{
                                    borderRadius: "20px 20px 20px 20px",
                                    MozBorderRadius: "20px 20px 20px 20px",
                                    WebkitBorderRadius: "20px 20px 20px 20px",
                                  }}
                                />
                                <div className="gdlr-core-testimonial-author-image gdlr-core-media-image">
                                  <img
                                    src="upload/customer1-150x150.jpg"
                                    alt=""
                                    width={150}
                                    height={150}
                                    title="customer1"
                                  />
                                  <div className="gdlr-core-testimonial-quote gdlr-core-quote-font gdlr-core-skin-icon">
                                    “
                                  </div>
                                </div>
                                <div className="gdlr-core-testimonial-content-wrap">
                                  <div
                                    className="gdlr-core-testimonial-content gdlr-core-info-font gdlr-core-skin-content"
                                    style={{
                                      fontSize: "18px",
                                      color: "#656565",
                                      paddingBottom: "25px",
                                    }}
                                  >
                                    <p>
                                      A great stay with all kinds of amenities
                                      and home like food. It’s a best place for
                                      working women. Milk is served daily, non
                                      veg is served once in a week. Over all a
                                      good place to stay in.
                                    </p>
                                  </div>
                                  <div className="gdlr-core-testimonial-author-wrap clearfix">
                                    <div className="gdlr-core-testimonial-author-content">
                                      <div
                                        className="gdlr-core-testimonial-title gdlr-core-title-font gdlr-core-skin-title"
                                        style={{
                                          color: "#313131",
                                          fontSize: "19px",
                                          fontWeight: 600,
                                          fontStyle: "normal",
                                          letterSpacing: "0px",
                                          textTransform: "none",
                                        }}
                                      >
                                        Pooja A
                                      </div>
                                      {/* <div
                                        className="gdlr-core-testimonial-position gdlr-core-info-font gdlr-core-skin-caption"
                                        style={{
                                          color: "#313131",
                                          fontSize: "14px",
                                          fontStyle: "normal",
                                          fontWeight: 500,
                                        }}
                                      >
                                        Solo Traveler
                                      </div> */}
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </li>
                          <li className="gdlr-core-item-mglr">
                            <div className="gdlr-core-testimonial clearfix gdlr-core-with-frame">
                              <div
                                className="gdlr-core-testimonial-frame clearfix gdlr-core-skin-e-background gdlr-core-outer-frame-element"
                                style={{
                                  boxShadow:
                                    "0px 15px 35px rgba(0, 0, 0, 0.08)",
                                  MozBoxShadow:
                                    "0px 15px 35px rgba(0, 0, 0, 0.08)",
                                  WebkitBoxShadow:
                                    "0px 15px 35px rgba(0, 0, 0, 0.08)",
                                  borderRadius: "20px 20px 20px 20px",
                                  MozBorderRadius: "20px 20px 20px 20px",
                                  WebkitBorderRadius: "20px 20px 20px 20px",
                                }}
                              >
                                <div
                                  className="gdlr-core-testimonial-frame-border"
                                  style={{
                                    borderRadius: "20px 20px 20px 20px",
                                    MozBorderRadius: "20px 20px 20px 20px",
                                    WebkitBorderRadius: "20px 20px 20px 20px",
                                  }}
                                />
                                <div className="gdlr-core-testimonial-author-image gdlr-core-media-image">
                                  <img
                                    src="upload/customer03-150x150.jpg"
                                    alt=""
                                    width={150}
                                    height={150}
                                    title="customer03"
                                  />
                                  <div className="gdlr-core-testimonial-quote gdlr-core-quote-font gdlr-core-skin-icon">
                                    “
                                  </div>
                                </div>
                                <div className="gdlr-core-testimonial-content-wrap">
                                  <div
                                    className="gdlr-core-testimonial-content gdlr-core-info-font gdlr-core-skin-content"
                                    style={{
                                      fontSize: "18px",
                                      color: "#656565",
                                      paddingBottom: "25px",
                                    }}
                                  >
                                    <p>
                                      I work in an IT company on the OMR
                                      stretch, The hostel is located very near
                                      and in a walkable distance. And to mention
                                      the owner including the warden is so kind
                                      and works out our problems really fast,
                                      totally a very good stay and experience.
                                    </p>
                                  </div>
                                  <div className="gdlr-core-testimonial-author-wrap clearfix">
                                    <div className="gdlr-core-testimonial-author-content">
                                      <div
                                        className="gdlr-core-testimonial-title gdlr-core-title-font gdlr-core-skin-title"
                                        style={{
                                          color: "#313131",
                                          fontSize: "19px",
                                          fontWeight: 600,
                                          fontStyle: "normal",
                                          letterSpacing: "0px",
                                          textTransform: "none",
                                        }}
                                      >
                                        Arthi
                                      </div>
                                      {/* <div
                                        className="gdlr-core-testimonial-position gdlr-core-info-font gdlr-core-skin-caption"
                                        style={{
                                          color: "#313131",
                                          fontSize: "14px",
                                          fontStyle: "normal",
                                          fontWeight: 500,
                                        }}
                                      >
                                        Solo Traveler
                                      </div> */}
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </li>
                          <li className="gdlr-core-item-mglr">
                            <div className="gdlr-core-testimonial clearfix gdlr-core-with-frame">
                              <div
                                className="gdlr-core-testimonial-frame clearfix gdlr-core-skin-e-background gdlr-core-outer-frame-element"
                                style={{
                                  boxShadow:
                                    "0px 15px 35px rgba(0, 0, 0, 0.08)",
                                  MozBoxShadow:
                                    "0px 15px 35px rgba(0, 0, 0, 0.08)",
                                  WebkitBoxShadow:
                                    "0px 15px 35px rgba(0, 0, 0, 0.08)",
                                  borderRadius: "20px 20px 20px 20px",
                                  MozBorderRadius: "20px 20px 20px 20px",
                                  WebkitBorderRadius: "20px 20px 20px 20px",
                                }}
                              >
                                <div
                                  className="gdlr-core-testimonial-frame-border"
                                  style={{
                                    borderRadius: "20px 20px 20px 20px",
                                    MozBorderRadius: "20px 20px 20px 20px",
                                    WebkitBorderRadius: "20px 20px 20px 20px",
                                  }}
                                />
                                <div className="gdlr-core-testimonial-author-image gdlr-core-media-image">
                                  <img
                                    src="upload/customer02-150x150.jpg"
                                    alt=""
                                    width={150}
                                    height={150}
                                    title="customer02"
                                  />
                                  <div className="gdlr-core-testimonial-quote gdlr-core-quote-font gdlr-core-skin-icon">
                                    “
                                  </div>
                                </div>
                                <div className="gdlr-core-testimonial-content-wrap">
                                  <div
                                    className="gdlr-core-testimonial-content gdlr-core-info-font gdlr-core-skin-content"
                                    style={{
                                      fontSize: "18px",
                                      color: "#656565",
                                      paddingBottom: "25px",
                                    }}
                                  >
                                    <p>
                                      A very safe PG for women with Security
                                      come good food and also a peaceful
                                      environment. No words I really like it.
                                    </p>
                                  </div>
                                  <div className="gdlr-core-testimonial-author-wrap clearfix">
                                    <div className="gdlr-core-testimonial-author-content">
                                      <div
                                        className="gdlr-core-testimonial-title gdlr-core-title-font gdlr-core-skin-title"
                                        style={{
                                          color: "#313131",
                                          fontSize: "19px",
                                          fontWeight: 600,
                                          fontStyle: "normal",
                                          letterSpacing: "0px",
                                          textTransform: "none",
                                        }}
                                      >
                                        Janani C
                                      </div>
                                      {/* <div
                                        className="gdlr-core-testimonial-position gdlr-core-info-font gdlr-core-skin-caption"
                                        style={{
                                          color: "#313131",
                                          fontSize: "14px",
                                          fontStyle: "normal",
                                          fontWeight: 500,
                                        }}
                                      >
                                        Solo Traveler
                                      </div> */}
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </li>
                          <li className="gdlr-core-item-mglr">
                            <div className="gdlr-core-testimonial clearfix gdlr-core-with-frame">
                              <div
                                className="gdlr-core-testimonial-frame clearfix gdlr-core-skin-e-background gdlr-core-outer-frame-element"
                                style={{
                                  boxShadow:
                                    "0px 15px 35px rgba(0, 0, 0, 0.08)",
                                  MozBoxShadow:
                                    "0px 15px 35px rgba(0, 0, 0, 0.08)",
                                  WebkitBoxShadow:
                                    "0px 15px 35px rgba(0, 0, 0, 0.08)",
                                  borderRadius: "20px 20px 20px 20px",
                                  MozBorderRadius: "20px 20px 20px 20px",
                                  WebkitBorderRadius: "20px 20px 20px 20px",
                                }}
                              >
                                <div
                                  className="gdlr-core-testimonial-frame-border"
                                  style={{
                                    borderRadius: "20px 20px 20px 20px",
                                    MozBorderRadius: "20px 20px 20px 20px",
                                    WebkitBorderRadius: "20px 20px 20px 20px",
                                  }}
                                />
                                <div className="gdlr-core-testimonial-author-image gdlr-core-media-image">
                                  <img
                                    src="upload/customer1-150x150.jpg"
                                    alt=""
                                    width={150}
                                    height={150}
                                    title="customer1"
                                  />
                                  <div className="gdlr-core-testimonial-quote gdlr-core-quote-font gdlr-core-skin-icon">
                                    “
                                  </div>
                                </div>
                                <div className="gdlr-core-testimonial-content-wrap">
                                  <div
                                    className="gdlr-core-testimonial-content gdlr-core-info-font gdlr-core-skin-content"
                                    style={{
                                      fontSize: "18px",
                                      color: "#656565",
                                      paddingBottom: "25px",
                                    }}
                                  >
                                    <p>
                                      A very safe place for girls, but other
                                      than that everything else is average. The
                                      food is just good for the money nothing
                                      exceptional, and the same goes for the
                                      place as a whole. But overall this is one
                                      of the affordable but worthy places in
                                      OMR.
                                    </p>
                                  </div>
                                  <div className="gdlr-core-testimonial-author-wrap clearfix">
                                    <div className="gdlr-core-testimonial-author-content">
                                      <div
                                        className="gdlr-core-testimonial-title gdlr-core-title-font gdlr-core-skin-title"
                                        style={{
                                          color: "#313131",
                                          fontSize: "19px",
                                          fontWeight: 600,
                                          fontStyle: "normal",
                                          letterSpacing: "0px",
                                          textTransform: "none",
                                        }}
                                      >
                                        Paramasundhari
                                      </div>
                                      {/* <div
                                        className="gdlr-core-testimonial-position gdlr-core-info-font gdlr-core-skin-caption"
                                        style={{
                                          color: "#313131",
                                          fontSize: "14px",
                                          fontStyle: "normal",
                                          fontWeight: 500,
                                        }}
                                      >
                                        Solo Traveler
                                      </div> */}
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </li>
                          {/* <li className="gdlr-core-item-mglr">
                            <div className="gdlr-core-testimonial clearfix gdlr-core-with-frame">
                              <div
                                className="gdlr-core-testimonial-frame clearfix gdlr-core-skin-e-background gdlr-core-outer-frame-element"
                                style={{
                                  boxShadow:
                                    "0px 15px 35px rgba(0, 0, 0, 0.08)",
                                  MozBoxShadow:
                                    "0px 15px 35px rgba(0, 0, 0, 0.08)",
                                  WebkitBoxShadow:
                                    "0px 15px 35px rgba(0, 0, 0, 0.08)",
                                  borderRadius: "20px 20px 20px 20px",
                                  MozBorderRadius: "20px 20px 20px 20px",
                                  WebkitBorderRadius: "20px 20px 20px 20px",
                                }}
                              >
                                <div
                                  className="gdlr-core-testimonial-frame-border"
                                  style={{
                                    borderRadius: "20px 20px 20px 20px",
                                    MozBorderRadius: "20px 20px 20px 20px",
                                    WebkitBorderRadius: "20px 20px 20px 20px",
                                  }}
                                />
                                <div className="gdlr-core-testimonial-author-image gdlr-core-media-image">
                                  <img
                                    src="upload/customer03-150x150.jpg"
                                    alt=""
                                    width={150}
                                    height={150}
                                    title="customer03"
                                  />
                                  <div className="gdlr-core-testimonial-quote gdlr-core-quote-font gdlr-core-skin-icon">
                                    “
                                  </div>
                                </div>
                                <div className="gdlr-core-testimonial-content-wrap">
                                  <div
                                    className="gdlr-core-testimonial-content gdlr-core-info-font gdlr-core-skin-content"
                                    style={{
                                      fontSize: "18px",
                                      color: "#656565",
                                      paddingBottom: "25px",
                                    }}
                                  >
                                    <p>
                                      A wonderful serenity has taken possession
                                      of my entire soul, like these sweet
                                      mornings of spring which I enjoy with my
                                      whole heart. I am alone, and feel the
                                      charm of existence.
                                    </p>
                                  </div>
                                  <div className="gdlr-core-testimonial-author-wrap clearfix">
                                    <div className="gdlr-core-testimonial-author-content">
                                      <div
                                        className="gdlr-core-testimonial-title gdlr-core-title-font gdlr-core-skin-title"
                                        style={{
                                          color: "#313131",
                                          fontSize: "19px",
                                          fontWeight: 600,
                                          fontStyle: "normal",
                                          letterSpacing: "0px",
                                          textTransform: "none",
                                        }}
                                      >
                                        Louis Lewis
                                      </div>
                                      <div
                                        className="gdlr-core-testimonial-position gdlr-core-info-font gdlr-core-skin-caption"
                                        style={{
                                          color: "#313131",
                                          fontSize: "14px",
                                          fontStyle: "normal",
                                          fontWeight: 500,
                                        }}
                                      >
                                        Solo Traveler
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </li> */}
                        </ul>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
