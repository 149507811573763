import React from "react";

export default function Section1() {
  return (
    <>
      <div
        style={{
          backgroundImage: ` url("/images/banner-bg.jpg")`,
          backgroundSize: "cover",
          padding: "0px 0px 0px 0px",
        }}
        className="gdlr-core-pbf-wrapper"
      >
        <div className="gdlr-core-pbf-wrapper-content gdlr-core-js">
          <div className="gdlr-core-pbf-wrapper-container clearfix gdlr-core-container">
            <div
              className="gdlr-core-pbf-column gdlr-core-column-30 gdlr-core-column-first"
              id="gdlr-core-column-1"
              style={{ padding: "20px" }}
            >
              <div
                className="cover-image"
                style={{
                  marginTop: "100px",
                  marginLeft: "60px",
                }}
              >
                <img
                  style={{
                    objectFit: "cover",
                    borderRadius: "100%",
                    width: "450px",
                    height: "450px",
                  }}
                  src="/images/15.jpg"
                />
              </div>
            </div>
            <div
              className="gdlr-core-pbf-column gdlr-core-column-30"
              id="gdlr-core-column-2"
            >
              <div
                className="gdlr-core-pbf-column-content-margin gdlr-core-js"
                style={{
                  margin: "0px 0px 0px 0px",
                  padding: "175px 80px 200px 70px",
                }}
                data-sync-height="h1"
              >
                <div
                  className="gdlr-core-pbf-column-content clearfix gdlr-core-js gdlr-core-sync-height-content"
                  data-gdlr-animation="fadeInLeft"
                  data-gdlr-animation-duration="600ms"
                  data-gdlr-animation-offset="0.8"
                >
                  <div className="gdlr-core-pbf-element">
                    <div
                      className="gdlr-core-title-item gdlr-core-item-pdb clearfix gdlr-core-left-align gdlr-core-title-item-caption-top gdlr-core-item-pdlr"
                      style={{ paddingBottom: "25px" }}
                    >
                      <div className="gdlr-core-title-item-title-wrap">
                        <h3
                          className="gdlr-core-title-item-title gdlr-core-skin-title class-test title-name"
                          style={{
                            fontSize: "72px",
                            fontWeight: 400,
                            letterSpacing: "0px",
                            lineHeight: 1,
                            textTransform: "none",
                            color: "#141414",
                          }}
                        >
                          KSJ Stay
                          {/* <span style={{ fontWeight: 700 }}>Chennai</span> */}
                          <span
                            style={{
                              color: "#ff5023",
                              fontSize: "72px",
                            }}
                          >
                            .
                          </span>
                          <span className="gdlr-core-title-item-title-divider gdlr-core-skin-divider" />
                        </h3>
                      </div>
                    </div>
                  </div>
                  <div className="gdlr-core-pbf-element">
                    <div
                      className="gdlr-core-text-box-item gdlr-core-item-pdlr gdlr-core-item-pdb gdlr-core-left-align"
                      style={{ paddingBottom: "15px" }}
                    >
                      <div
                        className="gdlr-core-text-box-item-content"
                        style={{
                          fontSize: "18px",
                          textTransform: "none",
                          color: "#fff",
                        }}
                      >
                        <p>
                          <br /> <br />
                          We are a PG accommodating working women in Chennai,
                          Perungudi and Near OMR in the closest proximity of the
                          IT hubs and is accessible to many IT Tech parks.
                          <br />
                          {/* It is a top-tier comfort stay for working women who needs all the amenities. Our stay comes with an assurance for Discipline, Security and an amazing care. Our mature process and system in the place assures you our best services during your entire stay. */}
                        </p>
                      </div>
                    </div>
                  </div>
                  <div className="gdlr-core-pbf-element">
                    <div className="gdlr-core-divider-item gdlr-core-divider-item-normal gdlr-core-item-pdlr gdlr-core-left-align">
                      <div
                        className="gdlr-core-divider-container"
                        style={{ maxWidth: "40px" }}
                      >
                        <div
                          className="gdlr-core-divider-line gdlr-core-skin-divider"
                          style={{
                            borderColor: "#ff5023",
                            borderWidth: "3px",
                          }}
                        />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      {/* <div
        className="gdlr-core-pbf-wrapper"
        style={{
          margin: "-90px auto 0px auto",
          padding: "5px 0px 5px 0px",
          maxWidth: "920px",
        }}
      >
        <div
          className="gdlr-core-pbf-background-wrap"
          style={{
            boxShadow: "0px 30px 70px rgba(44, 45, 57, 0.2)",
            MozBoxShadow: "0px 30px 70px rgba(44, 45, 57, 0.2)",
            WebkitBoxShadow: "0px 30px 70px rgba(44, 45, 57, 0.2)",
            borderRadius: "20px 20px 20px 20px",
            MozBorderRadius: "20px 20px 20px 20px",
            WebkitBorderRadius: "20px 20px 20px 20px",
            backgroundColor: "#ffffff",
          }}
        />
        <div className="gdlr-core-pbf-wrapper-content gdlr-core-js">
          <div className="gdlr-core-pbf-wrapper-container clearfix gdlr-core-container-custom">
            <div
              className="gdlr-core-pbf-column gdlr-core-column-60 gdlr-core-column-first"
              data-skin="Green Button"
              id="gdlr-core-column-3"
            >
              <div
                className="gdlr-core-pbf-column-content-margin gdlr-core-js"
                style={{
                  margin: "0px 0px 0px 0px",
                  padding: "20px 10px 0px 10px",
                }}
              >
                <div className="gdlr-core-pbf-background-wrap" />
                <div className="gdlr-core-pbf-column-content clearfix gdlr-core-js">
                  <div className="gdlr-core-pbf-element">
                    <div className="tourmaster-room-search-item tourmaster-item-pdlr clearfix">
                      <div className="custom-section">
                        <div className="Feature-List">
                          <span className="name">Location</span>
                          <span className="value">
                            <select>
                              <option value="" selected disabled>
                                Select Location
                              </option>
                              <option value=""> OMR</option>
                              <option value=""> Thoraipakkam</option>
                            </select>
                          </span>
                        </div>
                        <div className="Feature-List">
                          <span className="name"> Accommodation</span>
                          <span className="value"> 20</span>
                        </div>
                        <div className="Feature-List">
                          <span className="name">Amenities</span>
                          <span className="value"> 5</span>
                        </div>
                        <div className="Feature-List">
                          <span className="name">Amenities</span>
                          <span className="value"> 5</span>
                        </div>
                      </div>

                       <form
                        className="tourmaster-room-search-form tourmaster-radius-normal tourmaster-style-text-top tourmaster-align-horizontal"
                        action="#"
                        method="get"
                      >
                        <div className="tourmaster-room-search-size10">
                          <div
                            className="tourmaster-room-date-selection tourmaster-horizontal"
                            data-avail-date
                          >
                            <div className="tourmaster-custom-start-date">
                              <div className="tourmaster-head gdlr-core-skin-content">
                                Check In
                              </div>
                              <div className="tourmaster-tail gdlr-core-skin-e-background gdlr-core-skin-e-content">
                                Jul 28, 2022
                              </div>
                              <input
                                type="hidden"
                                name="start_date"
                                defaultValue="2022-07-28"
                              />
                            </div>
                            <div className="tourmaster-custom-end-date">
                              <div className="tourmaster-head gdlr-core-skin-content">
                                Check Out
                              </div>
                              <div className="tourmaster-tail gdlr-core-skin-e-background gdlr-core-skin-e-content">
                                Jul 29, 2022
                              </div>
                              <input
                                type="hidden"
                                name="end_date"
                                defaultValue="2022-07-29"
                              />
                            </div>
                            <div
                              className="tourmaster-custom-datepicker-wrap"
                              data-date-format="d M yy"
                            >
                              <i className="tourmaster-custom-datepicker-close icon_close" />
                              <div className="tourmaster-custom-datepicker-title" />
                              <div className="tourmaster-custom-datepicker-calendar" />
                            </div>
                          </div>
                        </div>
                        <div className="tourmaster-room-size2">
                          <div className="tourmaster-room-amount-selection">
                            <div className="tourmaster-custom-amount-display">
                              <div className="tourmaster-head gdlr-core-skin-content">
                                Room
                              </div>
                              <div className="tourmaster-tail gdlr-core-skin-e-background gdlr-core-skin-e-content">
                                <span className="tourmaster-space" />1
                              </div>
                            </div>
                            <div className="tourmaster-custom-amount-selection-wrap">
                              <div className="tourmaster-custom-amount-selection-item clearfix">
                                <div className="tourmaster-head">Room</div>
                                <div className="tourmaster-tail">
                                  <span className="tourmaster-minus">
                                    <i className="icon_minus-06" />
                                  </span>
                                  <span className="tourmaster-amount">1</span>
                                  <span className="tourmaster-plus">
                                    <i className="icon_plus" />
                                  </span>
                                </div>
                                <input
                                  type="hidden"
                                  name="room_amount"
                                  defaultValue={1}
                                />
                              </div>
                            </div>
                          </div>
                        </div>
                        <div className="tourmaster-room-search-size5">
                          <div className="tourmaster-room-amount-selection">
                            <div className="tourmaster-custom-amount-display">
                              <div className="tourmaster-head gdlr-core-skin-content">
                                Guests
                              </div>
                              <div className="tourmaster-tail gdlr-core-skin-e-background gdlr-core-skin-e-content">
                                <span className="tourmaster-space" />
                                Adults 2
                                <span className="tourmaster-space" />
                                Children 0
                              </div>
                            </div>
                            <div className="tourmaster-custom-amount-selection-wrap">
                              <div
                                className="tourmaster-custom-amount-selection-item clearfix"
                                data-label="Adults"
                              >
                                <div className="tourmaster-head">Adults</div>
                                <div className="tourmaster-tail">
                                  <span className="tourmaster-minus">
                                    <i className="icon_minus-06" />
                                  </span>
                                  <span className="tourmaster-amount">2</span>
                                  <span className="tourmaster-plus">
                                    <i className="icon_plus" />
                                  </span>
                                </div>
                                <input
                                  type="hidden"
                                  name="adult"
                                  defaultValue={2}
                                />
                              </div>
                              <div
                                className="tourmaster-custom-amount-selection-item clearfix"
                                data-label="Children"
                              >
                                <div className="tourmaster-head">Children</div>
                                <div className="tourmaster-tail">
                                  <span className="tourmaster-minus">
                                    <i className="icon_minus-06" />
                                  </span>
                                  <span className="tourmaster-amount">0</span>
                                  <span className="tourmaster-plus">
                                    <i className="icon_plus" />
                                  </span>
                                </div>
                                <input
                                  type="hidden"
                                  name="children"
                                  defaultValue={0}
                                />
                              </div>
                            </div>
                          </div>
                        </div>
                        <div className="tourmaster-room-search-size4 tourmaster-room-search-submit-wrap">
                          <input
                            className="tourmaster-room-search-submit tourmaster-style-solid"
                            type="submit"
                            defaultValue="Search Room"
                          />
                        </div>
                        <input type="hidden" name="room-search" defaultValue />
                      </form> 
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div> */}
    </>
  );
}
