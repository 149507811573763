import React from "react";
import Footer from "./components/Homepage/Footer";
import Header from "./components/Homepage/header";
export default function ContactUS() {
  return (
    <div>
      <div className="hotale-mobile-header-wrap">
        <div
          className="hotale-mobile-header hotale-header-background hotale-style-slide hotale-sticky-mobile-navigation"
          id="hotale-mobile-header"
        >
          <div className="hotale-mobile-header-container hotale-container clearfix">
            <div className="hotale-logo hotale-item-pdlr">
              <div className="hotale-logo-inner">
                <a className="hotale-fixed-nav-logo" href="index.html">
                  <img
                    style={{ width: "30px" }}
                    src="images/hostel.png"
                    alt=""
                    width={147}
                    height={40}
                    title="KSJ"
                  />
                  <span
                    style={{
                      fontSize: "23px",
                      fontWeight: 70,
                      letterSpacing: "0px",
                      lineHeight: 1,
                      textTransform: "none",
                      color: "#141414",
                    }}
                  >
                    {" "}
                    KSJ Stay
                  </span>
                </a>
                <a className="hotale-orig-logo" href="index.html">
                  <img
                    style={{ width: "30px" }}
                    src="images/hostel.png"
                    alt=""
                    width={294}
                    height={80}
                    title="KSJ"
                  />
                  <span
                    style={{
                      fontSize: "23px",
                      fontWeight: 70,
                      letterSpacing: "0px",
                      lineHeight: 1,
                      textTransform: "none",
                      color: "#141414",
                    }}
                  >
                    {" "}
                    KSJ
                  </span>
                </a>
              </div>
            </div>
            <div className="hotale-mobile-menu-right">
              <div
                className="tourmaster-user-top-bar tourmaster-guest tourmaster-style-2"
                data-redirect="/"
                // data-ajax-url="#"
              >
                <span
                  className="tourmaster-user-top-bar-login"
                  // data-tmlb="login"
                >
                  <i className="icon_lock_alt" />
                  <span className="tourmaster-text">About</span>
                </span>
                <div
                  className="tourmaster-lightbox-content-wrap"
                  data-tmlb-id="login"
                >
                  <div className="tourmaster-lightbox-head">
                    <h3 className="tourmaster-lightbox-title">Login</h3>
                    <i className="tourmaster-lightbox-close icon_close" />
                  </div>
                  <div className="tourmaster-lightbox-content">
                    <form
                      className="tourmaster-login-form tourmaster-form-field tourmaster-with-border"
                      method="post"
                      action="#wp-login.php"
                    >
                      <div className="tourmaster-login-form-fields clearfix">
                        <p className="tourmaster-login-user">
                          <label>Username or E-Mail</label>
                          <input type="text" name="log" />
                        </p>
                        <p className="tourmaster-login-pass">
                          <label>Password</label>
                          <input type="password" name="pwd" />
                        </p>
                      </div>
                      <p className="tourmaster-login-submit">
                        <input
                          type="submit"
                          name="wp-submit"
                          className="tourmaster-button"
                          defaultValue="Sign In!"
                        />
                      </p>
                      <p className="tourmaster-login-lost-password">
                        <a href="#">Forget Password?</a>
                      </p>
                    </form>
                    <div className="tourmaster-login-bottom">
                      <h3 className="tourmaster-login-bottom-title">
                        Do not have an account?
                      </h3>
                      <a
                        className="tourmaster-login-bottom-link"
                        href="register.html"
                      >
                        Create an Account
                      </a>
                    </div>
                  </div>
                </div>
              </div>
              <div className="hotale-mobile-menu">
                <a
                  className="hotale-mm-menu-button hotale-mobile-menu-button hotale-mobile-button-hamburger"
                  href="#hotale-mobile-menu"
                >
                  <span />
                </a>
                <div
                  className="hotale-mm-menu-wrap hotale-navigation-font"
                  id="hotale-mobile-menu"
                  data-slide="right"
                >
                  <ul id="menu-main-navigation" className="m-menu">
                    <li className="menu-item menu-item-home current-menu-item  menu-item-has-children">
                      <a href="index.html" aria-current="page">
                        Home
                      </a>
                      <ul className="sub-menu">
                        <li className="menu-item menu-item-home current-menu-item page_item page-item-14980 current_page_item">
                          <a href="../resort/index.html" aria-current="page">
                            Home – Resort 1
                          </a>
                        </li>
                        <li className="menu-item">
                          <a
                            target="_blank"
                            rel="noopener"
                            href="../resort2/index.html"
                          >
                            Home – Resort 2
                          </a>
                        </li>
                        <li className="menu-item">
                          <a
                            target="_blank"
                            rel="noopener"
                            href="../hotel1/index.html"
                          >
                            Home – Hotel 1
                          </a>
                        </li>
                        <li className="menu-item">
                          <a
                            target="_blank"
                            rel="noopener"
                            href="../hotel2/index.html"
                          >
                            Home – Hotel 2
                          </a>
                        </li>
                        <li className="menu-item">
                          <a
                            rel="noopener"
                            target="_blank"
                            href="../apartment/index.html"
                          >
                            Home – Apartment
                          </a>
                        </li>
                        <li className="menu-item">
                          <a href="index.html">Home – Apartment 2</a>
                        </li>
                      </ul>
                    </li>
                    <li className="menu-item menu-item-has-children">
                      <a href="#">Pages</a>
                      <ul className="sub-menu">
                        <li className="menu-item">
                          <a href="about-us.html">About Us</a>
                        </li>
                        <li className="menu-item">
                          <a href="about-us-2.html">About Us 2</a>
                        </li>
                        <li className="menu-item">
                          <a href="about-us-3.html">About Us 3</a>
                        </li>
                        <li className="menu-item">
                          <a href="our-team.html">Our Team</a>
                        </li>
                        <li className="menu-item">
                          <a href="hotel-review.html">Hotel Review</a>
                        </li>
                        <li className="menu-item">
                          <a href="faq.html">FAQ</a>
                        </li>
                        <li className="menu-item">
                          <a href="gallery.html">Gallery</a>
                        </li>
                        <li className="menu-item">
                          <a href="price-table.html">Price Table</a>
                        </li>
                        <li className="menu-item">
                          <a href="maintenance.html">Maintenance</a>
                        </li>
                        <li className="menu-item">
                          <a href="coming-soon.html">Coming Soon</a>
                        </li>
                        <li className="menu-item">
                          <a href="404.html">404 Page</a>
                        </li>
                      </ul>
                    </li>
                    <li className="menu-item menu-item-has-children">
                      <a href="room-grid-style-1.html">Rooms</a>
                      <ul className="sub-menu">
                        <li className="menu-item">
                          <a href="room-grid-style-1.html">Room Grid Style 1</a>
                        </li>
                        <li className="menu-item">
                          <a href="room-grid-style-2.html">Room Grid Style 2</a>
                        </li>
                        <li className="menu-item">
                          <a href="room-grid-style-3.html">Room Grid Style 3</a>
                        </li>
                        <li className="menu-item">
                          <a href="room-grid-style-4.html">Room Grid Style 4</a>
                        </li>
                        <li className="menu-item">
                          <a href="room-modern-style.html">Room Modern Style</a>
                        </li>
                        <li className="menu-item">
                          <a href="room-side-thumbnail.html">
                            Room Side Thumbnail
                          </a>
                        </li>
                      </ul>
                    </li>
                    <li className="menu-item">
                      <a href="room-search.html">Reservation</a>
                    </li>
                    <li className="menu-item menu-item-has-children">
                      <a href="blog-full-right-sidebar.html">Blog</a>
                      <ul className="sub-menu">
                        <li className="menu-item menu-item-has-children">
                          <a href="blog-3-columns-with-frame.html">
                            Blog Columns
                          </a>
                          <ul className="sub-menu">
                            <li className="menu-item">
                              <a href="blog-2-columns.html">Blog 2 Columns</a>
                            </li>
                            <li className="menu-item">
                              <a href="blog-2-columns-with-frame.html">
                                Blog 2 Columns With Frame
                              </a>
                            </li>
                            <li className="menu-item">
                              <a href="blog-3-columns.html">Blog 3 Columns</a>
                            </li>
                            <li className="menu-item">
                              <a href="blog-3-columns-with-frame.html">
                                Blog 3 Columns With Frame
                              </a>
                            </li>
                            <li className="menu-item">
                              <a href="blog-4-columns.html">Blog 4 Columns</a>
                            </li>
                            <li className="menu-item">
                              <a href="blog-4-columns-with-frame.html">
                                Blog 4 Columns With Frame
                              </a>
                            </li>
                          </ul>
                        </li>
                        <li className="menu-item menu-item-has-children">
                          <a href="blog-full-right-sidebar.html">Blog Full</a>
                          <ul className="sub-menu">
                            <li className="menu-item">
                              <a href="blog-full-right-sidebar.html">
                                Blog Full Right Sidebar
                              </a>
                            </li>
                            <li className="menu-item">
                              <a href="blog-full-right-sidebar-with-frame.html">
                                Blog Full Right Sidebar With Frame
                              </a>
                            </li>
                            <li className="menu-item">
                              <a href="blog-full-left-sidebar.html">
                                Blog Full Left Sidebar
                              </a>
                            </li>
                            <li className="menu-item">
                              <a href="blog-full-left-sidebar-with-frame.html">
                                Blog Full Left Sidebar With Frame
                              </a>
                            </li>
                            <li className="menu-item">
                              <a href="blog-full-both-sidebar.html">
                                Blog Full Both Sidebar
                              </a>
                            </li>
                            <li className="menu-item">
                              <a href="blog-full-both-sidebar-with-frame.html">
                                Blog Full Both Sidebar With Frame
                              </a>
                            </li>
                          </ul>
                        </li>
                        <li className="menu-item menu-item-has-children">
                          <a href="blog-grid-3-columns.html">Blog Grid</a>
                          <ul className="sub-menu">
                            <li className="menu-item">
                              <a href="blog-grid-2-columns.html">
                                Blog Grid 2 Columns
                              </a>
                            </li>
                            <li className="menu-item">
                              <a href="blog-grid-2-columns-no-space.html">
                                Blog Grid 2 Columns No Space
                              </a>
                            </li>
                            <li className="menu-item">
                              <a href="blog-grid-3-columns.html">
                                Blog Grid 3 Columns
                              </a>
                            </li>
                            <li className="menu-item">
                              <a href="blog-grid-3-columns-no-space.html">
                                Blog Grid 3 Columns No Space
                              </a>
                            </li>
                            <li className="menu-item">
                              <a href="blog-grid-4-columns.html">
                                Blog Grid 4 Columns
                              </a>
                            </li>
                            <li className="menu-item">
                              <a href="blog-grid-4-columns-no-space.html">
                                Blog Grid 4 Columns No Space
                              </a>
                            </li>
                          </ul>
                        </li>
                        <li className="menu-item">
                          <a href="single-blog.html">Single Posts</a>
                        </li>
                      </ul>
                    </li>
                    <li className="menu-item">
                      <a href="contact.html">Contact</a>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="hotale-body-outer-wrapper">
        <div className="hotale-body-wrapper clearfix hotale-with-frame">
          <Header />
          <div>
            <div className="hotale-page-title-wrap hotale-style-custom hotale-center-align">
              <div className="hotale-header-transparent-substitute" />
              <div className="hotale-page-title-overlay" />
              {/* <div className="hotale-page-title-container hotale-container">
                <div className="hotale-page-title-content hotale-item-pdlr">
                  <h1 className="hotale-page-title">Contact Us</h1>
                </div>
              </div> */}
            </div>
            <div className="hotale-page-wrapper" id="hotale-page-wrapper">
              <div className="gdlr-core-page-builder-body">
                <div
                  className="gdlr-core-pbf-wrapper"
                  style={{ padding: "30px 0px 20px 0px" }}
                  data-skin="White Text"
                >
                  <div
                    className="gdlr-core-pbf-background-wrap"
                    style={{ backgroundColor: "#191919" }}
                  >
                    <div
                      className="gdlr-core-pbf-background gdlr-core-parallax gdlr-core-js"
                      style={{
                        opacity: "0.5",
                        backgroundSize: "cover",
                        backgroundPosition: "center",
                      }}
                      data-parallax-speed="0.2"
                    />
                  </div>
                  <div className="gdlr-core-pbf-wrapper-content gdlr-core-js">
                    <div className="gdlr-core-pbf-wrapper-container clearfix gdlr-core-container">
                      <div
                        className="gdlr-core-pbf-column gdlr-core-column-20 gdlr-core-column-first"
                        id="gdlr-core-column-1"
                      >
                        <div
                          className="gdlr-core-pbf-column-content-margin gdlr-core-js"
                          style={{ padding: "50px 20px 0px 20px" }}
                        >
                          <div className="gdlr-core-pbf-background-wrap" />
                          <div
                            className="gdlr-core-pbf-column-content clearfix gdlr-core-js"
                            data-gdlr-animation="fadeInUp"
                            data-gdlr-animation-duration="600ms"
                            data-gdlr-animation-offset="0.8"
                          >
                            <div className="gdlr-core-pbf-element">
                              <div
                                className="gdlr-core-icon-item gdlr-core-item-pdlr gdlr-core-item-pdb gdlr-core-left-align"
                                style={{ paddingBottom: "40px" }}
                              >
                                <div
                                  className="gdlr-core-icon-item-wrap gdlr-core-skin-e-background gdlr-core-icon-item-type-round"
                                  style={{
                                    backgroundColor: "#ffffff",
                                    borderRadius: "40px",
                                    MozBorderRadius: "40px",
                                    WebkitBorderRadius: "40px",
                                    padding: "20px 20px 20px 20px",
                                  }}
                                >
                                  <i
                                    className="gdlr-core-icon-item-icon fa fa-phone gdlr-core-skin-e-content"
                                    style={{
                                      color: "#0a0a0a",
                                      fontSize: "40px",
                                      minWidth: "40px",
                                      minHeight: "40px",
                                    }}
                                  />
                                </div>
                              </div>
                            </div>
                            <div className="gdlr-core-pbf-element">
                              <div
                                className="gdlr-core-title-item gdlr-core-item-pdb clearfix gdlr-core-left-align gdlr-core-title-item-caption-top gdlr-core-item-pdlr"
                                style={{ paddingBottom: "25px" }}
                              >
                                <div className="gdlr-core-title-item-title-wrap">
                                  <h3
                                    className="gdlr-core-title-item-title gdlr-core-skin-title class-test"
                                    style={{
                                      fontSize: "26px",
                                      letterSpacing: "0px",
                                      textTransform: "none",
                                      color: "#ffffff",
                                    }}
                                  >
                                    Phone
                                    <span className="gdlr-core-title-item-title-divider gdlr-core-skin-divider" />
                                  </h3>
                                </div>
                              </div>
                            </div>
                            <div className="gdlr-core-pbf-element">
                              <div
                                className="gdlr-core-text-box-item gdlr-core-item-pdlr gdlr-core-item-pdb gdlr-core-left-align"
                                style={{ paddingBottom: "0px" }}
                              >
                                <div
                                  className="gdlr-core-text-box-item-content"
                                  style={{
                                    textTransform: "none",
                                    color: "#ffffff",
                                  }}
                                >
                                  <p>Mr.Jaikumar</p>
                                </div>
                              </div>
                            </div>
                            <div className="gdlr-core-pbf-element">
                              <div className="gdlr-core-text-box-item gdlr-core-item-pdlr gdlr-core-item-pdb gdlr-core-left-align">
                                <div
                                  className="gdlr-core-text-box-item-content"
                                  style={{
                                    fontWeight: 700,
                                    textTransform: "none",
                                  }}
                                >
                                  <p>
                                    <a
                                      style={{ textDecoration: "underline" }}
                                      href="#"
                                    >
                                      9942736737
                                      <br />
                                      9790736737
                                    </a>
                                  </p>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div
                        className="gdlr-core-pbf-column gdlr-core-column-20"
                        id="gdlr-core-column-2"
                      >
                        <div
                          className="gdlr-core-pbf-column-content-margin gdlr-core-js"
                          style={{ padding: "50px 20px 0px 20px" }}
                        >
                          <div className="gdlr-core-pbf-background-wrap" />
                          <div
                            className="gdlr-core-pbf-column-content clearfix gdlr-core-js"
                            data-gdlr-animation="fadeInDown"
                            data-gdlr-animation-duration="600ms"
                            data-gdlr-animation-offset="0.8"
                          >
                            <div className="gdlr-core-pbf-element">
                              <div
                                className="gdlr-core-icon-item gdlr-core-item-pdlr gdlr-core-item-pdb gdlr-core-left-align"
                                style={{ paddingBottom: "40px" }}
                              >
                                <div
                                  className="gdlr-core-icon-item-wrap gdlr-core-skin-e-background gdlr-core-icon-item-type-round"
                                  style={{
                                    backgroundColor: "#ffffff",
                                    borderRadius: "40px",
                                    MozBorderRadius: "40px",
                                    WebkitBorderRadius: "40px",
                                    padding: "24px 24px 24px 24px",
                                  }}
                                >
                                  <i
                                    className="gdlr-core-icon-item-icon fa fa-envelope-o gdlr-core-skin-e-content"
                                    style={{
                                      color: "#000000",
                                      fontSize: "31px",
                                      minWidth: "31px",
                                      minHeight: "31px",
                                    }}
                                  />
                                </div>
                              </div>
                            </div>
                            <div className="gdlr-core-pbf-element">
                              <div
                                className="gdlr-core-title-item gdlr-core-item-pdb clearfix gdlr-core-left-align gdlr-core-title-item-caption-top gdlr-core-item-pdlr"
                                style={{ paddingBottom: "25px" }}
                              >
                                <div className="gdlr-core-title-item-title-wrap">
                                  <h3
                                    className="gdlr-core-title-item-title gdlr-core-skin-title class-test"
                                    style={{
                                      fontSize: "26px",
                                      letterSpacing: "0px",
                                      textTransform: "none",
                                      color: "#ffffff",
                                    }}
                                  >
                                    Email
                                    <span className="gdlr-core-title-item-title-divider gdlr-core-skin-divider" />
                                  </h3>
                                </div>
                              </div>
                            </div>
                            {/* <div className="gdlr-core-pbf-element">
                              <div
                                className="gdlr-core-text-box-item gdlr-core-item-pdlr gdlr-core-item-pdb gdlr-core-left-align"
                                style={{ paddingBottom: "0px" }}
                              >
                                <div
                                  className="gdlr-core-text-box-item-content"
                                  style={{
                                    textTransform: "none",
                                    color: "#ffffff",
                                  }}
                                >
                                  <p>
                                    reach us through email
                                  </p>
                                </div>
                              </div>
                            </div> */}

                            <div className="gdlr-core-pbf-element">
                              <div className="gdlr-core-text-box-item gdlr-core-item-pdlr gdlr-core-item-pdb gdlr-core-left-align">
                                <div
                                  className="gdlr-core-text-box-item-content"
                                  style={{
                                    fontWeight: 700,
                                    textTransform: "none",
                                  }}
                                >
                                  <p>
                                    <a
                                      style={{ textDecoration: "underline" }}
                                      href="#"
                                    >
                                      <span>Jaiksj512@gmail.com</span>
                                    </a>
                                  </p>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div
                        className="gdlr-core-pbf-column gdlr-core-column-20"
                        id="gdlr-core-column-3"
                      >
                        <div
                          className="gdlr-core-pbf-column-content-margin gdlr-core-js"
                          style={{ padding: "50px 20px 0px 20px" }}
                        >
                          <div className="gdlr-core-pbf-background-wrap" />
                          <div
                            className="gdlr-core-pbf-column-content clearfix gdlr-core-js"
                            data-gdlr-animation="fadeInUp"
                            data-gdlr-animation-duration="600ms"
                            data-gdlr-animation-offset="0.8"
                          >
                            <div className="gdlr-core-pbf-element">
                              <div
                                className="gdlr-core-icon-item gdlr-core-item-pdlr gdlr-core-item-pdb gdlr-core-left-align"
                                style={{ paddingBottom: "40px" }}
                              >
                                <div
                                  className="gdlr-core-icon-item-wrap gdlr-core-skin-e-background gdlr-core-icon-item-type-round"
                                  style={{
                                    backgroundColor: "#ffffff",
                                    borderRadius: "40px",
                                    MozBorderRadius: "40px",
                                    WebkitBorderRadius: "40px",
                                    padding: "24px 24px 24px 24px",
                                  }}
                                >
                                  <i
                                    className="gdlr-core-icon-item-icon fa fa-location-arrow gdlr-core-skin-e-content"
                                    style={{
                                      color: "#000000",
                                      fontSize: "31px",
                                      minWidth: "31px",
                                      minHeight: "31px",
                                    }}
                                  />
                                </div>
                              </div>
                            </div>
                            <div className="gdlr-core-pbf-element">
                              <div
                                className="gdlr-core-title-item gdlr-core-item-pdb clearfix gdlr-core-left-align gdlr-core-title-item-caption-top gdlr-core-item-pdlr"
                                style={{ paddingBottom: "25px" }}
                              >
                                <div className="gdlr-core-title-item-title-wrap">
                                  <h3
                                    className="gdlr-core-title-item-title gdlr-core-skin-title class-test"
                                    style={{
                                      fontSize: "26px",
                                      letterSpacing: "0px",
                                      textTransform: "none",
                                      color: "#ffffff",
                                    }}
                                  >
                                    Location
                                    <span className="gdlr-core-title-item-title-divider gdlr-core-skin-divider" />
                                  </h3>
                                </div>
                              </div>
                            </div>
                            <div className="gdlr-core-pbf-element">
                              <div
                                className="gdlr-core-text-box-item gdlr-core-item-pdlr gdlr-core-item-pdb gdlr-core-left-align"
                                style={{ paddingBottom: "0px" }}
                              >
                                <div
                                  className="gdlr-core-text-box-item-content"
                                  style={{
                                    textTransform: "none",
                                    color: "#ffffff",
                                  }}
                                >
                                  <p>
                                    KSJ Mahal, Opposite to BSR Mall,
                                    <br />
                                    Behind seevaram Bus Stand Chennai,
                                    Perungudi.
                                  </p>
                                </div>
                              </div>
                            </div>
                            <div className="gdlr-core-pbf-element">
                              <div className="gdlr-core-text-box-item gdlr-core-item-pdlr gdlr-core-item-pdb gdlr-core-left-align">
                                <div
                                  className="gdlr-core-text-box-item-content"
                                  style={{
                                    fontWeight: 700,
                                    textTransform: "none",
                                  }}
                                >
                                  <p>
                                    <a
                                      style={{ textDecoration: "underline" }}
                                      href="https://goo.gl/maps/cHJjdnsTS3GJkxre8"
                                    >
                                      View On Google Map
                                    </a>
                                  </p>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div
                  className="gdlr-core-pbf-wrapper"
                  style={{ padding: "90px 0px 35px 0px" }}
                >
                  <div
                    className="gdlr-core-pbf-background-wrap"
                    style={{ backgroundColor: "#ffffff" }}
                  />
                  <div className="gdlr-core-pbf-wrapper-content gdlr-core-js">
                    <div className="gdlr-core-pbf-wrapper-container clearfix gdlr-core-container">
                      <div
                        className="gdlr-core-pbf-column gdlr-core-column-60 gdlr-core-column-first"
                        data-skin="Contact Field"
                        id="gdlr-core-column-4"
                      >
                        <div className="gdlr-core-pbf-column-content-margin gdlr-core-js">
                          <div className="gdlr-core-pbf-background-wrap" />
                          <div
                            className="gdlr-core-pbf-column-content clearfix gdlr-core-js"
                            style={{ maxWidth: "760px" }}
                          >
                            <div className="gdlr-core-pbf-element">
                              <div
                                className="gdlr-core-title-item gdlr-core-item-pdb clearfix gdlr-core-center-align gdlr-core-title-item-caption-bottom gdlr-core-item-pdlr"
                                style={{ paddingBottom: "60px" }}
                              >
                                <div className="gdlr-core-title-item-title-wrap">
                                  <h3
                                    className="gdlr-core-title-item-title gdlr-core-skin-title class-test"
                                    style={{
                                      fontSize: "39px",
                                      fontWeight: 600,
                                      letterSpacing: "0px",
                                      textTransform: "none",
                                    }}
                                  >
                                    Leave us your info{" "}
                                    <span className="gdlr-core-title-item-title-divider gdlr-core-skin-divider" />
                                  </h3>
                                </div>
                                <span
                                  className="gdlr-core-title-item-caption gdlr-core-info-font gdlr-core-skin-caption"
                                  style={{
                                    fontSize: "19px",
                                    fontStyle: "normal",
                                    letterSpacing: "0px",
                                  }}
                                >
                                  and we will get back to you.
                                </span>
                              </div>
                            </div>
                            <div className="gdlr-core-pbf-element">
                              <div className="gdlr-core-contact-form-7-item gdlr-core-item-pdlr gdlr-core-item-pdb">
                                <div
                                  role="form"
                                  className="wpcf7"
                                  id="wpcf7-f1979-p1964-o1"
                                  lang="en-US"
                                  dir="ltr"
                                >
                                  <div className="screen-reader-response">
                                    <p
                                      role="status"
                                      aria-live="polite"
                                      aria-atomic="true"
                                    />
                                    <ul />
                                  </div>
                                  <form
                                    method="post"
                                    action="https://max-themes.net/demos/hotale/hotale/apartment2/contactform/contactengine.php"
                                  >
                                    <input
                                      type="text"
                                      name="Name"
                                      id="Name"
                                      placeholder="Name:"
                                      className="input"
                                    />
                                    <input
                                      type="text"
                                      name="City"
                                      id="City"
                                      placeholder="City:"
                                      className="input"
                                    />
                                    <input
                                      type="text"
                                      name="Email"
                                      id="Email"
                                      placeholder="Email:"
                                      className="input"
                                    />
                                    <textarea
                                      name="Message"
                                      rows={20}
                                      cols={20}
                                      id="Message"
                                      placeholder="Message:"
                                      className="input"
                                      defaultValue={""}
                                    />
                                    <input
                                      type="submit"
                                      name="submit"
                                      defaultValue="Submit"
                                      className="submit-button"
                                    />
                                  </form>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>

                {/* <iframe src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d15552.86753262034!2d79.13611660872573!3d12.957969233360036!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3bad477cf9529b29%3A0x3a3ed0acaaa4fea!2sFortune%20Park%20Vellore!5e0!3m2!1sen!2sin!4v1662790833802!5m2!1sen!2sin" width="100%" style={{ marginBottom: "40px" }} height="450" allowfullscreen="" loading="lazy" referrerpolicy="no-referrer-when-downgrade"></iframe> */}
                <iframe
                  src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3888.3278188817144!2d80.24159074996388!3d12.950862790824688!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3a525d5aea2e409f%3A0xed34fa8a0d95c24f!2sKSJ%20Ladies%20Hostel!5e0!3m2!1sen!2sin!4v1665321102425!5m2!1sen!2sin"
                  width="100%"
                  style={{ marginBottom: "40px" }}
                  height="450"
                  allowFullScreen=""
                  loading="lazy"
                  referrerPolicy="no-referrer-when-downgrade"
                ></iframe>

                {/* <div
                  className="gdlr-core-pbf-wrapper"
                  style={{ padding: "0px 0px 0px 0px" }}
                >
                  <div className="gdlr-core-pbf-background-wrap" />
                  <div className="gdlr-core-pbf-wrapper-content gdlr-core-js">
                    <div className="gdlr-core-pbf-wrapper-container clearfix gdlr-core-pbf-wrapper-full-no-space">
                      <div className="gdlr-core-pbf-element">
                        <div
                          className="gdlr-core-wp-google-map-plugin-item gdlr-core-item-pdlr gdlr-core-item-pdb"
                          style={{ paddingBottom: "0px" }}
                        >
                          <div
                            className="wpgmp_map_container wpgmp-map-1"
                            rel="map1"
                          >
                            <iframe
                              style={{
                                width: "100%",
                                height: "500px",
                                border: 0,
                                marginBottom: "-9px",
                              }}
                              src="https://www.google.com/maps/embed?pb=!1m16!1m12!1m3!1d19905.696399556287!2d-0.3007084089960577!3d51.417531259591925!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!2m1!1s2334+Peterson+Street+Kingston+UK+London+H14D!5e0!3m2!1sen!2s!4v1546697086219"
                              width={600}
                              height={450}
                            />
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div> */}

                {/* <div
                  className="gdlr-core-pbf-wrapper"
                  style={{ padding: "80px 0px 50px 0px" }}
                >
                  <div
                    className="gdlr-core-pbf-background-wrap"
                    style={{ backgroundColor: "#ffffff" }}
                  />
                  <div className="gdlr-core-pbf-wrapper-content gdlr-core-js">
                    <div className="gdlr-core-pbf-wrapper-container clearfix gdlr-core-container">
                      <div className="gdlr-core-pbf-element">
                        <div className="gdlr-core-social-network-item gdlr-core-item-pdb gdlr-core-center-align gdlr-core-item-pdlr gdlr-direction-horizontal">
                          <a
                            href="https://max-themes.net/cdn-cgi/l/email-protection#5172"
                            target="_blank"
                            className="gdlr-core-social-network-icon"
                            title="email"
                            style={{
                              fontSize: "20px",
                              color: "#1e1e1e",
                              marginRight: "40px",
                            }}
                          >
                            <i className="fa fa-envelope" />
                          </a>
                          <a
                            href="#"
                            target="_blank"
                            className="gdlr-core-social-network-icon"
                            title="facebook"
                            style={{
                              fontSize: "20px",
                              color: "#1e1e1e",
                              marginRight: "40px",
                            }}
                          >
                            <i className="fa fa-facebook" />
                          </a>
                          <a
                            href="#"
                            target="_blank"
                            className="gdlr-core-social-network-icon"
                            title="skype"
                            style={{
                              fontSize: "20px",
                              color: "#1e1e1e",
                              marginRight: "40px",
                            }}
                          >
                            <i className="fa fa-skype" />
                          </a>
                          <a
                            href="#"
                            target="_blank"
                            className="gdlr-core-social-network-icon"
                            title="twitter"
                            style={{
                              fontSize: "20px",
                              color: "#1e1e1e",
                              marginRight: "40px",
                            }}
                          >
                            <i className="fa fa-twitter" />
                          </a>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
   */}
              </div>
            </div>
          </div>

          <Footer />
        </div>
      </div>
    </div>
  );
}
