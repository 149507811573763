import React from "react";
import Footer from "./components/Homepage/Footer";
import Header from "./components/Homepage/header";
export default function Gallery() {
  return (
    <div>
      <div className="hotale-mobile-header-wrap">
        <div
          className="hotale-mobile-header hotale-header-background hotale-style-slide hotale-sticky-mobile-navigation"
          id="hotale-mobile-header"
        >
          <div className="hotale-mobile-header-container hotale-container clearfix">
            <div className="hotale-logo hotale-item-pdlr">
              <div className="hotale-logo-inner">
                <a className="hotale-fixed-nav-logo" href="/">
                  <img
                    style={{ width: "30px" }}
                    src="images/hostel.png"
                    alt=""
                    width={147}
                    height={40}
                    title="KSJ"
                  />
                  <span
                    style={{
                      fontSize: "23px",
                      fontWeight: 70,
                      letterSpacing: "0px",
                      lineHeight: 1,
                      textTransform: "none",
                      color: "#141414",
                    }}
                  >
                    {" "}
                    KSJ Stay
                  </span>
                </a>
                <a className="hotale-orig-logo" href="/">
                  <img
                    style={{ width: "30px" }}
                    src="images/hostel.png"
                    alt=""
                    width={294}
                    height={80}
                    title="KSJ"
                  />
                  <span
                    style={{
                      fontSize: "23px",
                      fontWeight: 70,
                      letterSpacing: "0px",
                      lineHeight: 1,
                      textTransform: "none",
                      color: "#141414",
                    }}
                  >
                    {" "}
                    KSJ
                  </span>
                </a>
              </div>
            </div>
            <div className="hotale-mobile-menu-right">
              <div
                className="tourmaster-user-top-bar tourmaster-guest tourmaster-style-2"
                data-redirect="/"
                // data-ajax-url="#"
              >
                <span
                  className="tourmaster-user-top-bar-login"
                  // data-tmlb="login"
                >
                  <i className="icon_lock_alt" />
                  <span className="tourmaster-text">About</span>
                </span>
                {/* <div
                                    className="tourmaster-lightbox-content-wrap"
                                    data-tmlb-id="login"
                                >
                                    <div className="tourmaster-lightbox-head">
                                        <h3 className="tourmaster-lightbox-title">Login</h3>
                                        <i className="tourmaster-lightbox-close icon_close" />
                                    </div>
                                    <div className="tourmaster-lightbox-content">
                                        <form
                                            className="tourmaster-login-form tourmaster-form-field tourmaster-with-border"
                                            method="post"
                                            action="#wp-login.php"
                                        >
                                            <div className="tourmaster-login-form-fields clearfix">
                                                <p className="tourmaster-login-user">
                                                    <label>Username or E-Mail</label>
                                                    <input type="text" name="log" />
                                                </p>
                                                <p className="tourmaster-login-pass">
                                                    <label>Password</label>
                                                    <input type="password" name="pwd" />
                                                </p>
                                            </div>
                                            <p className="tourmaster-login-submit">
                                                <input
                                                    type="submit"
                                                    name="wp-submit"
                                                    className="tourmaster-button"
                                                    defaultValue="Sign In!"
                                                />
                                            </p>
                                            <p className="tourmaster-login-lost-password">
                                                <a href="#">Forget Password?</a>
                                            </p>
                                        </form>
                                        <div className="tourmaster-login-bottom">
                                            <h3 className="tourmaster-login-bottom-title">
                                                Do not have an account?
                </h3>
                                            <a
                                                className="tourmaster-login-bottom-link"
                                                href="register.html"
                                            >
                                                Create an Account
                </a>
                                        </div>
                                    </div>
                                </div> */}
              </div>
              <div className="hotale-mobile-menu">
                <a
                  className="hotale-mm-menu-button hotale-mobile-menu-button hotale-mobile-button-hamburger"
                  href="#hotale-mobile-menu"
                >
                  <span />
                </a>
                <div
                  className="hotale-mm-menu-wrap hotale-navigation-font"
                  id="hotale-mobile-menu"
                  data-slide="right"
                >
                  <ul id="menu-main-navigation" className="m-menu">
                    <li className="menu-item menu-item-home menu-item-has-children">
                      <a href="index.html" aria-current="page">
                        Home
                      </a>
                      <ul className="sub-menu">
                        <li className="menu-item menu-item-home current-menu-item page_item page-item-14980 current_page_item">
                          <a href="../resort/index.html" aria-current="page">
                            Home – Resort 1
                          </a>
                        </li>
                        <li className="menu-item">
                          <a
                            target="_blank"
                            rel="noopener"
                            href="../resort2/index.html"
                          >
                            Home – Resort 2
                          </a>
                        </li>
                        <li className="menu-item">
                          <a
                            target="_blank"
                            rel="noopener"
                            href="../hotel1/index.html"
                          >
                            Home – Hotel 1
                          </a>
                        </li>
                        <li className="menu-item">
                          <a
                            target="_blank"
                            rel="noopener"
                            href="../hotel2/index.html"
                          >
                            Home – Hotel 2
                          </a>
                        </li>
                        <li className="menu-item">
                          <a
                            rel="noopener"
                            target="_blank"
                            href="../apartment/index.html"
                          >
                            Home – Apartment
                          </a>
                        </li>
                        <li className="menu-item">
                          <a href="index.html">Home – Apartment 2</a>
                        </li>
                      </ul>
                    </li>
                    <li className="menu-item  menu-item-has-children">
                      <a href="#">Pages</a>
                      <ul className="sub-menu">
                        <li className="menu-item">
                          <a href="about-us.html">About Us</a>
                        </li>
                        <li className="menu-item">
                          <a href="about-us-2.html">About Us 2</a>
                        </li>
                        <li className="menu-item">
                          <a href="about-us-3.html">About Us 3</a>
                        </li>
                        <li className="menu-item">
                          <a href="our-team.html">Our Team</a>
                        </li>
                        <li className="menu-item">
                          <a href="hotel-review.html">Hotel Review</a>
                        </li>
                        <li className="menu-item">
                          <a href="faq.html">FAQ</a>
                        </li>
                        <li className="menu-item">
                          <a href="gallery.html">Gallery</a>
                        </li>
                        <li className="menu-item">
                          <a href="price-table.html">Price Table</a>
                        </li>
                        <li className="menu-item">
                          <a href="maintenance.html">Maintenance</a>
                        </li>
                        <li className="menu-item">
                          <a href="coming-soon.html">Coming Soon</a>
                        </li>
                        <li className="menu-item">
                          <a href="404.html">404 Page</a>
                        </li>
                      </ul>
                    </li>
                    <li className="menu-item menu-item-has-children">
                      <a href="room-grid-style-1.html">Rooms</a>
                      <ul className="sub-menu">
                        <li className="menu-item">
                          <a href="room-grid-style-1.html">Room Grid Style 1</a>
                        </li>
                        <li className="menu-item">
                          <a href="room-grid-style-2.html">Room Grid Style 2</a>
                        </li>
                        <li className="menu-item">
                          <a href="room-grid-style-3.html">Room Grid Style 3</a>
                        </li>
                        <li className="menu-item">
                          <a href="room-grid-style-4.html">Room Grid Style 4</a>
                        </li>
                        <li className="menu-item">
                          <a href="room-modern-style.html">Room Modern Style</a>
                        </li>
                        <li className="menu-item">
                          <a href="room-side-thumbnail.html">
                            Room Side Thumbnail
                          </a>
                        </li>
                      </ul>
                    </li>
                    <li className="menu-item">
                      <a href="room-search.html">Reservation</a>
                    </li>
                    <li className="menu-item menu-item-has-children">
                      <a href="blog-full-right-sidebar.html">Blog</a>
                      <ul className="sub-menu">
                        <li className="menu-item menu-item-has-children">
                          <a href="blog-3-columns-with-frame.html">
                            Blog Columns
                          </a>
                          <ul className="sub-menu">
                            <li className="menu-item">
                              <a href="blog-2-columns.html">Blog 2 Columns</a>
                            </li>
                            <li className="menu-item">
                              <a href="blog-2-columns-with-frame.html">
                                Blog 2 Columns With Frame
                              </a>
                            </li>
                            <li className="menu-item">
                              <a href="blog-3-columns.html">Blog 3 Columns</a>
                            </li>
                            <li className="menu-item">
                              <a href="blog-3-columns-with-frame.html">
                                Blog 3 Columns With Frame
                              </a>
                            </li>
                            <li className="menu-item">
                              <a href="blog-4-columns.html">Blog 4 Columns</a>
                            </li>
                            <li className="menu-item">
                              <a href="blog-4-columns-with-frame.html">
                                Blog 4 Columns With Frame
                              </a>
                            </li>
                          </ul>
                        </li>
                        <li className="menu-item menu-item-has-children">
                          <a href="blog-full-right-sidebar.html">Blog Full</a>
                          <ul className="sub-menu">
                            <li className="menu-item">
                              <a href="blog-full-right-sidebar.html">
                                Blog Full Right Sidebar
                              </a>
                            </li>
                            <li className="menu-item">
                              <a href="blog-full-right-sidebar-with-frame.html">
                                Blog Full Right Sidebar With Frame
                              </a>
                            </li>
                            <li className="menu-item">
                              <a href="blog-full-left-sidebar.html">
                                Blog Full Left Sidebar
                              </a>
                            </li>
                            <li className="menu-item">
                              <a href="blog-full-left-sidebar-with-frame.html">
                                Blog Full Left Sidebar With Frame
                              </a>
                            </li>
                            <li className="menu-item">
                              <a href="blog-full-both-sidebar.html">
                                Blog Full Both Sidebar
                              </a>
                            </li>
                            <li className="menu-item">
                              <a href="blog-full-both-sidebar-with-frame.html">
                                Blog Full Both Sidebar With Frame
                              </a>
                            </li>
                          </ul>
                        </li>
                        <li className="menu-item menu-item-has-children">
                          <a href="blog-grid-3-columns.html">Blog Grid</a>
                          <ul className="sub-menu">
                            <li className="menu-item">
                              <a href="blog-grid-2-columns.html">
                                Blog Grid 2 Columns
                              </a>
                            </li>
                            <li className="menu-item">
                              <a href="blog-grid-2-columns-no-space.html">
                                Blog Grid 2 Columns No Space
                              </a>
                            </li>
                            <li className="menu-item">
                              <a href="blog-grid-3-columns.html">
                                Blog Grid 3 Columns
                              </a>
                            </li>
                            <li className="menu-item">
                              <a href="blog-grid-3-columns-no-space.html">
                                Blog Grid 3 Columns No Space
                              </a>
                            </li>
                            <li className="menu-item">
                              <a href="blog-grid-4-columns.html">
                                Blog Grid 4 Columns
                              </a>
                            </li>
                            <li className="menu-item">
                              <a href="blog-grid-4-columns-no-space.html">
                                Blog Grid 4 Columns No Space
                              </a>
                            </li>
                          </ul>
                        </li>
                        <li className="menu-item">
                          <a href="single-blog.html">Single Posts</a>
                        </li>
                      </ul>
                    </li>
                    <li className="menu-item">
                      <a href="contact.html">Contact</a>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className="hotale-body-outer-wrapper">
        <div className="hotale-body-wrapper clearfix hotale-with-frame">
          <Header />
          {/* <div className="hotale-page-title-wrap hotale-style-custom hotale-center-align">
                        <div className="hotale-header-transparent-substitute" />
                        <div
                            className="hotale-page-title-overlay"
                            style={{ backgroundColor: "#f8f8f8" }}
                        />
                        <div className="hotale-page-title-container hotale-container">
                            <div className="hotale-page-title-content hotale-item-pdlr">
                                <h1 className="hotale-page-title">Gallery</h1>
                            </div>
                        </div>
                    </div> */}
          <div className="hotale-page-wrapper" id="hotale-page-wrapper">
            <div className="gdlr-core-page-builder-body">
              {/* <div
                                className="gdlr-core-pbf-wrapper"
                                style={{ padding: "100px 20px 30px 20px" }}
                            >
                                <div className="gdlr-core-pbf-background-wrap" />
                                <div className="gdlr-core-pbf-wrapper-content gdlr-core-js">
                                    <div className="gdlr-core-pbf-wrapper-container clearfix gdlr-core-pbf-wrapper-full">
                                        <div className="gdlr-core-pbf-element">
                                            <div
                                                className="gdlr-core-title-item gdlr-core-item-pdb clearfix gdlr-core-center-align gdlr-core-title-item-caption-bottom gdlr-core-item-pdlr"
                                                style={{ paddingBottom: 60 }}
                                            >
                                                <div className="gdlr-core-title-item-title-wrap">
                                                    <h3
                                                        className="gdlr-core-title-item-title gdlr-core-skin-title class-test"
                                                        style={{ textTransform: "none" }}
                                                    >
                                                        Grid Style
                      <span className="gdlr-core-title-item-title-divider gdlr-core-skin-divider" />
                                                    </h3>
                                                </div>
                                                <span
                                                    className="gdlr-core-title-item-caption gdlr-core-info-font gdlr-core-skin-caption"
                                                    style={{ fontStyle: "normal", letterSpacing: 0 }}
                                                >
                                                    With Space
                  </span>
                                            </div>
                                        </div>
                                        <div className="gdlr-core-pbf-element">
                                            <div className="gdlr-core-gallery-item gdlr-core-item-pdb clearfix gdlr-core-gallery-item-style-grid">
                                                <div
                                                    className="gdlr-core-gallery-item-holder gdlr-core-js-2 clearfix"
                                                    data-layout="fitrows"
                                                >
                                                    <div className="gdlr-core-item-list gdlr-core-gallery-column gdlr-core-column-15 gdlr-core-column-first gdlr-core-item-pdlr gdlr-core-item-mgb">
                                                        <div className="gdlr-core-gallery-list gdlr-core-media-image">
                                                            <a
                                                                className="gdlr-core-lightgallery gdlr-core-js"
                                                                href="upload/shutterstock_1069630346.jpg"
                                                                data-lightbox-group="gdlr-core-img-group-1"
                                                            >
                                                                <img
                                                                    src="upload/shutterstock_1069630346-1000x670.jpg"
                                                                    alt=""
                                                                    width={1000}
                                                                    height={670}
                                                                    title="Sofa"
                                                                />
                                                                <span className="gdlr-core-image-overlay">
                                                                    <i className="gdlr-core-image-overlay-icon gdlr-core-size-22 icon_zoom-in_alt" />
                                                                </span>
                                                            </a>
                                                        </div>
                                                    </div>
                                                    <div className="gdlr-core-item-list gdlr-core-gallery-column gdlr-core-column-15 gdlr-core-item-pdlr gdlr-core-item-mgb">
                                                        <div className="gdlr-core-gallery-list gdlr-core-media-image">
                                                            <a
                                                                className="gdlr-core-lightgallery gdlr-core-js"
                                                                href="upload/shutterstock_560973166.jpg"
                                                                data-lightbox-group="gdlr-core-img-group-1"
                                                            >
                                                                <img
                                                                    src="upload/shutterstock_560973166-1000x670.jpg"
                                                                    alt=""
                                                                    width={1000}
                                                                    height={670}
                                                                    title="Hotel Room"
                                                                />
                                                                <span className="gdlr-core-image-overlay">
                                                                    <i className="gdlr-core-image-overlay-icon gdlr-core-size-22 icon_zoom-in_alt" />
                                                                </span>
                                                            </a>
                                                        </div>
                                                    </div>
                                                    <div className="gdlr-core-item-list gdlr-core-gallery-column gdlr-core-column-15 gdlr-core-item-pdlr gdlr-core-item-mgb">
                                                        <div className="gdlr-core-gallery-list gdlr-core-media-image">
                                                            <a
                                                                className="gdlr-core-lightgallery gdlr-core-js"
                                                                href="upload/shutterstock_324822821.jpg"
                                                                data-lightbox-group="gdlr-core-img-group-1"
                                                            >
                                                                <img
                                                                    src="upload/shutterstock_324822821-1000x670.jpg"
                                                                    alt=""
                                                                    width={1000}
                                                                    height={670}
                                                                    title="Living Room"
                                                                />
                                                                <span className="gdlr-core-image-overlay">
                                                                    <i className="gdlr-core-image-overlay-icon gdlr-core-size-22 icon_zoom-in_alt" />
                                                                </span>
                                                            </a>
                                                        </div>
                                                    </div>
                                                    <div className="gdlr-core-item-list gdlr-core-gallery-column gdlr-core-column-15 gdlr-core-item-pdlr gdlr-core-item-mgb">
                                                        <div className="gdlr-core-gallery-list gdlr-core-media-image">
                                                            <a
                                                                className="gdlr-core-lightgallery gdlr-core-js"
                                                                href="upload/shutterstock_307470824.jpg"
                                                                data-lightbox-group="gdlr-core-img-group-1"
                                                            >
                                                                <img
                                                                    src="upload/shutterstock_307470824-1000x670.jpg"
                                                                    alt=""
                                                                    width={1000}
                                                                    height={670}
                                                                    title="The breakfast"
                                                                />
                                                                <span className="gdlr-core-image-overlay">
                                                                    <i className="gdlr-core-image-overlay-icon gdlr-core-size-22 icon_zoom-in_alt" />
                                                                </span>
                                                            </a>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div> */}

              {/* <div
                                className="gdlr-core-pbf-wrapper"
                                style={{ padding: "0px 0px 30px 0px" }}
                            >
                                <div className="gdlr-core-pbf-background-wrap" />
                                <div className="gdlr-core-pbf-wrapper-content gdlr-core-js">
                                    <div className="gdlr-core-pbf-wrapper-container clearfix gdlr-core-pbf-wrapper-full-no-space">
                                        <div className="gdlr-core-pbf-element">
                                            <div
                                                className="gdlr-core-title-item gdlr-core-item-pdb clearfix gdlr-core-center-align gdlr-core-title-item-caption-bottom gdlr-core-item-pdlr"
                                                style={{ paddingBottom: 60 }}
                                            >
                                                <div className="gdlr-core-title-item-title-wrap">
                                                    <h3
                                                        className="gdlr-core-title-item-title gdlr-core-skin-title class-test"
                                                        style={{ textTransform: "none" }}
                                                    >
                                                        Grid Style
                      <span className="gdlr-core-title-item-title-divider gdlr-core-skin-divider" />
                                                    </h3>
                                                </div>
                                                <span
                                                    className="gdlr-core-title-item-caption gdlr-core-info-font gdlr-core-skin-caption"
                                                    style={{ fontStyle: "normal", letterSpacing: 0 }}
                                                >
                                                    Without Space
                  </span>
                                            </div>
                                        </div>
                                        <div className="gdlr-core-pbf-element">
                                            <div className="gdlr-core-gallery-item gdlr-core-item-pdb clearfix gdlr-core-gallery-item-style-grid-no-space gdlr-core-item-pdlr">
                                                <div
                                                    className="gdlr-core-gallery-item-holder gdlr-core-js-2 clearfix"
                                                    data-layout="fitrows"
                                                >
                                                    <div className="gdlr-core-item-list gdlr-core-gallery-column gdlr-core-column-15 gdlr-core-column-first">
                                                        <div className="gdlr-core-gallery-list gdlr-core-media-image">
                                                            <a
                                                                className="gdlr-core-lightgallery gdlr-core-js"
                                                                href="upload/shutterstock_560973166.jpg"
                                                                data-lightbox-group="gdlr-core-img-group-2"
                                                            >
                                                                <img
                                                                    src="upload/shutterstock_560973166-1000x670.jpg"
                                                                    alt=""
                                                                    width={1000}
                                                                    height={670}
                                                                    title="Hotel Room"
                                                                />
                                                                <span className="gdlr-core-image-overlay">
                                                                    <i className="gdlr-core-image-overlay-icon gdlr-core-size-22 icon_zoom-in_alt" />
                                                                </span>
                                                            </a>
                                                        </div>
                                                    </div>
                                                    <div className="gdlr-core-item-list gdlr-core-gallery-column gdlr-core-column-15">
                                                        <div className="gdlr-core-gallery-list gdlr-core-media-image">
                                                            <a
                                                                className="gdlr-core-lightgallery gdlr-core-js"
                                                                href="upload/shutterstock_307470824.jpg"
                                                                data-lightbox-group="gdlr-core-img-group-2"
                                                            >
                                                                <img
                                                                    src="upload/shutterstock_307470824-1000x670.jpg"
                                                                    alt=""
                                                                    width={1000}
                                                                    height={670}
                                                                    title="The breakfast"
                                                                />
                                                                <span className="gdlr-core-image-overlay">
                                                                    <i className="gdlr-core-image-overlay-icon gdlr-core-size-22 icon_zoom-in_alt" />
                                                                </span>
                                                            </a>
                                                        </div>
                                                    </div>
                                                    <div className="gdlr-core-item-list gdlr-core-gallery-column gdlr-core-column-15">
                                                        <div className="gdlr-core-gallery-list gdlr-core-media-image">
                                                            <a
                                                                className="gdlr-core-lightgallery gdlr-core-js"
                                                                href="upload/shutterstock_1069630346.jpg"
                                                                data-lightbox-group="gdlr-core-img-group-2"
                                                            >
                                                                <img
                                                                    src="upload/shutterstock_1069630346-1000x670.jpg"
                                                                    alt=""
                                                                    width={1000}
                                                                    height={670}
                                                                    title="Sofa"
                                                                />
                                                                <span className="gdlr-core-image-overlay">
                                                                    <i className="gdlr-core-image-overlay-icon gdlr-core-size-22 icon_zoom-in_alt" />
                                                                </span>
                                                            </a>
                                                        </div>
                                                    </div>
                                                    <div className="gdlr-core-item-list gdlr-core-gallery-column gdlr-core-column-15">
                                                        <div className="gdlr-core-gallery-list gdlr-core-media-image">
                                                            <a
                                                                className="gdlr-core-lightgallery gdlr-core-js"
                                                                href="upload/shop-slo-vhztm9QC0L0-unsplash.jpg"
                                                                data-lightbox-group="gdlr-core-img-group-2"
                                                            >
                                                                <img
                                                                    src="upload/shop-slo-vhztm9QC0L0-unsplash-1000x670.jpg"
                                                                    alt=""
                                                                    width={1000}
                                                                    height={670}
                                                                    title="shop-slo-vhztm9QC0L0-unsplash"
                                                                />
                                                                <span className="gdlr-core-image-overlay">
                                                                    <i className="gdlr-core-image-overlay-icon gdlr-core-size-22 icon_zoom-in_alt" />
                                                                </span>
                                                            </a>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div> */}

              {/* <div
                                className="gdlr-core-pbf-wrapper"
                                style={{ padding: "30px 20px 50px 20px" }}
                            >
                                <div className="gdlr-core-pbf-background-wrap" />
                                <div className="gdlr-core-pbf-wrapper-content gdlr-core-js">
                                    <div className="gdlr-core-pbf-wrapper-container clearfix gdlr-core-pbf-wrapper-full">
                                        <div className="gdlr-core-pbf-element">
                                            <div
                                                className="gdlr-core-title-item gdlr-core-item-pdb clearfix gdlr-core-center-align gdlr-core-title-item-caption-bottom gdlr-core-item-pdlr"
                                                style={{ paddingBottom: 60 }}
                                            >
                                                <div className="gdlr-core-title-item-title-wrap">
                                                    <h3
                                                        className="gdlr-core-title-item-title gdlr-core-skin-title class-test"
                                                        style={{ textTransform: "none" }}
                                                    >
                                                        Horizontal Scrolling
                      <span className="gdlr-core-title-item-title-divider gdlr-core-skin-divider" />
                                                    </h3>
                                                </div>
                                                <span
                                                    className="gdlr-core-title-item-caption gdlr-core-info-font gdlr-core-skin-caption"
                                                    style={{ fontStyle: "normal", letterSpacing: 0 }}
                                                >
                                                    Hover With Center Caption
                  </span>
                                            </div>
                                        </div>
                                        <div className="gdlr-core-pbf-element">
                                            <div className="gdlr-core-gallery-item gdlr-core-item-pdb clearfix gdlr-core-gallery-item-style-scroll gdlr-core-item-pdlr">
                                                <div className="gdlr-core-sly-slider gdlr-core-js-2">
                                                    <ul className="slides">
                                                        <li className="gdlr-core-gallery-list gdlr-core-item-mglr">
                                                            <div
                                                                className="gdlr-core-media-image"
                                                                style={{ height: 500 }}
                                                            >
                                                                <a
                                                                    className="gdlr-core-lightgallery gdlr-core-js"
                                                                    href="upload/shutterstock_1069630346.jpg"
                                                                    data-lightbox-group="gdlr-core-img-group-3"
                                                                >
                                                                    <img
                                                                        src="upload/shutterstock_1069630346-1000x670.jpg"
                                                                        alt=""
                                                                        width={1000}
                                                                        height={670}
                                                                        title="Sofa"
                                                                    />
                                                                    <span className="gdlr-core-image-overlay gdlr-core-gallery-image-overlay gdlr-core-center-align">
                                                                        <i className="gdlr-core-image-overlay-icon gdlr-core-size-22 fa fa-search" />
                                                                    </span>
                                                                </a>
                                                            </div>
                                                        </li>
                                                        <li className="gdlr-core-gallery-list gdlr-core-item-mglr">
                                                            <div
                                                                className="gdlr-core-media-image"
                                                                style={{ height: 500 }}
                                                            >
                                                                <a
                                                                    className="gdlr-core-lightgallery gdlr-core-js"
                                                                    href="upload/shutterstock_560973166.jpg"
                                                                    data-lightbox-group="gdlr-core-img-group-3"
                                                                >
                                                                    <img
                                                                        src="upload/shutterstock_560973166-1000x670.jpg"
                                                                        alt=""
                                                                        width={1000}
                                                                        height={670}
                                                                        title="Hotel Room"
                                                                    />
                                                                    <span className="gdlr-core-image-overlay gdlr-core-gallery-image-overlay gdlr-core-center-align">
                                                                        <i className="gdlr-core-image-overlay-icon gdlr-core-size-22 fa fa-search" />
                                                                    </span>
                                                                </a>
                                                            </div>
                                                        </li>
                                                        <li className="gdlr-core-gallery-list gdlr-core-item-mglr">
                                                            <div
                                                                className="gdlr-core-media-image"
                                                                style={{ height: 500 }}
                                                            >
                                                                <a
                                                                    className="gdlr-core-lightgallery gdlr-core-js"
                                                                    href="upload/shutterstock_307470824.jpg"
                                                                    data-lightbox-group="gdlr-core-img-group-3"
                                                                >
                                                                    <img
                                                                        src="upload/shutterstock_307470824-1000x670.jpg"
                                                                        alt=""
                                                                        width={1000}
                                                                        height={670}
                                                                        title="The breakfast"
                                                                    />
                                                                    <span className="gdlr-core-image-overlay gdlr-core-gallery-image-overlay gdlr-core-center-align">
                                                                        <i className="gdlr-core-image-overlay-icon gdlr-core-size-22 fa fa-search" />
                                                                    </span>
                                                                </a>
                                                            </div>
                                                        </li>
                                                        <li className="gdlr-core-gallery-list gdlr-core-item-mglr">
                                                            <div
                                                                className="gdlr-core-media-image"
                                                                style={{ height: 500 }}
                                                            >
                                                                <a
                                                                    className="gdlr-core-lightgallery gdlr-core-js"
                                                                    href="upload/shutterstock_324822821.jpg"
                                                                    data-lightbox-group="gdlr-core-img-group-3"
                                                                >
                                                                    <img
                                                                        src="upload/shutterstock_324822821-1000x670.jpg"
                                                                        alt=""
                                                                        width={1000}
                                                                        height={670}
                                                                        title="Living Room"
                                                                    />
                                                                    <span className="gdlr-core-image-overlay gdlr-core-gallery-image-overlay gdlr-core-center-align">
                                                                        <i className="gdlr-core-image-overlay-icon gdlr-core-size-22 fa fa-search" />
                                                                    </span>
                                                                </a>
                                                            </div>
                                                        </li>
                                                    </ul>
                                                </div>
                                                <div className="gdlr-core-sly-scroll">
                                                    <div className="gdlr-core-sly-scroll-handle" />
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div> */}

              <div
                className="gdlr-core-pbf-wrapper"
                style={{ padding: "120px 20px 70px 20px" }}
                data-skin="white"
              >
                <div
                  className="gdlr-core-pbf-background-wrap"
                  style={{ backgroundColor: "#0a0a0a" }}
                >
                  <div
                    className="gdlr-core-pbf-background gdlr-core-parallax gdlr-core-js"
                    style={{
                      backgroundImage: "url(upload/page-title-bg-2.jpg)",
                      backgroundSize: "cover",
                      backgroundPosition: "center",
                    }}
                    data-parallax-speed="0.2"
                  />
                </div>
                <div className="gdlr-core-pbf-wrapper-content gdlr-core-js">
                  <div className="gdlr-core-pbf-wrapper-container clearfix gdlr-core-container">
                    <div className="gdlr-core-pbf-element">
                      <div
                        className="gdlr-core-title-item gdlr-core-item-pdb clearfix gdlr-core-center-align gdlr-core-title-item-caption-bottom gdlr-core-item-pdlr"
                        style={{ paddingBottom: 60 }}
                      >
                        <div className="gdlr-core-title-item-title-wrap">
                          <h3
                            className="gdlr-core-title-item-title gdlr-core-skin-title class-test"
                            style={{ textTransform: "none" }}
                          >
                            KSJ Stay Gallery
                            <span className="gdlr-core-title-item-title-divider gdlr-core-skin-divider" />
                          </h3>
                        </div>
                        {/* <span
                                                    className="gdlr-core-title-item-caption gdlr-core-info-font gdlr-core-skin-caption"
                                                    style={{ fontStyle: "normal", letterSpacing: 0 }}
                                                >
                                                    Hover With Left Title &amp; Caption
                  </span> */}
                      </div>
                    </div>
                    <div className="gdlr-core-pbf-element">
                      <div className="gdlr-core-gallery-item gdlr-core-item-pdb clearfix gdlr-core-gallery-item-style-slider gdlr-core-item-pdlr">
                        <div
                          className="gdlr-core-flexslider flexslider gdlr-core-js-2"
                          data-type="slider"
                          data-effect="default"
                          data-nav="navigation"
                        >
                          <ul className="slides">
                            <li>
                              <div className="gdlr-core-gallery-list gdlr-core-media-image">
                                <a
                                  className="gdlr-core-lightgallery gdlr-core-js"
                                  href="images/5.jpg"
                                  data-lightbox-group="gdlr-core-img-group-4"
                                >
                                  <img
                                    src="images/5.jpg"
                                    alt=""
                                    width={1280}
                                    height={580}
                                    title=""
                                  />
                                  {/* <span className="gdlr-core-image-overlay gdlr-core-gallery-image-overlay gdlr-core-left-align">
                                                                        <span className="gdlr-core-image-overlay-content">
                                                                            <span className="gdlr-core-image-overlay-title gdlr-core-title-font">
                                                                                Sofa
                                </span>
                                                                        </span>
                                                                    </span> */}
                                </a>
                              </div>
                            </li>
                            <li>
                              <div className="gdlr-core-gallery-list gdlr-core-media-image">
                                <a
                                  className="gdlr-core-lightgallery gdlr-core-js"
                                  href="images/4.jpg"
                                  data-lightbox-group="gdlr-core-img-group-4"
                                >
                                  <img
                                    src="images/4.jpg"
                                    alt=""
                                    width={1280}
                                    height={580}
                                    title=""
                                  />
                                  {/* <span className="gdlr-core-image-overlay gdlr-core-gallery-image-overlay gdlr-core-left-align">
                                                                        <span className="gdlr-core-image-overlay-content">
                                                                            <span className="gdlr-core-image-overlay-title gdlr-core-title-font">
                                                                                Hotel Room
                                </span>
                                                                        </span>
                                                                    </span> */}
                                </a>
                              </div>
                            </li>
                            <li>
                              <div className="gdlr-core-gallery-list gdlr-core-media-image">
                                <a
                                  className="gdlr-core-lightgallery gdlr-core-js"
                                  href="images/3.jpg"
                                  data-lightbox-group="gdlr-core-img-group-4"
                                >
                                  <img
                                    src="images/3.jpg"
                                    alt=""
                                    width={1280}
                                    height={580}
                                    title=""
                                  />
                                  {/* <span className="gdlr-core-image-overlay gdlr-core-gallery-image-overlay gdlr-core-left-align">
                                                                        <span className="gdlr-core-image-overlay-content">
                                                                            <span className="gdlr-core-image-overlay-title gdlr-core-title-font">
                                                                                The breakfast
                                </span>
                                                                        </span>
                                                                    </span> */}
                                </a>
                              </div>
                            </li>
                            <li>
                              <div className="gdlr-core-gallery-list gdlr-core-media-image">
                                <a
                                  className="gdlr-core-lightgallery gdlr-core-js"
                                  href="images/7.jpg"
                                  data-lightbox-group="gdlr-core-img-group-4"
                                >
                                  <img
                                    src="images/7.jpg"
                                    alt=""
                                    width={1280}
                                    height={580}
                                    title=""
                                  />
                                  {/* <span className="gdlr-core-image-overlay gdlr-core-gallery-image-overlay gdlr-core-left-align">
                                                                        <span className="gdlr-core-image-overlay-content">
                                                                            <span className="gdlr-core-image-overlay-title gdlr-core-title-font">
                                                                                Living Room
                                </span>
                                                                        </span>
                                                                    </span> */}
                                </a>
                              </div>
                            </li>
                          </ul>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              {/* <div
                                className="gdlr-core-pbf-wrapper"
                                style={{ padding: "120px 20px 50px 20px" }}
                            >
                                <div
                                    className="gdlr-core-pbf-background-wrap"
                                    style={{ backgroundColor: "#f7f7f7" }}
                                />
                                <div className="gdlr-core-pbf-wrapper-content gdlr-core-js">
                                    <div className="gdlr-core-pbf-wrapper-container clearfix gdlr-core-container">
                                        <div className="gdlr-core-pbf-element">
                                            <div
                                                className="gdlr-core-title-item gdlr-core-item-pdb clearfix gdlr-core-center-align gdlr-core-title-item-caption-bottom gdlr-core-item-pdlr"
                                                style={{ paddingBottom: 60 }}
                                            >
                                                <div className="gdlr-core-title-item-title-wrap">
                                                    <h3
                                                        className="gdlr-core-title-item-title gdlr-core-skin-title class-test"
                                                        style={{ textTransform: "none" }}
                                                    >
                                                        Gallery With Thumbnail
                      <span className="gdlr-core-title-item-title-divider gdlr-core-skin-divider" />
                                                    </h3>
                                                </div>
                                                <span
                                                    className="gdlr-core-title-item-caption gdlr-core-info-font gdlr-core-skin-caption"
                                                    style={{ fontStyle: "normal", letterSpacing: 0 }}
                                                >
                                                    Hover With Title &amp; Caption
                  </span>
                                            </div>
                                        </div>
                                        <div className="gdlr-core-pbf-element">
                                            <div className="gdlr-core-gallery-item gdlr-core-item-pdb clearfix gdlr-core-gallery-item-style-thumbnail gdlr-core-item-pdlr">
                                                <div className="gdlr-core-gallery-with-thumbnail-wrap gdlr-core-inside-slider">
                                                    <div
                                                        className="gdlr-core-flexslider flexslider gdlr-core-js-2"
                                                        data-type="slider"
                                                        data-effect="default"
                                                        data-nav="navigation"
                                                        data-thumbnail={1}
                                                    >
                                                        <ul className="slides">
                                                            <li>
                                                                <div className="gdlr-core-gallery-list gdlr-core-media-image">
                                                                    <a
                                                                        className="gdlr-core-lightgallery gdlr-core-js"
                                                                        href="upload/shutterstock_1069630346.jpg"
                                                                        data-lightbox-group="gdlr-core-img-group-5"
                                                                    >
                                                                        <img
                                                                            src="upload/shutterstock_1069630346-1280x580.jpg"
                                                                            alt=""
                                                                            width={1280}
                                                                            height={580}
                                                                            title="Sofa"
                                                                        />
                                                                        <span className="gdlr-core-image-overlay gdlr-core-gallery-image-overlay gdlr-core-center-align">
                                                                            <span className="gdlr-core-image-overlay-content">
                                                                                <span className="gdlr-core-image-overlay-title gdlr-core-title-font">
                                                                                    Sofa
                                  </span>
                                                                            </span>
                                                                        </span>
                                                                    </a>
                                                                </div>
                                                            </li>
                                                            <li>
                                                                <div className="gdlr-core-gallery-list gdlr-core-media-image">
                                                                    <a
                                                                        className="gdlr-core-lightgallery gdlr-core-js"
                                                                        href="upload/shutterstock_560973166.jpg"
                                                                        data-lightbox-group="gdlr-core-img-group-5"
                                                                    >
                                                                        <img
                                                                            src="upload/shutterstock_560973166-1280x580.jpg"
                                                                            alt=""
                                                                            width={1280}
                                                                            height={580}
                                                                            title="Hotel Room"
                                                                        />
                                                                        <span className="gdlr-core-image-overlay gdlr-core-gallery-image-overlay gdlr-core-center-align">
                                                                            <span className="gdlr-core-image-overlay-content">
                                                                                <span className="gdlr-core-image-overlay-title gdlr-core-title-font">
                                                                                    Hotel Room
                                  </span>
                                                                            </span>
                                                                        </span>
                                                                    </a>
                                                                </div>
                                                            </li>
                                                            <li>
                                                                <div className="gdlr-core-gallery-list gdlr-core-media-image">
                                                                    <a
                                                                        className="gdlr-core-lightgallery gdlr-core-js"
                                                                        href="upload/shutterstock_324822821.jpg"
                                                                        data-lightbox-group="gdlr-core-img-group-5"
                                                                    >
                                                                        <img
                                                                            src="upload/shutterstock_324822821-1280x580.jpg"
                                                                            alt=""
                                                                            width={1280}
                                                                            height={580}
                                                                            title="Living Room"
                                                                        />
                                                                        <span className="gdlr-core-image-overlay gdlr-core-gallery-image-overlay gdlr-core-center-align">
                                                                            <span className="gdlr-core-image-overlay-content">
                                                                                <span className="gdlr-core-image-overlay-title gdlr-core-title-font">
                                                                                    Living Room
                                  </span>
                                                                            </span>
                                                                        </span>
                                                                    </a>
                                                                </div>
                                                            </li>
                                                            <li>
                                                                <div className="gdlr-core-gallery-list gdlr-core-media-image">
                                                                    <a
                                                                        className="gdlr-core-lightgallery gdlr-core-js"
                                                                        href="upload/shutterstock_307470824.jpg"
                                                                        data-lightbox-group="gdlr-core-img-group-5"
                                                                    >
                                                                        <img
                                                                            src="upload/shutterstock_307470824-1280x580.jpg"
                                                                            alt=""
                                                                            width={1280}
                                                                            height={580}
                                                                            title="The breakfast"
                                                                        />
                                                                        <span className="gdlr-core-image-overlay gdlr-core-gallery-image-overlay gdlr-core-center-align">
                                                                            <span className="gdlr-core-image-overlay-content">
                                                                                <span className="gdlr-core-image-overlay-title gdlr-core-title-font">
                                                                                    The breakfast
                                  </span>
                                                                            </span>
                                                                        </span>
                                                                    </a>
                                                                </div>
                                                            </li>
                                                        </ul>
                                                    </div>
                                                    <div className="gdlr-core-sly-slider gdlr-core-js-2">
                                                        <ul className="slides">
                                                            <li className="gdlr-core-gallery-list gdlr-core-item-mglr">
                                                                <div className="gdlr-core-media-image">
                                                                    <img
                                                                        src="upload/shutterstock_1069630346-300x200.jpg"
                                                                        alt=""
                                                                        width={300}
                                                                        height={200}
                                                                        title="Sofa"
                                                                    />
                                                                </div>
                                                            </li>
                                                            <li className="gdlr-core-gallery-list gdlr-core-item-mglr">
                                                                <div className="gdlr-core-media-image">
                                                                    <img
                                                                        src="upload/shutterstock_560973166-300x225.jpg"
                                                                        alt=""
                                                                        width={300}
                                                                        height={225}
                                                                        title="Hotel Room"
                                                                    />
                                                                </div>
                                                            </li>
                                                            <li className="gdlr-core-gallery-list gdlr-core-item-mglr">
                                                                <div className="gdlr-core-media-image">
                                                                    <img
                                                                        src="upload/shutterstock_324822821-300x200.jpg"
                                                                        alt=""
                                                                        width={300}
                                                                        height={200}
                                                                        title="Living Room"
                                                                    />
                                                                </div>
                                                            </li>
                                                            <li className="gdlr-core-gallery-list gdlr-core-item-mglr">
                                                                <div className="gdlr-core-media-image">
                                                                    <img
                                                                        src="upload/shutterstock_307470824-300x200.jpg"
                                                                        alt=""
                                                                        width={300}
                                                                        height={200}
                                                                        title="The breakfast"
                                                                    />
                                                                </div>
                                                            </li>
                                                        </ul>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div> */}

              <div
                className="gdlr-core-pbf-wrapper"
                style={{ padding: "120px 20px 0px 20px" }}
              >
                <div
                  className="gdlr-core-pbf-background-wrap"
                  style={{ backgroundColor: "#ffffff" }}
                />
                <div className="gdlr-core-pbf-wrapper-content gdlr-core-js">
                  <div className="gdlr-core-pbf-wrapper-container clearfix gdlr-core-container">
                    <div className="gdlr-core-pbf-element">
                      <div
                        className="gdlr-core-title-item gdlr-core-item-pdb clearfix gdlr-core-center-align gdlr-core-title-item-caption-bottom gdlr-core-item-pdlr"
                        style={{ paddingBottom: 60 }}
                      >
                        <div className="gdlr-core-title-item-title-wrap">
                          <h3
                            className="gdlr-core-title-item-title gdlr-core-skin-title class-test"
                            style={{ textTransform: "none" }}
                          >
                            More from gallery
                            <span className="gdlr-core-title-item-title-divider gdlr-core-skin-divider" />
                          </h3>
                        </div>
                        <span
                          className="gdlr-core-title-item-caption gdlr-core-info-font gdlr-core-skin-caption"
                          style={{ fontStyle: "normal", letterSpacing: 0 }}
                        >
                          scroll down
                        </span>
                      </div>
                    </div>
                    <div className="gdlr-core-pbf-element">
                      <div className="gdlr-core-gallery-item gdlr-core-item-pdb clearfix gdlr-core-gallery-item-style-stack-image gdlr-core-item-pdlr">
                        <div className="gdlr-core-gallery-item-holder gdlr-core-js-2">
                          <div className="gdlr-core-item-list gdlr-core-item-mgb">
                            <div className="gdlr-core-gallery-list gdlr-core-media-image">
                              <a
                                className="gdlr-core-lightgallery gdlr-core-js"
                                href="images/1.jpg"
                                data-lightbox-group="gdlr-core-img-group-6"
                              >
                                <img
                                  src="images/1.jpg"
                                  alt=""
                                  width={1280}
                                  height={580}
                                  title="Sofa"
                                />
                                <span className="gdlr-core-image-overlay">
                                  <i className="gdlr-core-image-overlay-icon gdlr-core-size-22 icon_zoom-in_alt" />
                                </span>
                              </a>
                            </div>
                          </div>
                          <div className="gdlr-core-item-list gdlr-core-item-mgb">
                            <div className="gdlr-core-gallery-list gdlr-core-media-image">
                              <a
                                className="gdlr-core-lightgallery gdlr-core-js"
                                href="images/2.jpg"
                                data-lightbox-group="gdlr-core-img-group-6"
                              >
                                <img
                                  src="images/2.jpg"
                                  alt=""
                                  width={1280}
                                  height={580}
                                  title="Hotel Room"
                                />
                                <span className="gdlr-core-image-overlay">
                                  <i className="gdlr-core-image-overlay-icon gdlr-core-size-22 icon_zoom-in_alt" />
                                </span>
                              </a>
                            </div>
                          </div>
                          <div className="gdlr-core-item-list gdlr-core-item-mgb">
                            <div className="gdlr-core-gallery-list gdlr-core-media-image">
                              <a
                                className="gdlr-core-lightgallery gdlr-core-js"
                                href="images/13.jpg"
                                data-lightbox-group="gdlr-core-img-group-6"
                              >
                                <img
                                  src="images/13.jpg"
                                  alt=""
                                  width={1280}
                                  height={580}
                                  title="Living Room"
                                />
                                <span className="gdlr-core-image-overlay">
                                  <i className="gdlr-core-image-overlay-icon gdlr-core-size-22 icon_zoom-in_alt" />
                                </span>
                              </a>
                            </div>
                          </div>
                          <div className="gdlr-core-item-list gdlr-core-item-mgb">
                            <div className="gdlr-core-gallery-list gdlr-core-media-image">
                              <a
                                className="gdlr-core-lightgallery gdlr-core-js"
                                href="images/14.jpg"
                                data-lightbox-group="gdlr-core-img-group-6"
                              >
                                <img
                                  src="images/14.jpg"
                                  alt=""
                                  width={1280}
                                  height={580}
                                  title="The breakfast"
                                />
                                <span className="gdlr-core-image-overlay">
                                  <i className="gdlr-core-image-overlay-icon gdlr-core-size-22 icon_zoom-in_alt" />
                                </span>
                              </a>
                            </div>
                          </div>
                          <div className="gdlr-core-item-list gdlr-core-item-mgb">
                            <div className="gdlr-core-gallery-list gdlr-core-media-image">
                              <a
                                className="gdlr-core-lightgallery gdlr-core-js"
                                href="images/10.jpg"
                                data-lightbox-group="gdlr-core-img-group-6"
                              >
                                <img
                                  src="images/10.jpg"
                                  alt=""
                                  width={1280}
                                  height={580}
                                  title="The breakfast"
                                />
                                <span className="gdlr-core-image-overlay">
                                  <i className="gdlr-core-image-overlay-icon gdlr-core-size-22 icon_zoom-in_alt" />
                                </span>
                              </a>
                            </div>
                          </div>
                          <div className="gdlr-core-item-list gdlr-core-item-mgb">
                            <div className="gdlr-core-gallery-list gdlr-core-media-image">
                              <a
                                className="gdlr-core-lightgallery gdlr-core-js"
                                href="images/11.jpg"
                                data-lightbox-group="gdlr-core-img-group-6"
                              >
                                <img
                                  src="images/11.jpg"
                                  alt=""
                                  width={1280}
                                  height={580}
                                  title="The breakfast"
                                />
                                <span className="gdlr-core-image-overlay">
                                  <i className="gdlr-core-image-overlay-icon gdlr-core-size-22 icon_zoom-in_alt" />
                                </span>
                              </a>
                            </div>
                          </div>
                          <div className="gdlr-core-item-list gdlr-core-item-mgb">
                            <div className="gdlr-core-gallery-list gdlr-core-media-image">
                              <a
                                className="gdlr-core-lightgallery gdlr-core-js"
                                href="images/12.jpg"
                                data-lightbox-group="gdlr-core-img-group-6"
                              >
                                <img
                                  src="images/12.jpg"
                                  alt=""
                                  width={1280}
                                  height={580}
                                  title="The breakfast"
                                />
                                <span className="gdlr-core-image-overlay">
                                  <i className="gdlr-core-image-overlay-icon gdlr-core-size-22 icon_zoom-in_alt" />
                                </span>
                              </a>
                            </div>
                          </div>
                          {[...Array(10).keys()].map((i) => (
                            <div className="gdlr-core-item-list gdlr-core-item-mgb">
                              <div className="gdlr-core-gallery-list gdlr-core-media-image">
                                <a
                                  className="gdlr-core-lightgallery gdlr-core-js"
                                  href={`images/new/${i}.jpg`}
                                  data-lightbox-group="gdlr-core-img-group-6"
                                >
                                  <img
                                    src={`images/new/${i}.jpg`}
                                    alt=""
                                    width={1280}
                                    height={580}
                                    title="The breakfast"
                                  />
                                  <span className="gdlr-core-image-overlay">
                                    <i className="gdlr-core-image-overlay-icon gdlr-core-size-22 icon_zoom-in_alt" />
                                  </span>
                                </a>
                              </div>
                            </div>
                          ))}
                          <div className="gdlr-core-item-list gdlr-core-item-mgb">
                            <div className="gdlr-core-gallery-list gdlr-core-media-image">
                              <video width="80%" height="500" controls>
                                <source
                                  src="images/new/KJS.mp4"
                                  type="video/mp4"
                                />
                                Your browser does not support the video tag.
                              </video>
                              {/* <span className="gdlr-core-image-overlay">
                                  <i className="gdlr-core-image-overlay-icon gdlr-core-size-22 icon_zoom-in_alt" />
                                </span> */}
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <Footer />
        </div>
      </div>
    </div>
  );
}
