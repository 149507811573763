import React from "react";

export default function Section2() {
  return (
    <>
      <div
        className="gdlr-core-pbf-wrapper"
        style={{ padding: "140px 0px 30px 0px" }}
        id="gdlr-core-wrapper-1"
      >
        <div className="gdlr-core-pbf-background-wrap" />
        <div className="gdlr-core-pbf-wrapper-content gdlr-core-js">
          <div className="gdlr-core-pbf-wrapper-container clearfix gdlr-core-container">
            <div
              className="gdlr-core-pbf-column gdlr-core-column-30 gdlr-core-column-first"
              id="gdlr-core-column-4"
            >
              <div className="gdlr-core-pbf-column-content-margin gdlr-core-js">
                <div className="gdlr-core-pbf-background-wrap" />
                <div
                  className="gdlr-core-pbf-column-content clearfix gdlr-core-js"
                  data-gdlr-animation="fadeInLeft"
                  data-gdlr-animation-duration="600ms"
                  data-gdlr-animation-offset="0.8"
                >
                  <div className="gdlr-core-pbf-element">
                    <div className="gdlr-core-image-item gdlr-core-item-pdb gdlr-core-left-align gdlr-core-item-pdlr">
                      <div
                        className="gdlr-core-image-item-wrap gdlr-core-media-image gdlr-core-image-item-style-round"
                        style={{
                          borderWidth: "0px",
                          borderRadius: "20px",
                          MozBorderRadius: "20px",
                          WebkitBorderRadius: "20px",
                        }}
                      >
                        <img
                          src="upload/4.jpg"
                          alt=""
                          width={550}
                          height={684}
                          title="4"
                        />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div
              className="gdlr-core-pbf-column gdlr-core-column-30"
              id="gdlr-core-column-5"
            >
              <div
                className="gdlr-core-pbf-column-content-margin gdlr-core-js"
                style={{ padding: "20px 0px 0px 0px" }}
              >
                <div className="gdlr-core-pbf-background-wrap" />
                <div
                  className="gdlr-core-pbf-column-content clearfix gdlr-core-js"
                  data-gdlr-animation="fadeInRight"
                  data-gdlr-animation-duration="600ms"
                  data-gdlr-animation-offset="0.8"
                >
                  <div className="gdlr-core-pbf-element">
                    <div
                      className="gdlr-core-text-box-item gdlr-core-item-pdlr gdlr-core-item-pdb gdlr-core-left-align"
                      style={{ paddingBottom: "25px", marginTop: "3rem" }}
                    >
                      {/* <div
                        className="gdlr-core-text-box-item-content"
                        style={{
                          textTransform: "none",
                          color: "#0a0a0a",
                        }}
                      >
                        <p>
                          <span
                            style={{
                              fontSize: "55px",
                              fontWeight: 500,
                              marginRight: "12px",
                              letterSpacing: "4px",
                            }}
                          >
                            5
                          </span>
                          <span
                            className="mmr30"
                            style={{
                              fontSize: "22px",
                              fontWeight: 400,
                              marginRight: "80px",
                              letterSpacing: "7px",
                            }}
                          >
                            stars
                          </span>
                          <span
                            style={{
                              fontSize: "55px",
                              fontWeight: 500,
                              marginRight: "12px",
                              letterSpacing: "4px",
                            }}
                          >
                            07
                          </span>
                          <span
                            style={{
                              fontSize: "22px",
                              fontWeight: 400,
                              marginRight: "12px",
                              letterSpacing: "7px",
                            }}
                          >
                            apartments
                          </span>
                        </p>
                      </div> */}
                    </div>
                  </div>
                  <div className="gdlr-core-pbf-element">
                    <div
                      className="gdlr-core-title-item gdlr-core-item-pdb clearfix gdlr-core-left-align gdlr-core-title-item-caption-top gdlr-core-item-pdlr"
                      style={{ paddingBottom: "35px" }}
                    >
                      <div className="gdlr-core-title-item-title-wrap">
                        <h3
                          className="gdlr-core-title-item-title gdlr-core-skin-title class-test"
                          style={{
                            fontSize: "42px",
                            fontWeight: 700,
                            letterSpacing: "0px",
                            lineHeight: "1.2",
                            textTransform: "none",
                            color: "#1c1c1c",
                          }}
                        >
                          Facilities
                          <span className="gdlr-core-title-item-title-divider gdlr-core-skin-divider" />
                        </h3>
                      </div>
                    </div>
                  </div>
                  <div className="gdlr-core-pbf-element">
                    <div
                      className="gdlr-core-text-box-item gdlr-core-item-pdlr gdlr-core-item-pdb gdlr-core-left-align"
                      style={{ paddingBottom: "20px" }}
                    >
                      <div
                        className="gdlr-core-text-box-item-content"
                        style={{
                          fontSize: "19px",
                          textTransform: "none",
                          color: "#94959b",
                        }}
                      >
                        <p>
                          <ul>
                            <li>Furnished Rooms</li>
                            <li>Individual Wardrobes</li>
                            <li>
                              Freshly cooked In-house Food for all occupants
                            </li>
                            <li> Refrigerators</li>
                            <li>
                              Attached Western Toilet with Geyser for hot water
                            </li>
                            <li>Unlimited WIFI Connection</li>
                            <li> First Aid facilities</li>
                            {/* <li>RO Water in every floor</li> */}
                            <li>Security round the clock</li>
                            <li>Washing Machines</li>
                            <li>CCTV Surveillance</li>
                          </ul>
                        </p>
                      </div>
                    </div>
                  </div>
                  <div className="gdlr-core-pbf-element">
                    <div className="gdlr-core-divider-item gdlr-core-divider-item-normal gdlr-core-item-pdlr gdlr-core-left-align">
                      <div
                        className="gdlr-core-divider-container"
                        style={{ maxWidth: "40px" }}
                      >
                        <div
                          className="gdlr-core-divider-line gdlr-core-skin-divider"
                          style={{
                            borderColor: "#ff5023",
                            borderWidth: "3px",
                          }}
                        />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div
        className="gdlr-core-pbf-wrapper gdlr-core-hide-in-mobile"
        style={{
          margin: "-200px 0px 0px 0px",
          padding: "0px 0px 30px 0px",
        }}
      >
        <div className="gdlr-core-pbf-background-wrap" />
        <div className="gdlr-core-pbf-wrapper-content gdlr-core-js">
          <div className="gdlr-core-pbf-wrapper-container clearfix gdlr-core-container">
            <div className="gdlr-core-pbf-element">
              <div
                className="gdlr-core-image-item gdlr-core-item-pdb gdlr-core-center-align gdlr-core-item-pdlr"
                style={{ paddingBottom: "0px" }}
              >
                <div
                  className="gdlr-core-image-item-wrap gdlr-core-media-image gdlr-core-image-item-style-rectangle"
                  style={{ borderWidth: "0px" }}
                >
                  <img
                    src="images/4.jpg"
                    alt=""
                    width={1122}
                    height={395}
                    title="apartment-2-number-img-2"
                    style={{ borderRadius: "20px" }}
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
