import React from "react";

export default function Section5() {
  return (
    <>
      {" "}
      <div
        className="gdlr-core-pbf-wrapper"
        style={{
          margin: "0px 0px 0px 0px",
          padding: "90px 0px 30px 0px",
        }}
        id="gdlr-core-wrapper-4"
      >
        <div className="gdlr-core-pbf-background-wrap" />
        <div className="gdlr-core-pbf-background-wrap" style={{ top: "65px" }}>
          <div
            className="gdlr-core-pbf-background gdlr-core-parallax gdlr-core-js"
            style={{
              backgroundImage: "url(upload/apartment2-gallery-bg.png)",
              backgroundRepeat: "no-repeat",
              backgroundPosition: "top center",
            }}
            data-parallax-speed={0}
          />
        </div>
        <div className="gdlr-core-pbf-wrapper-content gdlr-core-js">
          <div className="gdlr-core-pbf-wrapper-container clearfix gdlr-core-pbf-wrapper-full">
            <div
              className="gdlr-core-pbf-column gdlr-core-column-60 gdlr-core-column-first"
              id="gdlr-core-column-11"
            >
              <div
                className="gdlr-core-pbf-column-content-margin gdlr-core-js"
                style={{ padding: "0px 0px 30px 0px" }}
              >
                <div className="gdlr-core-pbf-background-wrap" />
                <div
                  className="gdlr-core-pbf-column-content clearfix gdlr-core-js"
                  style={{ maxWidth: "580px" }}
                >
                  <div className="gdlr-core-pbf-element">
                    <div className="gdlr-core-title-item gdlr-core-item-pdb clearfix gdlr-core-center-align gdlr-core-title-item-caption-top gdlr-core-item-pdlr">
                      <div className="gdlr-core-title-item-title-wrap">
                        <h3
                          className="gdlr-core-title-item-title gdlr-core-skin-title class-test"
                          style={{
                            fontSize: "45px",
                            fontWeight: 700,
                            letterSpacing: "0px",
                            lineHeight: 1,
                            textTransform: "none",
                          }}
                        >
                          Gallery
                          <span className="gdlr-core-title-item-title-divider gdlr-core-skin-divider" />
                        </h3>
                      </div>
                    </div>
                  </div>
                  <div className="gdlr-core-pbf-element">
                    <div className="gdlr-core-divider-item gdlr-core-divider-item-normal gdlr-core-item-pdlr gdlr-core-center-align">
                      <div
                        className="gdlr-core-divider-container"
                        style={{ maxWidth: "40px" }}
                      >
                        <div
                          className="gdlr-core-divider-line gdlr-core-skin-divider"
                          style={{
                            borderColor: "#ff5023",
                            borderWidth: "3px",
                          }}
                        />
                      </div>
                    </div>
                  </div>
                  <div className="gdlr-core-pbf-element">
                    <div
                      className="gdlr-core-text-box-item gdlr-core-item-pdlr gdlr-core-item-pdb gdlr-core-center-align"
                      style={{ paddingBottom: "20px" }}
                    >
                      <div
                        className="gdlr-core-text-box-item-content"
                        style={{
                          fontSize: "19px",
                          textTransform: "none",
                          color: "#94959b",
                        }}
                      >
                        {/* <p>
                          Search millions of apartments, houses, and private
                          office suites for rent with our exclusive hotels &amp;
                          apartments app.
                        </p> */}
                      </div>
                    </div>
                  </div>
                  <div className="gdlr-core-pbf-element">
                    <div className="gdlr-core-button-item gdlr-core-item-pdlr gdlr-core-item-pdb gdlr-core-center-align">
                      <a
                        className="gdlr-core-button gdlr-core-button-transparent gdlr-core-center-align gdlr-core-button-no-border"
                        href="#"
                        style={{
                          fontSize: "13px",
                          fontStyle: "normal",
                          fontWeight: 600,
                          letterSpacing: "4px",
                          color: "#000000",
                          textTransform: "uppercase",
                        }}
                      >
                        {/* <span className="gdlr-core-content">
                          <i
                            className="gdlr-core-pos-left fa fa-instagram"
                            style={{ fontSize: "21px" }}
                          />
                          Follow us on Instagram
                        </span> */}
                      </a>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div
              className="gdlr-core-pbf-column gdlr-core-column-60 gdlr-core-column-first"
              id="gdlr-core-column-12"
            >
              <div
                className="gdlr-core-pbf-column-content-margin gdlr-core-js"
                style={{ margin: "0px -70px 0px -70px" }}
              >
                <div className="gdlr-core-pbf-background-wrap" />
                <div className="gdlr-core-pbf-column-content clearfix gdlr-core-js">
                  <div className="gdlr-core-pbf-element">
                    <div
                      className="gdlr-core-gallery-item gdlr-core-item-pdb clearfix gdlr-core-gallery-item-style-grid gdlr-core-item-pdlr"
                      id="gdlr-core-gallery-1"
                    >
                      <div
                        className="gdlr-core-flexslider flexslider gdlr-core-js-2"
                        data-type="carousel"
                        data-column={3}
                        data-move={1}
                        data-nav="navigation-inner"
                        data-nav-parent="self"
                        data-margin="20px"
                      >
                        <div className="gdlr-core-flexslider-custom-nav gdlr-core-style-navigation-inner gdlr-core-center-align gdlr-core-show-on-hover">
                          <i
                            className="icon-arrow-left flex-prev"
                            style={{
                              color: "#7a7a7a",
                              backgroundColor: "#ffffff",
                              padding: "20px 20px 20px 20px",
                              borderRadius: "0px",
                              MozBorderRadius: "0px",
                              WebkitBorderRadius: "0px",
                              fontSize: "22px",
                              marginTop: "-11px",
                              left: "50px",
                            }}
                          />
                          <i
                            className="icon-arrow-right flex-next"
                            style={{
                              color: "#7a7a7a",
                              backgroundColor: "#ffffff",
                              padding: "20px 20px 20px 20px",
                              borderRadius: "0px",
                              MozBorderRadius: "0px",
                              WebkitBorderRadius: "0px",
                              fontSize: "22px",
                              marginTop: "-11px",
                              right: "50px",
                            }}
                          />
                        </div>
                        <ul className="slides">
                          <li className="gdlr-core-item-mglr">
                            <div className="gdlr-core-gallery-list gdlr-core-media-image">
                              <a
                                className="gdlr-core-lightgallery gdlr-core-js"
                                href="upload/patrick-perkins-3wylDrjxH-E-unsplash.jpg"
                                data-lightbox-group="gdlr-core-img-group-1"
                              >
                                <img
                                  src="images/3.jpg"
                                  alt=""
                                  width={1000}
                                  height={670}
                                  title="patrick-perkins-3wylDrjxH-E-unsplash"
                                />
                              </a>
                            </div>
                          </li>
                          <li className="gdlr-core-item-mglr">
                            <div className="gdlr-core-gallery-list gdlr-core-media-image">
                              <a
                                className="gdlr-core-lightgallery gdlr-core-js"
                                href="upload/tu-tu-322210-unsplash-scaled.jpg"
                                data-lightbox-group="gdlr-core-img-group-1"
                              >
                                <img
                                  src="images/9.jpg"
                                  alt=""
                                  width={1000}
                                  height={670}
                                  title="tu-tu-322210-unsplash"
                                />
                              </a>
                            </div>
                          </li>
                          <li className="gdlr-core-item-mglr">
                            <div className="gdlr-core-gallery-list gdlr-core-media-image">
                              <a
                                className="gdlr-core-lightgallery gdlr-core-js"
                                href="upload/kelsey-roenau-608583-unsplash.jpg"
                                data-lightbox-group="gdlr-core-img-group-1"
                              >
                                <img
                                  src="images/4.jpg"
                                  alt=""
                                  width={1000}
                                  height={670}
                                  title="kelsey-roenau-608583-unsplash"
                                />
                              </a>
                            </div>
                          </li>
                          <li className="gdlr-core-item-mglr">
                            <div className="gdlr-core-gallery-list gdlr-core-media-image">
                              <a
                                className="gdlr-core-lightgallery gdlr-core-js"
                                href="upload/kirill-zakharov-630681-unsplash-scaled.jpg"
                                data-lightbox-group="gdlr-core-img-group-1"
                              >
                                <img
                                  src="images/5.jpg"
                                  alt=""
                                  width={1000}
                                  height={670}
                                  title="kirill-zakharov-630681-unsplash"
                                />
                              </a>
                            </div>
                          </li>
                          {/* <li className="gdlr-core-item-mglr">
                            <div className="gdlr-core-gallery-list gdlr-core-media-image">
                              <a
                                className="gdlr-core-lightgallery gdlr-core-js"
                                href="upload/aaron-huber-401200-unsplash.jpg"
                                data-lightbox-group="gdlr-core-img-group-1"
                              >
                                <img
                                  src="images/5.jpg"
                                  alt=""
                                  width={1000}
                                  height={670}
                                  title="aaron-huber-401200-unsplash"
                                />
                              </a>
                            </div>
                          </li> */}
                          <li className="gdlr-core-item-mglr">
                            <div className="gdlr-core-gallery-list gdlr-core-media-image">
                              <a
                                className="gdlr-core-lightgallery gdlr-core-js"
                                href="upload/andrew-neel-T0eb55DxDN4-unsplash.jpg"
                                data-lightbox-group="gdlr-core-img-group-1"
                              >
                                <img
                                  src="images/7.jpg"
                                  alt=""
                                  width={1000}
                                  height={670}
                                  title="andrew-neel-T0eb55DxDN4-unsplash"
                                />
                              </a>
                            </div>
                          </li>
                        </ul>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
