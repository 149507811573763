import React from "react";

export default function Footer() {
  return (
    <footer>
      <div className="hotale-footer-wrapper">
        <div className="hotale-footer-container hotale-container clearfix">
          <div className="hotale-footer-column hotale-item-pdlr hotale-column-20">
            <div
              id="block-25"
              className="widget widget_block widget_media_image hotale-widget"
            >
              <figure className="wp-block-image size-full is-resized">
                <img
                  style={{ width: "45px" }}
                  loading="lazy"
                  src="images/hostel.png"
                  alt=""
                  className="wp-image-15952"
                  width={126}
                  height={34}
                />
                <span
                  style={{
                    fontSize: "23px",
                    fontWeight: 200,
                    letterSpacing: "0px",
                    lineHeight: 1,
                    textTransform: "none",
                    color: "#141414",
                  }}
                >
                  {" "}
                  KSJ
                </span>
              </figure>
            </div>
            {/* <div id="block-8" className="widget widget_block hotale-widget">
              <p>
                <span
                  className="gdlr-core-social-network-item gdlr-core-item-pdb gdlr-core-none-align gdlr-direction-horizontal"
                  style={{ paddingBottom: "0px", display: "block" }}
                  id="gdlr-core-social-network-1"
                >
                  <a
                    href="/about"
                    target="_blank"
                    className="gdlr-core-social-network-icon"
                    title="facebook"
                    style={{
                      color: "#ffffff",
                      width: "46px",
                      height: "46px",
                      lineHeight: "46px",
                      backgroundColor: "#61c877",
                      borderRadius: "23px",
                      MozBorderRadius: "23px",
                      WebkitBorderRadius: "23px",
                    }}
                  >
                    <i className="fa fa-facebook" />
                  </a>
                  <a
                    href="/about"
                    target="_blank"
                    className="gdlr-core-social-network-icon"
                    title="linkedin"
                    style={{
                      color: "#ffffff",
                      width: "46px",
                      height: "46px",
                      lineHeight: "46px",
                      backgroundColor: "#61c877",
                      borderRadius: "23px",
                      MozBorderRadius: "23px",
                      WebkitBorderRadius: "23px",
                    }}
                  >
                    <i className="fa fa-linkedin" />
                  </a>
                  <a
                    href="/about"
                    target="_blank"
                    className="gdlr-core-social-network-icon"
                    title="twitter"
                    style={{
                      color: "#ffffff",
                      width: "46px",
                      height: "46px",
                      lineHeight: "46px",
                      backgroundColor: "#61c877",
                      borderRadius: "23px",
                      MozBorderRadius: "23px",
                      WebkitBorderRadius: "23px",
                    }}
                  >
                    <i className="fa fa-twitter" />
                  </a>
                </span>
              </p>
            </div> */}
            <div
              id="block-23"
              className="widget widget_block widget_text hotale-widget"
            >
              <p>Our hostels offer comfort stay in all ways.</p>
            </div>
            {/* <div id="block-27" className="widget widget_block hotale-widget">
              <div className="tourmaster-currency-switcher-shortcode clearfix">
                <div
                  className="tourmaster-currency-switcher"
                  style={{ background: "#e5e5e5" }}
                >
                  <span
                    className="tourmaster-head"
                    style={{ color: "#333333" }}
                  >
                    <span>USD</span>
                    <i className="fa fa-sort-down" />
                  </span>
                  <div className="tourmaster-currency-switcher-inner">
                    <div className="tourmaster-currency-switcher-content">
                      <ul>
                        <li>
                          <a href="#">EUR</a>
                        </li>
                        <li>
                          <a href="#">CHF</a>
                        </li>
                      </ul>
                    </div>
                  </div>
                </div>
              </div>
            </div> */}
          </div>
          <div className="hotale-footer-column hotale-item-pdlr hotale-column-20">
            <div
              id="gdlr-core-custom-menu-widget-2"
              className="widget widget_gdlr-core-custom-menu-widget hotale-widget"
            >
              <h1 className="hotale-widget-title">Quick Links</h1>
              <span className="clear" />
              <div className="menu-quick-links-container">
                <ul
                  id="menu-quick-links"
                  className="gdlr-core-custom-menu-widget gdlr-core-menu-style-half"
                >
                  <li className="menu-item hotale-normal-menu">
                    <a href="/">Home Page</a>
                  </li>
                  {/* <li className="menu-item hotale-normal-menu">
                    <a href="#">FAQ</a>
                  </li> */}
                  {/* <li className="menu-item hotale-normal-menu">
                    <a href="blog-3-columns-with-frame.html">Blog</a>
                  </li> */}
                  <li className="menu-item hotale-normal-menu">
                    <a href="/contact">Contact Us</a>
                  </li>
                  {/* <li className="menu-item hotale-normal-menu">
                    <a href="room-search.html">Reservation</a>
                  </li> */}
                  {/* <li className="menu-item hotale-normal-menu">
                    <a href="room-grid-style-3.html">Room List</a>
                  </li> */}
                  <li className="menu-item hotale-normal-menu">
                    <a href="/gallery">Gallery</a>
                  </li>
                  <li className="menu-item hotale-normal-menu">
                    <a href="/about">About Us</a>
                  </li>
                </ul>
              </div>
            </div>
          </div>
          {/* <div className="hotale-footer-column hotale-item-pdlr hotale-column-20">
            <div
              id="gdlr-core-newsletter-widget-2"
              className="widget widget_gdlr-core-newsletter-widget hotale-widget"
            >
              <h1 className="hotale-widget-title">Newsletter</h1>
              <span className="clear" />
              <div className="gdlr-core-with-fa-send-o-button tnp tnp-subscription gdlr-core-style-1">
                <form method="post" action="#">
                  <div className="tnp-field tnp-field-email">
                    <input
                      className="tnp-email"
                      type="email"
                      name="ne"
                      placeholder="Enter Your Email Address"
                      required
                      style={{ background: "#ffffff" }}
                    />
                  </div>
                  <div
                    className="tnp-field tnp-field-button"
                    style={{ color: "#61c877" }}
                  >
                    <input
                      className="tnp-submit"
                      type="submit"
                      defaultValue="Subscribe"
                    />
                  </div>
                </form>
              </div>
            </div>
            <div
              id="block-24"
              className="widget widget_block widget_media_image hotale-widget"
            >
              <figure className="wp-block-image size-full">
                <img
                  loading="lazy"
                  width={383}
                  height={137}
                  src="upload/resort2-footer-bg.jpg"
                  alt=""
                  className="wp-image-15944"
                />
              </figure>
            </div>
          </div> */}
        </div>
      </div>
      <div className="hotale-copyright-wrapper">
        <div className="hotale-copyright-container hotale-container">
          <div className="hotale-copyright-text hotale-item-pdlr">
            © Copyright VS <a href="#">Terms &amp; Conditions.</a>
          </div>
        </div>
      </div>
    </footer>
  );
}
