import React from "react";
import ReactDOM from "react-dom/client";
import { BrowserRouter, Routes, Route } from "react-router-dom";
import AboutUS from "./AboutUs";
import ContactUS from "./ContactUS";
import Gallery from "./Gallery";
import HomePage from "./homePage";
import reportWebVitals from "./reportWebVitals";

const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(
  <React.StrictMode>
    {/* <HomePage /> */}
    <BrowserRouter>
      <Routes>
        {/* <Route path="/" element={<Layout />}> */}
        <Route index element={<HomePage />} />
        <Route path="about" element={<AboutUS />} />
        <Route path="contact" element={<ContactUS />} />
        <Route path="gallery" element={<Gallery />} />
        {/* <Route exact path="/gallery" component={Gallery} /> */}
        {/*  <Route path="contact" element={<Contact />} />
          <Route path="*" element={<NoPage />} /> */}
        {/* </Route> */}
      </Routes>
    </BrowserRouter>
  </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
